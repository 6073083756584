import React, { Component } from "react";

import MaterialTable from "material-table";

import { connect } from "react-redux";

import { get_news, elimina_news } from "../../../actions/news";

import { locale } from "../../../dashboard/calcoloTasse/impostazioni";

import moment from "moment";

class Tabella extends Component {
    componentDidMount() {
        this.props.get_news();
    }
    render() {
   
        return (
            <div style={{ width: "90%" }}>
                <MaterialTable
                    columns={[
                        { title: "ID", field: "id", hidden: true },
                        {
                            title: "Titolo",
                            field: "titolo",
                            cellStyle: { fontSize: 14 }
                        },
                        {
                            title: "Data",
                            field: "data",
                            type: "datetime",
                            cellStyle: { fontSize: 14 },
                            defaultSort: "desc",
                            render: rowData => {
                                return moment(rowData.data).format(
                                    "DD/MM/YYYY"
                                );
                            }
                        }
                    ]}
                    data={this.props.news}
                    actions={[
                        {
                            icon: "delete_forever",
                            tooltip: "Cancella fattura",
                            onClick: (event, rowData) => {
                                this.props.elimina_news(rowData.id);
                            },
                            iconProps: {
                                style: {
                                    fontSize: 30,
                                    color: "red"
                                }
                            }
                        }
                    ]}
                    title="Lista news"
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            fontSize: 14
                        }
                    }}
                    localization={locale}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    news: state.news.news
});

export default connect(
    mapStateToProps,
    { get_news, elimina_news }
)(Tabella);
