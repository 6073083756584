import React, { Component } from "react";

import moment from "moment";

import { connect } from "react-redux";

import InserisciContributi from "./InserisciContributi";

import TabellaContributi from "./TabellaContributi";

import ModalDelete from "../../components/Modal/ModalDelete";

import {
  invia_contributi,
  get_contributi_anno,
  elimina_contributi,
} from "../../../actions/contributi";

class Contributi extends Component {
  state = {
    mesi: ["", "", "", "", "", "", "", "", "", "", "", ""],
  };

  componentDidMount() {
    this.props.get_contributi_anno(this.props.annoSelezionato);
  }
  componentWillReceiveProps(props) {
    if (props.annoSelezionato !== this.props.annoSelezionato) {
      props.get_contributi_anno(props.annoSelezionato);
    }
  }

  onInput = (e) => {
    var mesi = this.state.mesi;
    mesi[e.target.name] = e.target.value;
    this.setState({
      mesi,
    });
  };

  onSumbit = (e) => {
    e.preventDefault();
    var vuoto = true;
    this.state.mesi.forEach((mese) => {
      if (mese !== "") vuoto = false;
    });
    if (vuoto) {
    } else {
      this.state.mesi.forEach((mese, index) => {
        if (mese !== "") {
          const contributi = {
            importo: parseFloat(mese),
            data: moment(`${new Date().getFullYear()}-${index + 1}-1`)._d,
          };
          this.props.invia_contributi(contributi);
        }
      });
      setTimeout(() => {
        this.props.get_contributi_anno(new Date().getFullYear());
      }, 1000);
      this.setState({
        mesi: ["", "", "", "", "", "", "", "", "", "", "", ""],
      });
    }
  };

  render() {
    return (
      <div className="content">
        <div className="__tab-wrapper">
          <input
            id="tab1"
            className="__tab-input"
            type="radio"
            name="tabs"
            defaultChecked
          />
          <label className="__tab-label" htmlFor="tab1">
            Inserisci Contributi
          </label>

          <input id="tab2" className="__tab-input" type="radio" name="tabs" />
          <label className="__tab-label" htmlFor="tab2">
            Lista contributi
          </label>

          <section id="content1" className="__tab-section">
            <InserisciContributi
              contributi={this.props.contributi.contributi}
            />
          </section>

          <section id="content2" className="__tab-section">
            <TabellaContributi
              data={this.props.contributi}
              delete={this.props.elimina_contributi}
            />
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  contributi: state.contributi,
  annoSelezionato: state.auth.annoSelezionato,
});

export default connect(mapStateToProps, {
  invia_contributi,
  get_contributi_anno,
  elimina_contributi,
})(Contributi);
