export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const GET_ERRORS = "GET_ERRORS";
export const INVIA_FATTURA = "INVIA_FATTURA";
export const GET_FATTURE = "GET_FATTURE";
export const MODIFICA_FATTURA = "MODIFICA_FATTURA";
export const ELIMINA_FATTURA = "ELIMINA_FATTURA";
export const ELIMINA_NOTA_DI_CREDITO = "ELIMINA_NOTA_DI_CREDITO";
export const ALERT_HIDE = "ALERT_HIDE";
export const INSERISCI_NEWS = "INSERISCI_NEWS";
export const ELIMINA_NEWS = "ELIMINA_NEWS";
export const GET_NEWS = "GET_NEWS";
export const GET_CONTRIBUTI = "GET_CONTRIBUTI";
export const ELIMINA_CONTRIBUTI = "ELIMINA_CONTRIBUTI";
export const LOADING_SHOW = "LOADING_SHOW";
export const LOADING_HIDE = "LOADING_HIDE";
export const INSERISCI_SPESA = "INSERISCI_SPESA";
export const GET_SPESE = "GET_SPESE";
export const ELIMINA_SPESA = "ELIMINA_SPESA";
export const GET_CLIENTI = "GET_CLIENTI";
export const GET_NOTE_DI_CREDITO = "GET_NOTE_DI_CREDITO";
export const INVIA_CLIENTE = "INVIA_CLIENTE";
export const DELETE_CLIENTE = "DELETE_CLIENTE";
export const DISABLE_REMINDER_FATTURE = "DISABLE_REMINDER_FATTURE";
export const UPDATE_ANNO_SELEZIONATO = "UPDATE_ANNO_SELEZIONATO";
export const INVIA_NOTA_DI_CREDITO = "INVIA_NOTA_DI_CREDITO";
