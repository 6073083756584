import React from "react";
import { Card, CardTitle, CardBody, CardHeader, Row, Col } from "reactstrap";

import Accordion from "./Accordion";

import { connect } from "react-redux";

class Tasse extends React.Component {
  render() {
    return (
      <div className="content">
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader className="text-center mb-3">
                <CardTitle>Storico imposte</CardTitle>
              </CardHeader>
              <CardBody>
                <Accordion />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Tasse);
