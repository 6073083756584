import React from "react";
import { Card, CardBody, CardFooter, CardTitle, Row, Col } from "reactstrap";

import Stats from "../../components/Stats/Stats.jsx";

import Chart from "./Chart";
import Tabella from "./Tabella.js";

import { connect } from "react-redux";
import { get_fatture_incassate_anno, get_note_di_credito_incassate_anno } from "../../../actions/fatture";
import { get_contributi_anno } from "../../../actions/contributi";
import { get_spese_anno } from "../../../actions/spese";

import {
  prof_1_2_reduce,
  art_com_reduce,
  calcolo_cumulativo,
  calcolo_tasse_prof_1,
  calcolo_tasse_prof_2,
  calcolo_tasse_art,
  calcolo_tasse_comm
} from "../../calcoloTasse/calcoloTasse";

import { parseFromLocale, converti } from "../../calcoloTasse/funzioni";

import "moment/locale/it";

class Dashboard extends React.Component {
  componentDidMount() {
    this.props.get_fatture_incassate_anno(this.props.annoSelezionato);
    this.props.get_contributi_anno(this.props.annoSelezionato);
    this.props.get_spese_anno(this.props.annoSelezionato);
    this.props.get_note_di_credito_incassate_anno(this.props.annoSelezionato);
  }
  componentWillReceiveProps(props) {
    if (props.annoSelezionato !== this.props.annoSelezionato) {
      props.get_fatture_incassate_anno(props.annoSelezionato);
      props.get_contributi_anno(props.annoSelezionato);
      props.get_spese_anno(props.annoSelezionato);
    }
  }

  render() {
    const { fatture, contributi, noteDiCredito, auth } = this.props;

    const { categoria } = this.props.auth.user;

    const spese = this.props.spese.reduce((somma, elem) => {
      return somma + elem.importo;
    }, 0);

    var totale_contributi;

    if (categoria === "prof 1") {
      totale_contributi = contributi.reduce((totale, contributo) => {
        return totale + contributo.importo;
      }, 0);
    } else {
      totale_contributi = contributi.reduce((totale, contributo) => {
        const mese = new Date(contributo.data).getMonth() + 1;

        if (mese === 6 || mese === 7) {
          return totale;
        }
        return totale + contributo.importo;
      }, 0);
    }

    var dati = [];
    if (fatture.length !== 0 && contributi) {
      if (categoria === "prof 1") {
        const anni = prof_1_2_reduce(fatture, contributi, noteDiCredito);
        const anno = anni[0];
        dati = calcolo_tasse_prof_1(anno, auth.user);
      } else if (categoria === "prof 2") {
        const anni = prof_1_2_reduce(fatture, contributi, noteDiCredito);
        const anno = anni[0];
        dati = calcolo_tasse_prof_2(anno, auth.user);
      } else if (categoria === "art") {
        dati = calcolo_tasse_art(
          calcolo_cumulativo(
            art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
          ),
          auth.user
        );
      } else if (categoria === "comm") {
        dati = calcolo_tasse_comm(
          calcolo_cumulativo(
            art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
          ),
          auth.user
        );
      }
    }

    return (
      <div className="content">
        <Row align="center">
          <Col
            md={12}
            lg={6}
            xl={4}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block"
            }}
          >
            <Card className="bg-info">
              <CardBody>
                <strong className="h5 text-white">
                  Anno {this.props.annoSelezionato}
                </strong>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="__dashboard-grid">
          <div className="__dashboard-grid-item">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={4} md={4}>
                    <div className="icon-big text-center">
                      <i
                        className="fas fa-wallet "
                        style={{ color: "#3F729B" }}
                      />
                    </div>
                  </Col>
                  <Col xs={8} md={8}>
                    <div className="numbers">
                      <p className="card-category">Totale incassato</p>
                      <CardTitle tag="p">
                        {fatture.length !== 0
                          ? converti(
                              fatture.reduce((somma, elem) => {
                                return somma + elem.imponibile + elem.rivalsa;
                              }, 0) -
                              noteDiCredito.reduce((somma, elem)=>{
                                return somma + elem.imponibile + elem.rivalsa;
                              }, 0)
                            ) + " €"
                          : "0 €"}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-money-bill-wave",
                      t: "Totale importo lordo"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </div>

          <div className="__dashboard-grid-item">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={4} md={4}>
                    <div className="icon-big text-center">
                      <i className="fas fa-money-bill text-danger" />
                    </div>
                  </Col>
                  <Col xs={8} md={8}>
                    <div className="numbers">
                      <p className="card-category">
                        {categoria === "prof 2"
                          ? " Imposte E Contributi"
                          : "Imposte"}
                      </p>
                      <CardTitle tag="p">
                        {fatture.length !== 0 && contributi
                          ? categoria === "prof 1" ||
                            (categoria === "art" && dati.length !== 3) ||
                            (categoria === "comm" && dati.length !== 3)
                            ? dati[6].importo
                            : dati[2][2].importo
                          : "0 €"}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-receipt",
                      t:
                        categoria === "prof 2"
                          ? `Da pagare nel ${new Date().getFullYear() + 1}`
                          : `Imposte da pagare nel ${new Date().getFullYear() +
                              1}`
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </div>
          <div className="__dashboard-grid-item">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={4} md={4}>
                    <div className="icon-big text-center">
                      <i
                        className="fas fa-euro-sign "
                        style={{ color: "#ffcc80" }}
                      />
                    </div>
                  </Col>
                  <Col xs={8} md={8}>
                    <div className="numbers">
                      <p className="card-category">
                        {categoria === "prof 2"
                          ? "Spese"
                          : "Spese e Contributi"}
                      </p>
                      <CardTitle tag="p">
                        {this.props.spese.length !== 0 ||
                        contributi.length !== 0
                          ? categoria !== "prof 2"
                            ? converti(spese + totale_contributi) + " €"
                            : converti(spese) + " €"
                          : "0 €"}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-euro-sign",
                      t: "Totale spese"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </div>
          <div className="__dashboard-grid-item">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col xs={4} md={4}>
                    <div className="icon-big text-center">
                      <i className="fas fa-coins text-success" />
                    </div>
                  </Col>
                  <Col xs={8} md={8}>
                    <div className="numbers">
                      <p className="card-category">Reddito netto</p>
                      <CardTitle tag="p">
                        {fatture.length !== 0 && contributi
                          ? categoria === "prof 1"
                            ? converti(
                                this.props.fatture.reduce((somma, elem) => {
                                  return somma + elem.imponibile + elem.rivalsa;
                                }, 0) -
                                noteDiCredito.reduce((somma, elem)=>{
                                  return somma + elem.imponibile + elem.rivalsa;
                                }, 0)-
                                  parseFromLocale(
                                    dati[6].importo.split(" ")[0]
                                  ) -
                                  spese -
                                  totale_contributi
                              ) + " €"
                            : categoria === "prof 2"
                            ? (
                                this.props.fatture.reduce((somma, elem) => {
                                  return somma + elem.imponibile + elem.rivalsa;
                                }, 0) - noteDiCredito.reduce((somma, elem)=>{
                                  return somma + elem.imponibile + elem.rivalsa;
                                }, 0) -
                                parseFromLocale(
                                  dati[2][2].importo.split(" ")[0]
                                ) -
                                spese
                              ).toLocaleString("de") + " €"
                            : categoria === "art" || categoria === "comm"
                            ? dati.length !== 3
                              ? converti(
                                  this.props.fatture.reduce((somma, elem) => {
                                    return (
                                      somma + elem.imponibile + elem.rivalsa
                                    ); 
                                  }, 0) - noteDiCredito.reduce((somma, elem)=>{
                                    return somma + elem.imponibile + elem.rivalsa;
                                  }, 0)-
                                    parseFromLocale(
                                      dati[6].importo.split(" ")[0]
                                    ) -
                                    totale_contributi -
                                    spese
                                ) + " €"
                              : (
                                  this.props.fatture.reduce((somma, elem) => {
                                    return (
                                      somma + elem.imponibile + elem.rivalsa
                                    );
                                  }, 0) - noteDiCredito.reduce((somma, elem)=>{
                                    return somma + elem.imponibile + elem.rivalsa;
                                  }, 0) -
                                  parseFromLocale(
                                    dati[2][2].importo.split(" ")[0]
                                  ) -
                                  totale_contributi -
                                  spese
                                ).toLocaleString("de") + " €"
                            : ""
                          : "0 €"}
                      </CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <Stats>
                  {[
                    {
                      i: "fas fa-hand-holding-usd",
                      t: "Totale incassato post imposte"
                    }
                  ]}
                </Stats>
              </CardFooter>
            </Card>
          </div>
        </div>
        <Chart
          contributi={this.props.contributi}
          fatture={this.props.fatture}
          noteDiCredito={noteDiCredito}
          spese={this.props.spese}
          user={this.props.auth.user}
        />
        <Tabella
          fatture={this.props.fatture}
          noteDiCredito={noteDiCredito}
          contributi={this.props.contributi}
          spese={this.props.spese}
          user={this.props.auth.user}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  annoSelezionato: state.auth.annoSelezionato,
  spese: state.spese.spese,
  fatture: state.fatture.fatture,
  noteDiCredito: state.fatture.noteDiCredito,
  contributi: state.contributi.contributi,
});

export default connect(mapStateToProps, {
  get_fatture_incassate_anno,
  get_contributi_anno,
  get_spese_anno,
  get_note_di_credito_incassate_anno
})(Dashboard);
