import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Spring } from "react-spring/renderprops";

const modalRoot = document.getElementById("modal");

class Modal extends Component {
  dismiss = () => {
    this.props.unmount();
  };

  element = document.createElement("div");
  componentDidMount() {
    document.body.style.overflow = "hidden";
    modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  render() {
    const { titolo, lista } = this.props.servizio;

    return ReactDOM.createPortal(
      <div
        onClick={this.dismiss}
        className="ui dimmer modals visible active"
        style={{ position: "fixed" }}
      >
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ friction: 50 }}
        >
          {props => (
            <div
              onClick={e => e.stopPropagation()}
              className="ui modal visible active"
              style={props}
            >
              <div className="header">{titolo}</div>
              <div className="content">
                <div className="ui list">
                  {lista.map(elemento => {
                    return (
                      <div className="item">
                        <i className="angle right icon" />
                        <div className="content">{elemento}</div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="actions">
                <button onClick={this.dismiss} className="ui button red">
                  Chiudi
                </button>
              </div>
            </div>
          )}
        </Spring>
      </div>,
      this.element
    );
  }
}
export default Modal;
