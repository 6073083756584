import React from "react";
import {
  CardTitle,
  CardSubtitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
  FormFeedback,
  FormText,
} from "reactstrap";

import { connect } from "react-redux";
import { get_clienti, invia_cliente } from "../../../actions/clienti";
import { get_fatture, genera_fattura } from "../../../actions/fatture";

import { renderToString } from "react-dom/server";

import { Link } from "react-router-dom";
import TabellaArticoli from "./TabellaArticoli";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { pdf } from "@react-pdf/renderer";
import PdfFattura from "./PdfFattura";
import windowSize from "react-window-size";
import moment from "moment";
import { showModalInviaFattura } from "../../../components/Modal/Modals";
import { inviaEmailFattura } from "../../../actions/sendMail";
import MaterialDatePicker from "../../components/Modal/MaterialDatePicker";

const MySwal = withReactContent(Swal);

class GeneraFattura extends React.Component {
  state = {
    fattura: {
      cliente: "",
      dataEmissione: "",
      nrFattura: "",
      articoli: [],
      metodoPagamento: "Non specificato",
      IBAN: "",
      intestato: "",
      terminiPagamento: "Non specificato",
      marcaDaBollo: false,
      codiceMarcaDaBollo: "",
      rivalsa: 0,
      marca_da_bollo: 0,
      imponibile: 0,
      note: "",
      percentualeRivalsa: 0,
    },
    errors: {
      cliente: false,
      messaggio_cliente: "",
      data_emissione: false,
      messaggio_data_emissione: "",
      nr_fattura: false,
      messaggio_nr_fattura: "",
      articoli: false,
      messaggio_articoli: "",
      codiceMarcaDaBollo: false,
      messaggio_codiceMarcaDaBollo: "",
    },
    height: 0,
  };

  componentDidMount() {
    this.props.get_clienti();
    this.props.get_fatture();
  }
  componentWillReceiveProps() {
    let fattura = this.state.fattura;

    if (this.props.user.categoria === "prof 2") {
      fattura.percentualeRivalsa = 0.04;
    }
    if (this.props.user.categoria === "prof 1")
      fattura.percentualeRivalsa = 0.02;

    const max = this.props.fatture.fatture.reduce((max, fattura) => {
      const anno_fattura = moment(fattura.dataEmissione)._d.getFullYear();

      if (
        fattura.nr_fattura >= max &&
        anno_fattura === new Date().getFullYear()
      ) {
        return fattura.nr_fattura;
      } else {
        return max;
      }
    }, 0);
    fattura.nrFattura = max + 1;
    fattura.iban = this.props.user.iban;
    fattura.intestato = this.props.user.intestato;

    this.setState({
      fattura,
    });
  }

  aggiungiArticolo = (articolo) => {
    let fattura = this.state.fattura;
    fattura.imponibile += articolo.totale;

    if (
      this.props.user.categoria === "prof 2" ||
      this.props.user.categoria === "prof 1"
    ) {
      fattura.rivalsa = fattura.imponibile * fattura.percentualeRivalsa;
    }

    if (fattura.imponibile >= 77.47) {
      fattura.marca_da_bollo = 2;
    }

    fattura.articoli = [...fattura.articoli, articolo];
    this.setState({
      fattura,
    });
  };

  rimuoviArticolo = (articolo) => {
    let fattura = this.state.fattura;

    fattura.imponibile -= articolo.totale;

    if (this.props.user.categoria === "prof 2") {
      fattura.rivalsa -= fattura.imponibile * 0.04;
    }

    if (fattura.imponibile < 77.47) {
      fattura.marca_da_bollo = 0;
    }

    fattura.articoli = fattura.articoli.filter((a) => {
      if (
        a.nome === articolo.nome &&
        a.prezzo === articolo.prezzo &&
        a.quantita === articolo.quantita
      ) {
        return false;
      } else {
        return true;
      }
    });

    this.setState({
      fattura,
    });
  };

  onInput = (e) => {
    let fattura = this.state.fattura;
    fattura[e.target.name] = e.target.value;
    fattura.rivalsa = fattura.imponibile * fattura.percentualeRivalsa;
    this.setState({ fattura });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    let errorFound = false;
    let errors = {
      cliente: false,
      messaggio_cliente: "",
      data_emissione: false,
      messaggio_data_emissione: "",
      nr_fattura: false,
      messaggio_nr_fattura: "",
      articoli: false,
      messaggio_articoli: "",
      codiceMarcaDaBollo: false,
      messaggio_codiceMarcaDaBollo: "",
    };

    const {
      cliente,
      articoli,
      dataEmissione,
      nrFattura,
      marca_da_bollo,
      codiceMarcaDaBollo,
      rivalsa,
      imponibile,
      marcaDaBollo,
    } = this.state.fattura;

    if (dataEmissione === "") {
      errorFound = true;
      errors.data_emissione = true;
      errors.messaggio_data_emissione =
        "Il campo 'Data emissione' non può essere vuoto";
    }

    if (cliente === "") {
      errorFound = true;
      errors.cliente = true;
      errors.messaggio_cliente = "Il campo 'Cliente' non può essere vuoto";
    }

    if (articoli.length === 0) {
      errorFound = true;
      errors.articoli = true;
      errors.messaggio_articoli = "Si prega di inserire almeno un articolo";
    }

    if (nrFattura === "") {
      errorFound = true;
      errors.nr_fattura = true;
      errors.messaggio_nr_fattura =
        "Il campo 'Nr. fattura' non può essere vuoto";
    }

    // if (codiceMarcaDaBollo === "" && imponibile >= 77.47) {
    //   errorFound = true;
    //   errors.codiceMarcaDaBollo = true;
    //   errors.messaggio_codiceMarcaDaBollo =
    //     "Il campo 'Codice marca da bollo' non può essere vuoto";
    // }

    if (
      codiceMarcaDaBollo !== "" &&
      imponibile >= 77.47 &&
      isNaN(parseInt(codiceMarcaDaBollo))
    ) {
      errorFound = true;
      errors.codiceMarcaDaBollo = true;
      errors.messaggio_codiceMarcaDaBollo = "Codice marca da bollo non valido";
    }

    this.setState({ errors: errors });
    if (errorFound) return;

    const blob = await pdf(
      <PdfFattura
        height={750}
        fattura={this.state.fattura}
        user={this.props.user}
        cliente={this.props.clienti.clienti.find((cliente) => {
          return cliente.id === parseInt(this.state.fattura.cliente);
        })}
      />
    ).toBlob();

    var reader = new FileReader();
    reader.readAsDataURL(blob);

    let pdfFile = "";

    reader.onloadend = await function (e) {
      this.props.genera_fattura({
        cliente: this.props.clienti.clienti.find((c) => {
          return c.id.toString() === cliente;
        }).denominazione,
        data_emissione: dataEmissione,
        nr_fattura: nrFattura,
        imponibile,
        marca_da_bollo,
        rivalsa,
        pdf: reader.result,
      });
      showModalInviaFattura(
        reader.result,
        this.props.inviaEmailFattura,
        this.props.user.email
      );
    }.bind(this);
    this.props.history.push("/dashboard/fatture");
  };

  toogleCheck = (e) => {
    let fattura = this.state.fattura;
    fattura.marcaDaBollo = !fattura.marcaDaBollo;
    if (fattura.marcaDaBollo === true) {
      fattura.marca_da_bollo = 0;
    } else {
      if (fattura.imponibile >= 77.47) {
        fattura.marca_da_bollo = 2;
      }
    }
    this.setState({
      fattura,
    });
  };

  getDataEmissione = (data) => {
    let fattura = this.state.fattura;
    fattura.dataEmissione = data;
    this.setState({ fattura });
  };

  render() {
    const { fattura, errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="__tab-wrapper">
            <CardTitle className="text-center">
              <i
                className="fas fa-receipt text-warning"
                style={{ marginRight: "1em" }}
              />
              Genera fattura
              <i
                className="fas fa-receipt text-warning"
                style={{ marginLeft: "1em" }}
              />
            </CardTitle>

            <Form onSubmit={this.onSubmit}>
              <Row>
                <Col sm="12" md="12">
                  <CardSubtitle className="text-center my-4">
                    Dati documento
                  </CardSubtitle>
                  <Row>
                    <Col sm="12" md="12">
                      <FormGroup>
                        <Label>Cliente</Label>
                        <Input
                          value={fattura.cliente}
                          type="select"
                          name="cliente"
                          onChange={this.onInput}
                          style={{ height: 39 }}
                          invalid={errors.cliente}
                        >
                          <option defaultValue></option>
                          {this.props.clienti.clienti
                            .sort((a, b) => {
                              return a.denominazione >= b.denominazione;
                            })
                            .map((cliente) => {
                              return (
                                <option key={cliente.id} value={cliente.id}>
                                  {cliente.denominazione}
                                </option>
                              );
                            })}
                        </Input>
                        <FormFeedback>{errors.messaggio_cliente}</FormFeedback>
                        <FormText>
                          <Link
                            onClick={() => {
                              MySwal.fire({
                                title: "Aggiungi cliente",
                                width: 1000,
                                showCloseButton: true,
                                confirmButtonText: "Aggiungi cliente",
                                html: renderToString(
                                  <div>
                                    <Row className="mt-4">
                                      <Col xs="12" sm="6">
                                        <FormGroup>
                                          <Label className="float-left">
                                            Denominazione
                                          </Label>
                                          <Input
                                            id="denominazione"
                                            type="text"
                                            name="denominazione"
                                            placeholder="Digita la denominazione del cliente"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="12" sm="6">
                                        <FormGroup>
                                          <Label className="float-left">
                                            Sede legale
                                          </Label>
                                          <Input
                                            id="luogo_esercizio"
                                            type="text"
                                            name="luogo_esercizio"
                                            placeholder="Digita la sede legale del cliente"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="12" sm="6">
                                        <FormGroup>
                                          <Label className="float-left">
                                            Codice Fiscale
                                          </Label>
                                          <Input
                                            id="codice_fiscale"
                                            type="text"
                                            name="codice_fiscale"
                                            placeholder="Digita il codice fiscale del cliente"
                                          />
                                        </FormGroup>
                                      </Col>
                                      <Col xs="12" sm="6">
                                        <FormGroup>
                                          <Label className="float-left">
                                            Partita IVA
                                          </Label>
                                          <Input
                                            id="partita_iva"
                                            type="text"
                                            name="partita_iva"
                                            placeholder="Digita la partita IVA del cliente"
                                          />
                                        </FormGroup>
                                      </Col>
                                    </Row>
                                  </div>
                                ),
                                focusConfirm: false,
                                preConfirm: () => {
                                  let cliente = {
                                    denominazione: document.getElementById(
                                      "denominazione"
                                    ).value,
                                    luogo_esercizio: document.getElementById(
                                      "luogo_esercizio"
                                    ).value,
                                    partita_iva: document.getElementById(
                                      "partita_iva"
                                    ).value,
                                    codice_fiscale: document.getElementById(
                                      "codice_fiscale"
                                    ).value,
                                  };

                                  if (
                                    cliente.denominazione !== "" &&
                                    cliente.luogo_esercizio !== "" &&
                                    cliente.partita_iva !== "" &&
                                    cliente.codice_fiscale !== ""
                                  ) {
                                    this.props.invia_cliente(cliente);
                                    return true;
                                  } else {
                                    return false;
                                  }
                                },
                              });
                            }}
                          >
                            Aggiungi nuovo cliente
                          </Link>
                        </FormText>
                      </FormGroup>
                    </Col>
                    <Col sm="12" md="6">
                      <MaterialDatePicker
                        onChange={this.getDataEmissione}
                        value={this.state.fattura.dataEmissione}
                        label="Data di emissione"
                        invalid={errors.data_emissione}
                        invalidMessage={errors.messaggio_data_emissione}
                        placeholder="Selezionare la data di emissione..."
                      />
                    </Col>
                    <Col sm="12" md="6">
                      <FormGroup>
                        <Label>Nr. fattura</Label>
                        <Input
                          type="number"
                          name="nrFattura"
                          value={fattura.nrFattura}
                          onChange={this.onInput}
                          invalid={errors.nr_fattura}
                        />
                        <FormFeedback>
                          {errors.messaggio_nr_fattura}
                        </FormFeedback>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="my-5">
                <CardSubtitle className="text-center my-4 w-100">
                  Articoli
                </CardSubtitle>
                <TabellaArticoli
                  articoli={this.state.fattura.articoli}
                  aggiungiArticolo={this.aggiungiArticolo}
                  rimuoviArticolo={this.rimuoviArticolo}
                />
                {errors.articoli ? (
                  <FormText className="text-danger ml-4">
                    {errors.messaggio_articoli}
                  </FormText>
                ) : (
                  ""
                )}
              </Row>
              <Row className="mt-5">
                <CardSubtitle className="text-center my-4 w-100">
                  Opzioni
                </CardSubtitle>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label>Metodo di pagamento</Label>
                    <Input
                      type="select"
                      name="metodoPagamento"
                      value={fattura.metodoPagamento}
                      onChange={this.onInput}
                      style={{ height: 39 }}
                    >
                      <option>Non specificato</option>
                      <option>Contanti</option>
                      <option>Assegno</option>
                      <option>Bonifico</option>
                    </Input>
                  </FormGroup>
                </Col>

                <Col sm="12" md="6">
                  <FormGroup>
                    <Label>Termini pagamento</Label>
                    <Input
                      type="select"
                      name="terminiPagamento"
                      value={fattura.terminiPagamento}
                      onChange={this.onInput}
                      style={{ height: 39 }}
                    >
                      <option>Non specificato</option>
                      <option>Immediato</option>
                      <option>15 giorni</option>
                      <option>30 giorni</option>
                      <option>60 giorni</option>
                      <option>90 giorni</option>
                      <option>120 giorni</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              {fattura.metodoPagamento === "Bonifico" ? (
                <Row>
                  <Col sm="12" md="6">
                    <FormGroup>
                      <Label>IBAN</Label>
                      <Input
                        type="text"
                        name="iban"
                        value={fattura.iban}
                        onChange={this.onInput}
                      ></Input>
                    </FormGroup>
                  </Col>

                  <Col sm="12" md="6">
                    <FormGroup>
                      <Label>Intestato a</Label>
                      <Input
                        type="text"
                        name="intestato"
                        value={fattura.intestato}
                        onChange={this.onInput}
                      ></Input>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {this.props.user.categoria === "prof 1" ? (
                <Row>
                  <Col sm="12" md="12">
                    <FormGroup>
                      <Label>Percentuale rivalsa</Label>
                      <Input
                        type="select"
                        name="percentualeRivalsa"
                        value={fattura.percentualeRivalsa}
                        onChange={this.onInput}
                        style={{ height: 39 }}
                      >
                        <option value={0.02}>2 %</option>
                        <option value={0.04}>4 %</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              {fattura.imponibile >= 77.47 ? (
                <Row>
                  <Col sm="12" md="6">
                    <FormGroup>
                      <Label>Codice marca da bollo</Label>
                      <Input
                        type="text"
                        name="codiceMarcaDaBollo"
                        value={fattura.codiceMarcaDaBollo}
                        onChange={this.onInput}
                        style={{ height: 39 }}
                        invalid={errors.codiceMarcaDaBollo}
                      ></Input>
                      <FormFeedback>
                        {errors.messaggio_codiceMarcaDaBollo}
                      </FormFeedback>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {fattura.imponibile >= 77.47 ? (
                <Row>
                  <Col sm="12" md="11">
                    <FormGroup>
                      <Label
                        check
                        style={{
                          fontSize: 16,
                        }}
                      >
                        <Input
                          className="ml-1"
                          style={{ marginTop: 5 }}
                          name="marcaDaBollo"
                          checked={fattura.marcaDaBollo}
                          onChange={this.toogleCheck}
                          type="checkbox"
                        />
                        <div className="ml-4">Marca da bollo a tuo carico</div>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              ) : (
                ""
              )}

              <Row className="mt-5">
                <CardSubtitle className="text-center my-4 w-100">
                  Note
                </CardSubtitle>
                <Col sm="12" md="12">
                  <FormGroup>
                    <Input
                      type="textarea"
                      name="note"
                      style={{ minHeight: 300 }}
                      value={fattura.note}
                      onChange={this.onInput}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="12" xs="12">
                  <Button
                    color="warning"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginTop: "2em",
                      color: "white",
                    }}
                    onClick={async () => {
                      const blob = await pdf(
                        <PdfFattura
                          height={750}
                          fattura={this.state.fattura}
                          user={this.props.user}
                          cliente={this.props.clienti.clienti.find(
                            (cliente) => {
                              return (
                                cliente.id ===
                                parseInt(this.state.fattura.cliente)
                              );
                            }
                          )}
                        />
                      ).toBlob();

                      const blobUrl = URL.createObjectURL(blob);

                      MySwal.fire({
                        title: "Anteprima fattura",
                        width: 800,
                        showCloseButton: true,
                        showConfirmButton: false,
                        html: renderToString(
                          <iframe
                            src={blobUrl}
                            type="application/pdf"
                            width="100%"
                            height={this.props.windowHeight * 0.85}
                          />
                        ),
                      });
                    }}
                  >
                    Anteprima fattura
                  </Button>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="12" xs="12">
                  <Button
                    type="submit"
                    color="info"
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      marginTop: "2em",
                    }}
                  >
                    Salva fattura
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  clienti: state.clienti,
  fatture: state.fatture,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_clienti,
  invia_cliente,
  get_fatture,
  genera_fattura,
  inviaEmailFattura,
})(windowSize(GeneraFattura));
