import { LOADING_SHOW, LOADING_HIDE } from "./types";
import { showModalSuccess, showModalError } from "../components/Modal/Modals";
import { tokenConfig } from "./auth";
import { axiosInstance } from "../axiosInstance";

export const sendMail = (mail) => async (dispatch) => {
  const body = {
    nome: mail.nome,
    email: mail.email,
    messaggio: mail.messaggio,
    telefono: mail.telefono,
  };

  try {
    dispatch({
      type: LOADING_SHOW,
    });

    await axiosInstance.post("/api/sendMail/", body);

    dispatch({
      type: LOADING_HIDE,
    });
    showModalSuccess("Messaggio inviato correttamente");
  } catch (error) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(error.response.data);
  }
};

export const inviaEmailFattura = (email) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_SHOW,
    });

    await axiosInstance.post(
      "/api/sendMail/?fattura=true",
      email,
      tokenConfig(getState)
    );

    dispatch({
      type: LOADING_HIDE,
    });
    showModalSuccess("Fattura inviata correttamente");
  } catch (error) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(error.response.data);
  }
};

export const inviaEmailNotaDiCredito = (email) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: LOADING_SHOW,
    });

    await axiosInstance.post(
      "/api/sendMail/?fattura=true",
      email,
      tokenConfig(getState)
    );

    dispatch({
      type: LOADING_HIDE,
    });
    showModalSuccess("Nota di credito inviata correttamente");
  } catch (error) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(error.response.data);
  }
};
