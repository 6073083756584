import { tokenConfig } from "./auth";
import {
  GET_SPESE,
  LOADING_SHOW,
  LOADING_HIDE,
  INSERISCI_SPESA,
  ELIMINA_SPESA,
} from "./types";
import { showModalSuccess, showModalError } from "../components/Modal/Modals";
import { axiosInstance } from "../axiosInstance";

export const inserisci_spese = (spesa) => async (dispatch, getState) => {
  var descrizione = "";

  if (spesa.descrizione !== "Altro" && spesa.categoria !== "Altro") {
    descrizione = spesa.descrizione;
  } else {
    descrizione = spesa.descrizione_inserita;
  }

  const body = JSON.stringify({
    categoria: spesa.categoria,
    descrizione,
    data:
      spesa.data.toLocaleDateString().split("/")[2] +
      "-" +
      spesa.data.toLocaleDateString().split("/")[1] +
      "-" +
      spesa.data.toLocaleDateString().split("/")[0],
    importo: spesa.importo,
  });

  try {
    dispatch({
      type: LOADING_SHOW,
    });

    const spesa = await axiosInstance.post(
      "/api/spese/",
      body,
      tokenConfig(getState)
    );

    dispatch({
      type: LOADING_HIDE,
    });
    showModalSuccess("La spesa è stata inserita correttamente!");
    dispatch({
      type: INSERISCI_SPESA,
      payload: spesa.data,
    });
  } catch (error) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(error.response.data);
  }
};
export const get_spese = () => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.get(
      "/api/spese/",
      tokenConfig(getState)
    );

    dispatch({
      type: GET_SPESE,
      payload: response.data,
    });
  } catch (error) {}
};

export const get_spese_anno = (anno) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.get(
      `/api/spese?anno=${anno}`,
      tokenConfig(getState)
    );
    dispatch({
      type: GET_SPESE,
      payload: response.data,
    });
  } catch (error) {}
};

export const elimina_spese = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/spese/${id}`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_SPESA,
      payload: response.data,
    });
    showModalSuccess("La spesa è stata eliminata correttamente!");
  } catch (error) {
    showModalError("Si è verificato un errore... Riprovare");
  }
};
