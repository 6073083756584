import React, { Component } from "react";

import { login } from "../../../actions/auth";
import { connect } from "react-redux";

import { Redirect } from "react-router-dom";

import "./style.css";

import image from "../../../assets/login/header.jpg";

class Login extends Component {
  state = {
    email: "",
    password: "",
    errori: {
      stato: false,
      email: false,
      password: false,
      messaggi: [],
    },
  };

  componentDidMount() {
    document.title = "SEVAT | Login";
  }

  validateEmail = (email) => {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const { email, password } = this.state;

    var stato = this.state;
    stato.errori = {
      stato: false,
      nome: false,
      email: false,
      messaggio: false,
      messaggi: [],
    };

    if (email === "") {
      stato.errori.stato = true;
      stato.errori.email = true;
      stato.errori.messaggi.push("Email: si prega di inserire un'email");
    }
    // else if (!this.validateEmail(email)) {
    //     stato.errori.stato = true;
    //     stato.errori.email = true;
    //     stato.errori.messaggi.push("Email: email non valida");
    // }

    if (password === "") {
      stato.errori.stato = true;
      stato.errori.password = true;
      stato.errori.messaggi.push("Password: si prega di inserire una password");
    }

    this.setState({ stato });

    if (this.state.errori.stato) return;

    this.props.login(this.state.email, this.state.password);

    this.setState({
      email: "",
      password: "",
      errori: {
        stato: false,
        email: false,
        password: false,
        messaggi: [],
      },
    });
  };

  render() {
    const { email, password, errori } = this.state;

    const { auth } = this.props;

    if (auth.isAuthenticated && auth.user) {
      if (!auth.user.is_staff) return <Redirect to="/dashboard/home" />;
      else return <Redirect to="/gestione-news/inserisci" />;
    }

    return (
      <div
        className="login-container"
        style={{ backgroundImage: `url(${image})` }}
      >
        <div className="contatti-form">
          <div className="ui header medium centered">Login</div>
          <form className="ui form" onSubmit={this.onSubmit}>
            <div className={"field" + (errori.email ? " error" : "")}>
              <label>Email</label>
              <input
                type="text"
                name="email"
                value={email}
                placeholder="Inserire email"
                onChange={this.onChange}
              />
            </div>
            <div className={"field" + (errori.password ? " error" : "")}>
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={password}
                placeholder="Inserire password"
                onChange={this.onChange}
              />
            </div>
            {errori.stato ? (
              <div className="ui negative message">
                <i className="close icon" onClick={this.cancellaErrori} />
                {errori.messaggi.map((descrizione) => {
                  return (
                    <div
                      className="header"
                      style={{
                        padding: 1,
                        fontSize: 13,
                      }}
                    >
                      {descrizione}
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <button
              className="btn btn-primary"
              type="submit"
              style={{
                margin: "auto",
                display: "block",
                backgroundColor: "#0E6EB8",
                color: "white",
              }}
            >
              Login
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.msg,
});

export default connect(mapStateToProps, { login })(Login);
