import React, { Component } from "react";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { localeConvert } from "../../fucntions/functions";
import { URL } from "../../../axiosInstance";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    width: "10%",
    height: "auto",
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginTop: 4,
    textAlign: "left",
    fontWeight: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

export default class PdfNotaDiCredito extends Component {
  render() {
    return (
      <Document>
        <Page style={styles.body}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "50%" }}>
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#448aff",
                  }}
                  fixed
                >
                  {this.props.user.denominazione}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Partita IVA: {this.props.user.partita_iva}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Codice fiscale: {this.props.user.codice_fiscale}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.user.luogo_esercizio}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#448aff",
                }}
              >
                Nota di credito
              </Text>
              <Text style={styles.header} fixed>
                nr. {this.props.nota.numero} del{" "}
                {this.props.nota.data !== ""
                  ? this.props.nota.data.toLocaleDateString()
                  : ""}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              height: 2,
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: "#448aff",
            }}
          />
          {this.props.cliente ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#448aff",
                  }}
                  fixed
                >
                  Spettabile
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.cliente.denominazione}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Partita IVA: {this.props.cliente.partita_iva}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Codice fiscale: {this.props.cliente.codice_fiscale}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.cliente.luogo_esercizio}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}

          <View
            style={{
              marginTop: 50,
              backgroundColor: "#448aff",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <Text
              style={{
                color: "white",
                fontSize: 12,
                padding: 2,
              }}
            >
              Descrizione
            </Text>
          </View>
          <Text
            style={{
              marginTop: 5,
              fontSize: 11,
              padding: 2,
            }}
          >
            {this.props.nota.descrizione}
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginTop: 150,
            }}
          >
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  padding: 2,
                }}
              >
                Totale onorario:
              </Text>
            </View>
            <View
              style={{
                width: "15%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  padding: 2,
                }}
              >
                € {localeConvert(this.props.nota.imponibile)}
              </Text>
            </View>
          </View>
          {this.props.nota.rivalsa !== 0 ? (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  {this.props.user.categoria === "prof 2"
                    ? "Rivalsa INPS:"
                    : "Rivalsa professionale " +
                      this.props.nota.percentuale_rivalsa * 100 +
                      "%:"}
                </Text>
              </View>
              <View
                style={{
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  € {localeConvert(this.props.nota.rivalsa)}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}
          {this.props.nota.importo_marca_da_bollo !== 0 ? (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  Marca da bollo:
                </Text>
              </View>
              <View
                style={{
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  € {localeConvert(this.props.nota.importo_marca_da_bollo)}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}

          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 16,
                  padding: 2,
                  color: "#448aff",
                }}
              >
                Totale nota di credito:
              </Text>
            </View>
            <View
              style={{
                width: "15%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 16,
                  padding: 2,
                  color: "#448aff",
                }}
              >
                €{" "}
                {localeConvert(
                  parseFloat(this.props.nota.imponibile) +
                    parseFloat(this.props.nota.rivalsa) +
                    parseFloat(this.props.nota.importo_marca_da_bollo)
                )}
              </Text>
            </View>
          </View>

          <View>
            <Text style={{ fontSize: 10, marginTop: 50, fontStyle: "italic" }}>
              Operazione esente da Iva in quanto effettuata ai sensi dell'art.1,
              commi da 54 a 89 della Legge n. 190/2014 Regime forfetario. Il
              compenso non è soggetto a ritenute d'acconto ai sensi della legge
              190 del 23 Dicembre 2014 art. 1 comma 67. Ed ai sensi della Legge
              n.145/2018
            </Text>
          </View>
          {this.props.nota.codice_marca_da_bollo !== "" ? (
            <View>
              <Text
                style={{ fontSize: 10, marginTop: 20, fontStyle: "italic" }}
              >
                Imposta di bollo assolta sull'originale. ID{" "}
                {this.props.nota.codice_marca_da_bollo}
              </Text>
            </View>
          ) : (
            <View />
          )}
        </Page>
      </Document>
    );
  }
}
