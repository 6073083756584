import { GET_CONTRIBUTI, ELIMINA_CONTRIBUTI } from "../actions/types";

const initialState = {
    contributi: [],
    start: 0
};

export default function(state = initialState, action) {
    switch (action.type) {
        case GET_CONTRIBUTI:
            return {
                ...state,
                contributi: action.payload
            };

        case ELIMINA_CONTRIBUTI:
            return {
                ...state,
                contributi: state.contributi.filter(fattura => {
                    return fattura.id.toString() !== action.payload;
                })
            };

        default:
            return state;
    }
}
