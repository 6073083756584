import React, { Component } from "react";

import MaterialTable from "material-table";

import { locale } from "../../calcoloTasse/impostazioni";
import { converti } from "../../calcoloTasse/funzioni";

import moment from "moment";
import { showModalConferma } from "../../../components/Modal/Modals";

class TabellaContributi extends Component {
  render() {
    const fatture = this.props.data.contributi.map(contributi => {
      return {
        id: contributi.id,
        data: moment(contributi.data),
        totale: contributi.importo
      };
    });

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[
            { title: "ID", field: "id", hidden: true },
            {
              title: "Data",
              field: "data",
              type: "datetime",
              defaultSort: "desc",
              render: rowData => {
                return rowData.data.format("MMMM YYYY");
              },
              cellStyle: { fontSize: 14, padding: "4px 12px" }
            },
            {
              title: "Totale (€)",
              field: "totale",
              type: "numeric",
              cellStyle: { fontSize: 14, padding: "4px 12px" },
              render: rowData => {
                return converti(rowData.totale);
              }
            }
          ]}
          data={fatture}
          actions={[
            {
              icon: "delete_forever",
              tooltip: "Cancella fattura",
              onClick: (event, rowData) => {
                showModalConferma(
                  "Sei sicuro di voler eliminare i contributi?",
                  this.props.delete,
                  rowData.id
                );
              },
              iconProps: {
                style: {
                  fontSize: 30,
                  color: "red"
                }
              }
            }
          ]}
          title="Lista contributi"
          options={{
            actionsColumnIndex: -1,
            headerStyle: {
              fontSize: 14,
              padding: "4px 12px"
            },
            pageSize: 10,
            pageSizeOptions: [10]
          }}
          localization={locale}
        />
      </div>
    );
  }
}
export default TabellaContributi;
