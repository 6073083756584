import React from "react";

import CircularProgressBar from "../../CircularProgressBar/CircularProgressBar";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50,
  userSelect: "none"
};

export default function PrimaSezione() {
  return (
    <Fade>
      <div style={style}>
        <div
          className="ui section huge header centered"
          style={{ marginBottom: 50 }}
        >
          SEVAT in numeri
        </div>
        <div className="ui three column grid container">
          <div className="nine wide mobile three wide computer column centered">
            <CircularProgressBar
              number={"3"}
              description="Le aziende del gruppo"
            />
          </div>
          <div className="nine wide mobile three wide computer column centered">
            <CircularProgressBar number={18} description="Anni di esperienza" />
          </div>
          <div className="nine wide mobile three wide computer column centered">
            <CircularProgressBar
              number={5}
              description="Lingue supportate"
              sub="ENG - ESP - PORT - FRA - RUS"
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}
