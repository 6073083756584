import { GET_SPESE, ELIMINA_SPESA, INSERISCI_SPESA } from "../actions/types";

const initialState = {
  spese: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SPESE:
      return {
        ...state,
        spese: action.payload
      };

    case ELIMINA_SPESA:
      return {
        ...state,
        spese: state.spese.filter(spese => {
          return spese.id.toString() !== action.payload;
        })
      };

    case INSERISCI_SPESA:
      return {
        spese: [action.payload, ...state.spese]
      };

    default:
      return state;
  }
}
