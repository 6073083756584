import React, { Component } from "react";
import { Link } from "react-router-dom";

import logo from "../../assets/icon.png";

const openButtonStyle = {
  position: "absolute",
  left: "auto",
  right: 30,
  top: 24,
  zIndex: 999,
};

const logoStyle = {
  position: "absolute",
  left: 30,
  right: "auto",
  top: 0,
  zIndex: 999,
  color: "white",
};

const linkedinStyle = {
  position: "absolute",
  left: 150,
  right: "auto",
  top: 3,
  zIndex: 999,
  color: "#0e76a8",
  padding: 0,
  fontSize: 10,
};

const menuItemStyle = {
  textAlign: "center",
};

const closeButtonStyle = {
  margin: 10,
  fontSize: 24,
  color: "white",
};

class Menu extends Component {
  state = {
    open: false,
  };

  onButtonPressed = () => {
    this.setState({ open: true });
  };

  closeMenu = () => {
    this.setState({ open: false });
  };

  render() {
    return (
      <React.Fragment>
        <div
          className="toc item"
          style={openButtonStyle}
          onClick={this.onButtonPressed}
        >
          <i className="sidebar icon large inverted" />
        </div>
        <Link to="/" className="ui header" style={logoStyle}>
          <img
            src={logo}
            alt="logo"
            style={{
              height: "40px",
              width: "40px",
              margin: 0,
              marginRight: ".5em",
            }}
          />
          SEVAT
        </Link>
        <a
          className="ui header"
          style={linkedinStyle}
          href="https://www.linkedin.com/company/sevat-s-r-l/"
          target="_blank"
        >
          <img
            alt="linkedin logo"
            src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/ca/LinkedIn_logo_initials.png/480px-LinkedIn_logo_initials.png"
          />
        </a>

        <div
          className={
            "ui sidebar vertical overlay right menu inverted pushable " +
            (this.state.open ? " visible" : "")
          }
          style={{
            zIndex: 999,
            backgroundColor: "#393939",
          }}
        >
          <i
            className="fas fa-times"
            onClick={this.closeMenu}
            style={closeButtonStyle}
          />
          <Link
            to="/"
            className="item centered aligned"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            Homepage
          </Link>
          <Link
            to="/team"
            className="item"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            Team
          </Link>
          <Link
            to="/servizi"
            className="item"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            Servizi
          </Link>
          <Link
            to="/perche"
            className="item"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            Perchè noi
          </Link>
          <Link
            to="/sedi"
            className="item"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            Sedi
          </Link>
          <Link
            to="/news"
            className="item"
            onClick={this.closeMenu}
            style={menuItemStyle}
          >
            News
          </Link>
          <Link
            to="/login"
            className="item "
            onClick={this.closeMenu}
            style={{
              textAlign: "center",
              backgroundColor: "#0E6EB8",
            }}
          >
            Login
          </Link>
        </div>
      </React.Fragment>
    );
  }
}
export default Menu;
