import React, { Component } from "react";
import ReactDOM from "react-dom";

class Modal extends Component {
  toggle = () => {
    this.props.toggle(false);
  };
  modalRoot = document.getElementById("modal");
  element = document.createElement("div");
  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  render() {
    return ReactDOM.createPortal(
      <div className="my-modal-overlay" onClick={this.toggle}>
        <div onClick={(e) => e.stopPropagation()} className="ui modal">
          <div className="header">
            {this.props.title}
            <button
              type="button"
              className="close"
              onClick={this.toggle}
              style={{ color: "red", cursor: "pointer" }}
            >
              &times;
            </button>
          </div>
          <div className="content">{this.props.children}</div>
          {this.props.hideActions === true ? (
            ""
          ) : (
            <div className="actions">
              <button onClick={this.toggle} className="ui button red">
                Chiudi
              </button>
            </div>
          )}
        </div>
      </div>,
      this.element
    );
  }
}
export default Modal;
