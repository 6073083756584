import React, { Component } from "react";

import { createBrowserHistory } from "history";
import ReactGA from "react-ga";
import { Router, Route, Switch } from "react-router-dom";

import DefaultLayout from "./Layout/DefaultLayout";

import Homepage from "./pages/Homepage/Homepage";
import Studio from "./pages/Studio/Studio";
import Servizi from "./pages/Servizi/Servizi";
import Sedi from "./pages/Sedi/Sedi";
import Perche from "./pages/Perche/Perche";
import Login from "./pages/Login/Login";
import News from "./pages/News/News";

import "bootstrap/dist/css/bootstrap.css";
import "../dashboard/assets/scss/paper-dashboard.css";
import "../dashboard/assets/demo/demo.css";

import Dashboard from "../dashboard/layouts/Dashboard/Dashboard";
import Manage from "../components/pages/News/Manage";

import Spinner from "../components/Spinner/Spinner";

import { connect } from "react-redux";
import { loadUser } from "../actions/auth";

import "./style.css";
import PrivacyPolicy from "./pages/Policies/PrivacyPolicy";
import CookiePolicy from "./pages/Policies/CookiePolicy";

const hist = createBrowserHistory();
ReactGA.initialize("UA-169050883-1");
hist.listen((location, action) => {
  if (location.pathname + location.search !== "/index") {
    ReactGA.pageview(location.pathname + location.search);
  }
});

class App extends Component {
  componentDidMount() {
    this.props.loadUser();
  }
  render() {
    return (
      <Router history={hist}>
        {this.props.loading ? <Spinner visible /> : <Spinner />}
        <Switch>
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/gestione-news/" component={Manage} />

          <DefaultLayout>
            <Route exact path="/" component={Homepage} />
            <Route exact path="/team" component={Studio} />
            <Route exact path="/servizi" component={Servizi} />
            <Route exact path="/sedi" component={Sedi} />
            <Route exact path="/news" component={News} />
            <Route exact path="/perche" component={Perche} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/cookie-policy" component={CookiePolicy} />
          </DefaultLayout>
        </Switch>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.loading.loading,
});

export default connect(mapStateToProps, { loadUser })(App);
