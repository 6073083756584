import React from "react";

import "./spinner.css";

// PROPS:
// visible => true : false

class Spinner extends React.Component {
    componentDidMount() {
      
        if (this.props.visible) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
    }

    render() {
        return (
            <div
                className={
                    this.props.visible
                        ? "spinner-container"
                        : "spinner-container fade-out"
                }
            >
                <div className="lds-default">
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                    <div />
                </div>
            </div>
        );
    }
}

export default Spinner;
