import React from "react";
import { Redirect } from "react-router-dom";

import Header from "../../components/Header/Header.jsx";
import Footer from "../../components/Footer/Footer.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.jsx";

import dashboardRoutes from "../../routes/dashboard.jsx";

import store from "../../../store";
import { loadUser } from "../../../actions/auth";

import PrivateRoute from "../../../common/PrivateRoute";

import "../../style.css";
import InserisciFattura from "../../views/Fatture/InserisciFattura.js";
import GeneraFattura from "../../views/Fatture/GeneraFattura.js";
import Anagrafica from "../../views/Anagrafica/Anagrafica.js";
import GeneraNotaDiCredito from "../../views/NoteDiCredito/GeneraNotaDiCredito.js";

class Dashboard extends React.Component {
  componentWillMount() {
    store.dispatch(loadUser());
  }

  componentDidMount() {
    document.title = "SEVAT | Dashboard";
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      this.refs.mainPanel.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    }
  }

  render() {
    if (
      this.props.location.pathname !== "/dashboard/home" &&
      this.props.location.pathname !== "/dashboard/fatture" &&
      this.props.location.pathname !== "/dashboard/note_di_credito" &&
      this.props.location.pathname !== "/dashboard/note_di_credito/genera" &&
      this.props.location.pathname !== "/dashboard/contributi" &&
      this.props.location.pathname !== "/dashboard/imposte" &&
      this.props.location.pathname !== "/dashboard/spese" &&
      this.props.location.pathname !== "/dashboard/fatture/aggiungi" &&
      this.props.location.pathname !== "/dashboard/fatture/genera" &&
      this.props.location.pathname !== "/dashboard/anagrafica" &&
      this.props.location.pathname !== "/dashboard/clienti"
    ) {
      return <Redirect to="/dashboard/home" />;
    } else
      return (
        <div className="wrapper">
          <Sidebar
            {...this.props}
            routes={dashboardRoutes}
            bgColor="black"
            activeColor="info"
          />
          <div className="main-panel" ref="mainPanel">
            <Header {...this.props} />
            {dashboardRoutes.map((prop, key) => {
              return (
                <PrivateRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  exact
                />
              );
            })}
            <PrivateRoute
              path="/dashboard/fatture/aggiungi"
              component={InserisciFattura}
              exact
            />
            <PrivateRoute
              path="/dashboard/fatture/genera"
              component={GeneraFattura}
              exact
            />
             <PrivateRoute
              path="/dashboard/note_di_credito/genera"
              component={GeneraNotaDiCredito}
              exact
            />
            <PrivateRoute
              path="/dashboard/anagrafica"
              component={Anagrafica}
              exact
            />
            <Footer fluid />
          </div>
        </div>
      );
  }
}

export default Dashboard;
