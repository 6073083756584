import React from "react";

import image from "../../../assets/homepage/terzaSezione.jpg";

const style = {
  padding: "150px 0px",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  backgroundAttachment: "fixed",
  userSelect: "none",
  color: "white",
  backgroundImage: `url(${image})`,
  width: "100%",
  margin: 0
};

export default function TerzaSezione() {
  return (
    <div className="ui grid center aligned middle aligned" style={style}>
      <div className="row">
        <div className="twelve wide column">
          <div
            className="ui header medium inverted"
            style={{ marginBottom: 0 }}
          >
            <strong>
              “L'amore per principio, l'ordine per fondamento ed il progresso
              per fine”
            </strong>
          </div>
          <div className="ui sub inverted">
            <i>Auguste Comte</i>
          </div>
        </div>
      </div>
    </div>
  );
}
