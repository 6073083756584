import React, { Component } from "react";

import MaterialTable from "material-table";

import { Label, Row, Col, Input, FormGroup } from "reactstrap";

import { converti } from "../../calcoloTasse/funzioni";

import { renderToString } from "react-dom/server";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

import { withRouter } from "react-router-dom";

const MySwal = withReactContent(Swal);

class TabellaArticoli extends Component {
  render() {
    return (
      <MaterialTable
        style={{
          minWidth: "95%",
          margin: "auto",
          boxShadow: "none",
          border: "1px solid rgba(224, 224, 224, 1)"
        }}
        columns={[
          {
            title: "Nome Prodotto",
            field: "nome",
            type: "text",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Prezzo (€)",
            field: "prezzo",
            type: "numeric",
            cellStyle: { fontSize: 14 },
            render: rowData => {
              return converti(rowData.prezzo);
            }
          },
          {
            title: "Quantità",
            field: "quantita",
            type: "numeric",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Unità di misura",
            type: "numeric",
            field: "unita_misura",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Totale (€)",
            field: "totale",
            type: "numeric",
            cellStyle: { fontSize: 14 },
            render: rowData => {
              return converti(rowData.totale);
            }
          }
        ]}
        data={this.props.articoli}
        actions={[
          {
            icon: "delete_forever",
            tooltip: "Cancella fattura",
            onClick: (event, rowData) => {
              this.props.rimuoviArticolo(rowData);
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "red"
              }
            }
          },
          {
            icon: IconaAggiungi,
            tooltip: "Aggiungi Articolo",
            iconProps: {
              fontSize: "large",
              color: "primary"
            },
            isFreeAction: true,
            onClick: () => {
              MySwal.fire({
                title: "Aggiungi articolo",
                width: 1000,
                showCloseButton: true,
                confirmButtonText: "Aggiungi articolo",
                html: renderToString(
                  <div>
                    <FormGroup className="mt-4">
                      <Label for="exampleEmail" className="float-left">
                        Nome articolo
                      </Label>
                      <Input
                        id="nome-prodotto"
                        type="text"
                        name="email"
                        placeholder="Digita il nome dell'articolo"
                      />
                    </FormGroup>
                    <Row>
                      <Col xs="12" sm="4">
                        <FormGroup>
                          <Label for="exampleEmail" className="float-left">
                            Prezzo unitario
                          </Label>
                          <Input
                            id="prezzo-unitario"
                            type="number"
                            name="prezzo-unitario"
                            placeholder="Digita il prezzo unitario"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="4">
                        <FormGroup>
                          <Label for="exampleEmail" className="float-left">
                            Quantità
                          </Label>
                          <Input
                            id="numero-articoli"
                            type="number"
                            name="numero-articoli"
                            placeholder="Digita il numero di articoli"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="4">
                        <FormGroup>
                          <Label for="exampleEmail" className="float-left">
                            Un. misura
                          </Label>
                          <Input
                            id="unita-misura"
                            type="text"
                            name="unita-misura"
                            placeholder="Digita unità di misura (opzionale)"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ),
                focusConfirm: false,
                preConfirm: () => {
                  let articolo = {
                    nome: document.getElementById("nome-prodotto").value,
                    prezzo: document.getElementById("prezzo-unitario").value,
                    quantita: document.getElementById("numero-articoli").value,
                    unita_misura: document.getElementById("unita-misura").value,
                    totale:
                      document.getElementById("prezzo-unitario").value *
                      document.getElementById("numero-articoli").value
                  };

                  if (
                    articolo.nome !== "" &&
                    articolo.prezzo !== "" &&
                    articolo.quantita !== ""
                  ) {
                    this.props.aggiungiArticolo(articolo);
                    return true;
                  } else {
                    return false;
                  }
                }
              });
            }
          }
        ]}
        title=""
        options={{
          actionsColumnIndex: -1,
          search: false,
          headerStyle: {
            fontSize: 14
          },
          sorting: false,
          paging: false
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} di {count}", // {from}-{to} of {count}
            labelRowsPerPage: "Righe per pagina:", // Rows per page:,
            labelRowsSelect: "righe",
            firstAriaLabel: "Prima pagina", // First Page
            firstTooltip: "Prima pagina", // First Page
            previousAriaLabel: "Pagina precedente", // Previous Page
            previousTooltip: "Pagina precedente", // Previous Page
            nextAriaLabel: "Pagina successiva", // Next Page
            nextTooltip: "Pagina successiva", // Next Page
            lastAriaLabel: "Ultima pagina", // Last Page
            lastTooltip: "Ultima pagina" // Last Page
          },
          toolbar: {
            nRowsSelected: "{0} righe selezionate", // {0} row(s) selected
            showColumnsTitle: "Mostra colonne", // Show Columns
            showColumnsAriaLabel: "Mostra colonne", // Show Columns
            exportTitle: "Esporta", // Export
            exportAriaLabel: "Esporta", // Export
            exportName: "Esporta CSV", // Export as CSV
            searchTooltip: "Cerca" // Search
          },
          header: {
            actions: "" // Actions
          },
          body: {
            emptyDataSourceMessage: "Nessuna articolo inserito", // No records to display
            filterRow: {
              filterTooltip: "Filtra" // Filter
            }
          }
        }}
      />
    );
  }
}
export default withRouter(TabellaArticoli);

function IconaAggiungi() {
  return (
    <div>
      <span className="MuiIconButton-label">
        <span
          style={{ fontSize: 31, color: "#3f51b5" }}
          className="material-icons MuiIcon-root MuiIcon-colorPrimary MuiIcon-fontSizeLarge"
          aria-hidden="true"
        >
          add
        </span>
        <span className="MuiIcon-colorPrimary" style={{ color: "#3f51b5" }}>
          Aggiungi nuovo articolo
        </span>
      </span>
      <span className="MuiTouchRipple-root" />
    </div>
  );
}
