import React, { Component } from "react";
import { connect } from "react-redux";
import {
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  Row,
  Button,
  FormGroup,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import { get_clienti } from "../../../actions/clienti";
import {
  genera_nota_di_credito,
  get_fatture,
  get_note_di_credito,
} from "../../../actions/fatture";
import MaterialDatePicker from "../../components/Modal/MaterialDatePicker";
import moment from "moment";
import PdfNotaDiCredito from "./PdfNotaDiCredito";
import { pdf } from "@react-pdf/renderer";
import Swal from "sweetalert2";
import { renderToString } from "react-dom/server";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class GeneraNotaDiCredito extends Component {
  state = {
    nota: {
      numero: "",
      data: "",
      descrizione: "",
      id_fattura: "",
      imponibile: "",
      tipo_fattura: "",
      rivalsa: "",
      percentuale_rivalsa: 0,
      codice_marca_da_bollo: "",
      importo_marca_da_bollo: "",
    },
    errori: {
      numero: false,
      messaggio_numero: false,
      data: false,
      messaggio_data: false,
      descrizione: false,
      messaggio_descrizione: false,
      id_fattura: false,
      messaggio_id_fattura: false,
      imponibile: false,
      messaggio_imponibile: false,
      codice_marca_da_bollo: false,
      messaggio_codice_marca_da_bollo: false,
    },
  };
  componentDidMount() {
    this.props.get_fatture();
    this.props.get_clienti();
    this.props.get_note_di_credito();
  }

  componentWillReceiveProps() {
    var nota = this.state.nota;
    const numero_nota = this.props.fatture.noteDiCredito.reduce((max, nota) => {
      const anno = moment(nota.data)._d.getFullYear();

      if (nota.numero >= max && anno === new Date().getFullYear()) {
        return nota.numero;
      } else {
        return max;
      }
    }, 0);
    nota.numero = numero_nota + 1;
    this.setState(nota);
  }

  onInput = (e) => {
    var nota = this.state.nota;
    nota[e.target.name] = e.target.value;

    if (e.target.name === "imponibile") {
      nota.rivalsa = nota.imponibile * nota.percentuale_rivalsa;
      if (nota.imponibile >= 77.47) {
        nota.importo_marca_da_bollo = 2;
      } else {
        nota.importo_marca_da_bollo = 0;
      }
    }

    this.setState({ nota });
  };

  getData = (data) => {
    let nota = this.state.nota;
    nota.data = data;
    this.setState({ nota });
  };

  selezionaFattura = (e) => {
    const id_fattura = e.target.value;
    var nota = this.state.nota;
    if (id_fattura === "-1") {
      nota.id_fattura = "";
      nota.tipo_fattura = "";
    } else {
      const fattura = this.props.fatture.fatture.find((f) => {
        return f.id === parseInt(id_fattura);
      });
      nota.id_fattura = fattura.id;
      nota.tipo_fattura = fattura.generata === true ? "Generata" : "Importata";
      nota.percentuale_rivalsa = fattura.rivalsa / fattura.imponibile;
      nota.rivalsa = nota.imponibile * nota.percentuale_rivalsa;
    }

    this.setState({
      nota,
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    var errorFound = false;
    var errori = {
      numero: false,
      messaggio_numero: false,
      data: false,
      messaggio_data: false,
      descrizione: false,
      messaggio_descrizione: false,
      id_fattura: false,
      messaggio_id_fattura: false,
      imponibile: false,
      messaggio_imponibile: false,
      codice_marca_da_bollo: false,
      messaggio_codice_marca_da_bollo: false,
    };

    const {
      numero,
      data,
      descrizione,
      id_fattura,
      imponibile,
      codice_marca_da_bollo,
    } = this.state.nota;
    const { fatture } = this.props.fatture;
    const { user } = this.props;
    const { nota } = this.state;

    if (data === "") {
      errorFound = true;
      errori.data = true;
      errori.messaggio_data = "Il campo 'Data' non può essere vuoto";
    }
    if (numero === "") {
      errorFound = true;
      errori.numero = true;
      errori.messaggio_numero =
        "Il campo 'Nr. nota di credito' non può essere vuoto";
    }
    if (parseInt(id_fattura) < 0 || isNaN(parseInt(id_fattura))) {
      errorFound = true;
      errori.id_fattura = true;
      errori.messaggio_id_fattura =
        "Si prega di selezzionare la fattura relativa";
    }
    if (imponibile === "") {
      errorFound = true;
      errori.imponibile = true;
      errori.messaggio_imponibile =
        "Il campo 'Imponibile' non può essere vuoto";
    }

    if (parseInt(imponibile) <= 0) {
      errorFound = true;
      errori.imponibile = true;
      errori.messaggio_imponibile =
        "Il campo 'Imponibile' non può essere negativo";
    }

    if (descrizione === "") {
      errorFound = true;
      errori.descrizione = true;
      errori.messaggio_descrizione =
        "Il campo 'Descrizione' non può essere vuoto";
    }

    if (parseFloat(imponibile) >= 77.47 && codice_marca_da_bollo == "") {
      errorFound = true;
      errori.codice_marca_da_bollo = true;
      errori.messaggio_codice_marca_da_bollo =
        "Il campo 'Codice marca da bollo' non può essere vuoto";
    }

    this.setState({ errori });
    if (errorFound) return;

    const fattura = fatture.find((f) => {
      return f.id === parseInt(nota.id_fattura);
    });
    var cliente = {};
    if (fattura !== undefined) {
      cliente = this.props.clienti.clienti.find((cliente) => {
        return cliente.denominazione === fattura.cliente;
      });
    }
    const blob = await pdf(
      <PdfNotaDiCredito
        height={750}
        nota={nota}
        user={user}
        cliente={cliente}
      />
    ).toBlob();

    var reader = new FileReader();
    reader.readAsDataURL(blob);

    var pdfFile = "";

    reader.onloadend = await function (e) {
      this.props.genera_nota_di_credito({
        numero,
        data: moment(data).format("YYYY-MM-DD"),
        descrizione,
        id_fattura: fattura.id,
        nr_fattura: fattura.nr_fattura,
        tipo_fattura: nota.tipo_fattura,
        imponibile,
        rivalsa: nota.rivalsa,
        marca_da_bollo: nota.importo_marca_da_bollo,
        pdf: reader.result,
      });
    }.bind(this);
    this.props.history.push("/dashboard/note_di_credito");
  };

  render() {
    const { nota, errori } = this.state;
    const { fatture } = this.props.fatture;
    const { user } = this.props;

    return (
      <div className="content">
        <div className="__tab-wrapper">
          <CardTitle className="text-center">
            <i
              className="fas fa-receipt text-warning"
              style={{ marginRight: "1em" }}
            />
            Genera nota di credito
            <i
              className="fas fa-receipt text-warning"
              style={{ marginLeft: "1em" }}
            />
          </CardTitle>
          <Form onSubmit={this.onSubmit}>
            <CardSubtitle className="text-center my-4">
              Dati documento
            </CardSubtitle>

            <Row>
              <Col sm="12" md="4">
                <FormGroup>
                  <Label>Nr. nota di credito</Label>
                  <Input
                    type="number"
                    name="numero"
                    value={nota.numero}
                    onChange={this.onInput}
                    invalid={errori.numero}
                  />
                  <FormFeedback>{errori.messaggio_numero}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="12" md="4">
                <MaterialDatePicker
                  onChange={this.getData}
                  value={nota.data}
                  label="Data"
                  invalid={errori.data}
                  invalidMessage={errori.messaggio_data}
                  placeholder="Selezionare la data..."
                />
              </Col>
              <Col sm="12" md="4">
                <FormGroup>
                  <Label>Fattura relativa</Label>
                  <Input
                    type="select"
                    name="select"
                    onChange={this.selezionaFattura}
                    invalid={errori.id_fattura}
                  >
                    <option value={-1} />
                    {fatture
                      .filter((f) => f.incassata === true)
                      .sort((a, b) => {
                        if (
                          moment(a.data_emissione).isSameOrBefore(
                            moment(b.data_emissione)
                          )
                        ) {
                          return 1;
                        } else {
                          return -1;
                        }
                      })
                      .map((fattura) => {
                        const data = moment(fattura.data_emissione);
                        const stringaData = data.format("DD/MM/YY");
                        return (
                          <option
                            value={fattura.id}
                          >{`Nr: ${fattura.nr_fattura} -- Data: ${stringaData} -- Cliente: ${fattura.cliente}`}</option>
                        );
                      })}
                  </Input>
                  <FormFeedback>{errori.messaggio_id_fattura}</FormFeedback>
                </FormGroup>
              </Col>
              <Col sm="12" md="4">
                <FormGroup>
                  <Label>Imponibile</Label>
                  <Input
                    type="number"
                    name="imponibile"
                    value={nota.imponibile}
                    onChange={this.onInput}
                    invalid={errori.imponibile}
                  />
                  <FormFeedback>{errori.messaggio_imponibile}</FormFeedback>
                </FormGroup>
              </Col>
              {user.categoria === "prof 2" || user.categoria === "prof 1" ? (
                <Col sm="12" md="4">
                  <FormGroup>
                    <Label>Rivalsa</Label>
                    <Input type="number" name="rivalsa" value={nota.rivalsa} />
                  </FormGroup>
                </Col>
              ) : (
                <></>
              )}
              {nota.imponibile >= 77.47 ? (
                <Col sm="12" md="4">
                  <FormGroup>
                    <Label>Codice marca da bollo</Label>
                    <Input
                      type="number"
                      name="codice_marca_da_bollo"
                      value={nota.codice_marca_da_bollo}
                      onChange={this.onInput}
                      invalid={errori.codice_marca_da_bollo}
                    />
                    <FormFeedback>
                      {errori.messaggio_codice_marca_da_bollo}
                    </FormFeedback>
                  </FormGroup>
                </Col>
              ) : (
                <></>
              )}
            </Row>
            <Row className="mt-3">
              <CardSubtitle className="text-center my-4 w-100">
                Descrizione
              </CardSubtitle>
              <Col sm="12" md="12">
                <FormGroup>
                  <Input
                    type="textarea"
                    name="descrizione"
                    style={{ minHeight: 200 }}
                    value={nota.descrizione}
                    onChange={this.onInput}
                    invalid={errori.descrizione}
                  />
                  <FormFeedback>{errori.messaggio_descrizione}</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row
              className="justify-content-center"
              style={{ maxWidth: 500, margin: "auto" }}
            >
              <Col md="6" xs="6">
                <Button
                  color="info"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "2em",
                  }}
                  onClick={async () => {
                    const fattura = fatture.find((f) => {
                      return f.id === parseInt(nota.id_fattura);
                    });
                    var cliente = {};
                    if (fattura !== undefined) {
                      cliente = this.props.clienti.clienti.find((cliente) => {
                        return cliente.denominazione === fattura.cliente;
                      });
                    }
                    const blob = await pdf(
                      <PdfNotaDiCredito
                        height={750}
                        nota={nota}
                        user={user}
                        cliente={cliente}
                      />
                    ).toBlob();
                    const blobUrl = URL.createObjectURL(blob);
                    MySwal.fire({
                      title: "Anteprima nota di credito",
                      width: 800,
                      showCloseButton: true,
                      showConfirmButton: false,
                      html: renderToString(
                        <iframe
                          src={blobUrl}
                          type="application/pdf"
                          width="100%"
                          height={window.screen.availHeight * 0.75}
                        />
                      ),
                    });
                  }}
                >
                  Anteprima pdf
                </Button>
              </Col>
              <Col md="6" xs="6">
                <Button
                  type="submit"
                  color="info"
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    display: "block",
                    marginTop: "2em",
                  }}
                >
                  Salva nota di credito
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clienti: state.clienti,
  fatture: state.fatture,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_clienti,
  get_fatture,
  get_note_di_credito,
  genera_nota_di_credito,
})(GeneraNotaDiCredito);
