import Dashboard from "../views/Dashboard/Dashboard.jsx";
import Tasse from "../views/Tasse/Tasse.jsx";
import Fatture from "../views/Fatture/Fatture.jsx";
import Contributi from "../views/Contributi/Contributi";
import Spese from "../views/Spese/Spese.js";
import Clienti from "../views/Clienti/Clienti.js";
import NoteDiCredito from "../views/NoteDiCredito/NoteDiCredito.js";

var dashRoutes = [
  {
    path: "/dashboard/home",
    name: "Home",
    icon: "fas fa-tachometer-alt",
    component: Dashboard
  },
  {
    path: "/dashboard/clienti",
    name: "Clienti",
    icon: "fas fa-users",
    component: Clienti
  },
  {
    path: "/dashboard/fatture",
    name: "Gestione fatture",
    icon: "fas fa-receipt",
    component: Fatture
  },
  {
    path: "/dashboard/note_di_credito",
    name: "Gestione note di credito",
    icon: "fas fa-receipt",
    component: NoteDiCredito
  },
  {
    path: "/dashboard/contributi",
    name: "Gestione contributi",
    icon: "fas fa-balance-scale",
    component: Contributi
  },
  {
    path: "/dashboard/spese",
    name: "Spese",
    icon: "fas fa-euro-sign",
    component: Spese
  },
  {
    path: "/dashboard/imposte",
    name: "Imposte",
    icon: "fas fa-money-bill",
    component: Tasse
  }
];
export default dashRoutes;
