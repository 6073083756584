import React from "react";
import {
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { connect } from "react-redux";
import { invia_fattura } from "../../../actions/fatture";
import "./style.css";
import MaterialDatePicker from "../../components/Modal/MaterialDatePicker";

class InserisciFatturaIncassata extends React.Component {
  state = {
    fattura: {
      nr_fattura: "",
      imponibile: "",
      rivalsa: "",
      marca_da_bollo: "",
      cliente: "",
      descrizione: "",
      data_incasso: "",
      data_emissione: ""
    },
    errors: {
      nr_fattura: false,
      messaggio_nr_fattura: "",
      imponibile: false,
      messaggio_imponibile: "",
      rivalsa: false,
      messaggio_rivalsa: "",
      marca_da_bollo: false,
      messaggiomarca_da_bollo: "",
      cliente: false,
      messaggio_cliente: "",
      descrizione: false,
      messaggio_descrizione: "",
      incasso: false,
      messaggio_incasso: "",
      emissione: false,
      messaggio_emissione: ""
    }
  };
  onInput = e => {
    let fattura = this.state.fattura;
    fattura[e.target.name] = e.target.value;
    this.setState({ fattura });
  };
  getDataIncasso = data => {
    let fattura = this.state.fattura;
    fattura.data_incasso = data;
    this.setState({ fattura });
  };
  getDataEmissione = data => {
    let fattura = this.state.fattura;
    fattura.data_emissione = data;
    this.setState({ fattura });
  };
  onSubmit = e => {
    e.preventDefault();
    let errorFound = false;
    let errors = {
      nr_fattura: false,
      messaggio_nr_fattura: "",
      imponibile: false,
      messaggio_imponibile: "",
      rivalsa: false,
      messaggio_rivalsa: "",
      marca_da_bollo: false,
      messaggiomarca_da_bollo: "",
      cliente: false,
      messaggio_cliente: "",
      descrizione: false,
      messaggio_descrizione: "",
      incasso: false,
      messaggio_incasso: "",
      emissione: false,
      messaggio_emissione: ""
    };
    const {
      data_emissione,
      data_incasso,
      nr_fattura,
      cliente,
      imponibile,
      rivalsa,
      marca_da_bollo
    } = this.state.fattura;
    if (data_emissione === "") {
      errorFound = true;
      errors.emissione = true;
      errors.messaggio_emissione =
        "Il campo 'Data di emissione' non può essere vuoto";
    }
    if (data_incasso === "") {
      errorFound = true;
      errors.incasso = true;
      errors.messaggio_incasso =
        "Il campo 'Data di incasso' non può essere vuoto";
    }
    if (
      data_incasso !== "" &&
      data_emissione !== "" &&
      data_incasso.getTime() < data_emissione.getTime()
    ) {
      errorFound = true;
      errors.incasso = true;
      errors.messaggio_incasso =
        "La data di emissione deve essere precedente alla data di incasso";
    }
    if (nr_fattura === "") {
      errorFound = true;
      errors.nr_fattura = true;
      errors.messaggio_nr_fattura =
        "Il campo 'Nr. fattura' non può essere vuoto";
    }
    if (cliente === "") {
      errorFound = true;
      errors.cliente = true;
      errors.messaggio_cliente = "Il campo 'Nome cliente' non può essere vuoto";
    }
    if (imponibile === "") {
      errorFound = true;
      errors.imponibile = true;
      errors.messaggio_imponibile =
        "Il campo 'Importo imponibile' non può essere vuoto";
    }
    if (
      rivalsa === "" &&
      (this.props.auth.user.categoria === "prof 1" ||
        this.props.auth.user.categoria === "prof 2")
    ) {
      errorFound = true;
      errors.rivalsa = true;
      errors.messaggio_rivalsa =
        "Il campo 'Importo rivalsa' non può essere vuoto";
    } else if (rivalsa === "") {
      let stato = this.state;
      stato.fattura.rivalsa = 0;
      this.setState({ stato });
    }
    if (marca_da_bollo === "") {
      errorFound = true;
      errors.marca_da_bollo = true;
      errors.messaggiomarca_da_bollo =
        "Il campo 'Importo marca da bollo' non può essere vuoto";
    }
    this.setState({ errors: errors });
    if (errorFound) return;
    this.props.invia_fattura(this.state.fattura);
    this.setState({
      fattura: {
        nr_fattura: "",
        imponibile: "",
        rivalsa: "",
        marca_da_bollo: "",
        cliente: "",
        descrizione: "",
        data_incasso: "",
        data_emissione: ""
      }
    });
  };

  render() {
    const {
      data_emissione,
      data_incasso,
      nr_fattura,
      cliente,
      imponibile,
      rivalsa,
      marca_da_bollo,
      descrizione
    } = this.state.fattura;
    const { errors } = this.state;
    return (
      <React.Fragment>
        <div className="content">
          <div className="__tab-wrapper">
            <CardTitle className="text-center">
              <i
                className="fas fa-receipt text-warning"
                style={{ marginRight: "1em" }}
              />
              Fattura
              <i
                className="fas fa-receipt text-warning"
                style={{ marginLeft: "1em" }}
              />
            </CardTitle>
            <form onSubmit={this.onSubmit}>
              <div className="form-row">
                <div className="col-md-6">
                  <MaterialDatePicker
                    label="Data di emissione"
                    placeholder="Seleziona la data di emissione della fattura..."
                    disableFuture={true}
                    value={data_emissione}
                    onChange={this.getDataEmissione}
                    invalid={errors.emissione}
                    invalidMessage={errors.messaggio_emissione}
                  />
                </div>
                <div className="col-md-6">
                  <MaterialDatePicker
                    label="Data di incasso"
                    placeholder="Seleziona la data di incasso della fattura..."
                    disableFuture={true}
                    value={data_incasso}
                    onChange={this.getDataIncasso}
                    invalid={errors.incasso}
                    invalidMessage={errors.messaggio_incasso}
                  />
                </div>
              </div>
              <FormGroup>
                <Label>Nr. Fattura</Label>
                <Input
                  type="number"
                  name="nr_fattura"
                  placeholder="Inserire il numero della fattura..."
                  value={nr_fattura}
                  onChange={this.onInput}
                  invalid={errors.nr_fattura}
                />
                <FormFeedback>{errors.messaggio_nr_fattura}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label>Nome cliente</Label>
                <Input
                  type="text"
                  name="cliente"
                  placeholder="Inserire il nome del cliente..."
                  value={cliente}
                  onChange={this.onInput}
                  invalid={errors.cliente}
                />
                <FormFeedback>{errors.messaggio_cliente}</FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="Descrizione fattura">Descrizione fattura</Label>
                <Input
                  type="text"
                  name="descrizione"
                  placeholder="Inserisci una descrizione della fattura... (opzionale)"
                  value={descrizione}
                  onChange={this.onInput}
                  invalid={errors.descrizione}
                />
                <FormFeedback>{errors.messaggio_descrizione}</FormFeedback>
              </FormGroup>
              <div className="form-row">
                {this.props.auth.user.categoria === "prof 1" ||
                this.props.auth.user.categoria === "prof 2" ? (
                  <React.Fragment>
                    <FormGroup className="col-md-4">
                      <Label>Importo imponibile (€)</Label>
                      <Input
                        type="number"
                        name="imponibile"
                        placeholder="Inserisci l'imponibile...."
                        value={imponibile}
                        onChange={this.onInput}
                        invalid={errors.imponibile}
                      />
                      <FormFeedback>{errors.messaggio_imponibile}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label>Importo rivalsa (€)</Label>
                      <Input
                        type="number"
                        name="rivalsa"
                        placeholder="Inserisci l'importo della rivalsa...."
                        value={rivalsa}
                        onChange={this.onInput}
                        invalid={errors.rivalsa}
                      />
                      <FormFeedback>{errors.messaggio_rivalsa}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label>Importo marca da bollo (€)</Label>
                      <Input
                        type="number"
                        name="marca_da_bollo"
                        placeholder="Inserisci l'importo della marca da bollo...."
                        value={marca_da_bollo}
                        onChange={this.onInput}
                        invalid={errors.marca_da_bollo}
                      />
                      <FormFeedback>
                        {errors.messaggiomarca_da_bollo}
                      </FormFeedback>
                    </FormGroup>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <FormGroup className="col-md-6">
                      <Label>Importo imponibile (€)</Label>
                      <Input
                        type="number"
                        name="imponibile"
                        placeholder="Inserisci l'imponibile...."
                        value={imponibile}
                        onChange={this.onInput}
                        invalid={errors.imponibile}
                      />
                      <FormFeedback>{errors.messaggio_imponibile}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label>Importo marca da bollo (€)</Label>
                      <Input
                        type="number"
                        name="marca_da_bollo"
                        placeholder="Inserisci l'importo della marca da bollo...."
                        value={marca_da_bollo}
                        onChange={this.onInput}
                        invalid={errors.marca_da_bollo}
                      />
                      <FormFeedback>
                        {errors.messaggiomarca_da_bollo}
                      </FormFeedback>
                    </FormGroup>
                  </React.Fragment>
                )}
              </div>
              <Button
                type="submit"
                color="info"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginTop: "2em"
                }}
              >
                Inserisci fattura
              </Button>
            </form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { invia_fattura }
)(InserisciFatturaIncassata);
