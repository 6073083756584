import React from "react";

import { connect } from "react-redux";
import { elimina_spese } from "../../../actions/spese";

import ModalDelete from "../../components/Modal/ModalDelete";
import ModalEdit from "../../components/Modal/ModalEdit";

import InserisciSpesa from "./InserisciSpesa";
import TabellaSpese from "./TabellaSpese";

class Spese extends React.Component {
  state = {
    modal: "",
    id: ""
  };

  render() {
    return (
      <div className="content">
        <div className="__tab-wrapper">
          <input
            id="tab1"
            className="__tab-input"
            type="radio"
            name="tabs"
            defaultChecked
          />
          <label className="__tab-label" htmlFor="tab1">
            Inserisci Spesa
          </label>

          <input id="tab2" className="__tab-input" type="radio" name="tabs" />
          <label className="__tab-label" htmlFor="tab2">
            Lista Spese
          </label>

          <section id="content1" className="__tab-section">
            <InserisciSpesa />
          </section>

          <section id="content2" className="__tab-section">
            {this.state.modalOpen && this.state.modal === "delete fattura" ? (
              <ModalDelete
                header="Sei sicuro di voler eliminare la spesa?"
                unmount={this.handleModalCloseDelete}
              />
            ) : this.state.modalOpen &&
              this.state.modal === "delete contributi" ? (
              <ModalDelete
                header="Sei sicuro di voler eliminare i spesa?"
                unmount={this.handleModalCloseDelete}
              />
            ) : this.state.modalOpen ? (
              <ModalEdit
                unmount={this.handleModalCloseDelete}
                fattura={this.props.fatture.fatture.filter(fattura => {
                  return fattura.id === this.state.id;
                })}
              />
            ) : (
              ""
            )}
            <TabellaSpese delete={this.props.elimina_spese} />
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.auth.user
});

export default connect(
  mapStateToProps,
  { elimina_spese }
)(Spese);
