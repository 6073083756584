import React from "react";
import { renderToString } from "react-dom/server";
import * as L from "leaflet";
import "leaflet-providers";
import "./style.css";

export default function Sedi() {
  const [width, setWidth] = React.useState(window.innerWidth - 17);

  window.addEventListener("resize", (e) => {
    setWidth(e.target.innerWidth - 17);
    console.log();
  });

  React.useEffect(() => {
    const map = L.map("map", {
      center: [50, -30],
      zoom: 4,
      minZoom: 4,
      maxZoom: 4,
      preferCanvas: true,
      zoomControl: false,
      dragging: false,
      doubleClickZoom: false,
      renderer: L.Canvas,
    });
    L.tileLayer.provider("CartoDB.DarkMatter", { tms: false }).addTo(map);
    const p1 = L.popup({ closeButton: false }).setContent(
      renderToString(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
            padding: 8,
          }}
        >
          <div style={{ marginBottom: 10, fontWeight: "bold" }}>
            Torino (Italy)
          </div>
          <div>Via Bossolasco n.11, 10141 - piano 1</div>
        </div>
      )
    );

    const p2 = L.popup({ closeButton: false }).setContent(
      renderToString(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
            padding: 8,
          }}
        >
          <div style={{ marginBottom: 10, fontWeight: "bold" }}>
            New York (U.S.A.)
          </div>
          <div>488 Madison Avenue, 10022, 21esimo piano</div>
        </div>
      )
    );

    const p3 = L.popup({ closeButton: false }).setContent(
      renderToString(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            fontSize: 14,
            padding: 8,
          }}
        >
          <div style={{ marginBottom: 10, fontWeight: "bold" }}>
            Lugano (SVIZZERA)
          </div>
          <div>Via Carlo Frasca 8</div>
        </div>
      )
    );

    L.marker([45.1, 7.7]).addTo(map).bindPopup(p1);
    L.marker([40.73, -73.93]).addTo(map).bindPopup(p2);
    L.marker([46.0064805, 8.9524243]).addTo(map).bindPopup(p3);
  }, []);

  return (
    <div
      id="map"
      style={{
        height: "100vh",
        width: width,
      }}
    />
  );
}
