import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import React from "react";
import { Label, Input, FormGroup, Form, FormText } from "reactstrap";
import { renderToString } from "react-dom/server";
const MySwal = withReactContent(Swal);

export function showModalSuccess(message = "") {
  MySwal.fire({
    type: "success",
    text: message,
    confirmButtonColor: "#5cb85c",
    showCloseButton: true,
  });
}

export function showModalError(message = "") {
  MySwal.fire({
    type: "error",
    text: message,
    confirmButtonColor: "#d9534f",
    showCloseButton: true,
  });
}

export function showModalContributi() {
  MySwal.fire({
    type: "question",
    text: "Hai pagato dei contributi questo mese?",
    showCloseButton: true,
    showCancelButton: true,
    confirmButtonColor: "#5cb85c",
    cancelButtonColor: "#d9534f",
    focusConfirm: false,
    confirmButtonText: "Si",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.value) {
      window.location = "/dashboard/contributi";
    }
  });
}

export function showModalAggiungiFatture(history) {
  MySwal.fire({
    type: "question",
    confirmButtonColor: "#5cb85c",
    showCloseButton: true,
    showConfirmButton: false,
    html:
      '<br/><button id="nuova" class="btn btn-success w-100">' +
      "Genera nuova fattura" +
      "</button><br/><br/>" +
      '<button id="incassata" class="btn btn-info w-100">' +
      "Inserisci fattura già incassata" +
      "</button><br/>",
    onBeforeOpen: () => {
      const content = Swal.getContent();
      const $ = content.querySelector.bind(content);

      const nuova = $("#nuova");
      const incassata = $("#incassata");

      nuova.addEventListener("click", () => {
        MySwal.close();
        history.push("/dashboard/fatture/genera");
      });

      incassata.addEventListener("click", () => {
        MySwal.close();
        history.push("/dashboard/fatture/aggiungi");
      });
    },
  });
}

export function showModalConferma(message = "", funzione, id) {
  MySwal.fire({
    type: "warning",
    text: message,
    showCloseButton: true,
    showCancelButton: true,
    cancelButtonColor: "#5cb85c",
    confirmButtonColor: "#d9534f",
    focusConfirm: false,
    confirmButtonText: "Si",
    cancelButtonText: "No",
  }).then((result) => {
    if (result.value) {
      funzione(id);
    }
  });
}

export function showModalReminderFatture(funzione) {
  MySwal.fire({
    type: "warning",
    text: "Ricordati di controllare e segnare le fatture incassate!",
    showCloseButton: true,
    showCancelButton: false,
    confirmButtonColor: "#5cb85c",
    focusConfirm: false,
    confirmButtonText: "Ok",
  });
}

export function showModalInviaFattura(pdf, funzioneInviaEmail, mittente) {
  MySwal.fire({
    titleText: "Vuoi inviare la fattura al tuo cliente?",
    showCloseButton: true,
    showCancelButton: true,
    cancelButtonColor: "rgb(217, 83, 79)",
    confirmButtonColor: "info",
    focusConfirm: false,
    confirmButtonText: "Invia fattura",
    cancelButtonText: "Annulla",
    focusConfirm: false,
    width: 800,
    html: renderToString(
      <Form id="email-form">
        <FormGroup className="mt-4">
          <FormGroup className="mt-4">
            <Label className="float-left">Mittente</Label>
            <Input
              id="mittente"
              type="email"
              name="mittente"
              placeholder="Digita l'indirizzo email da cui vuoi inviare l'email"
              value={mittente}
              disabled
            />
          </FormGroup>
          <Label className="float-left">Destinatario</Label>
          <Input
            id="destinatario"
            type="email"
            name="destinatario"
            placeholder="Digita l'indirizzo email del cliente a cui si vuole inviare la fattura..."
            required
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Label className="float-left">Oggetto</Label>
          <Input
            id="oggetto"
            type="text"
            name="oggetto"
            placeholder="Digita l'oggetto dell'email"
            required
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Label className="float-left">Messaggio</Label>
          <Input
            id="messaggio"
            type="textarea"
            style={{ minHeight: 200 }}
            name="messaggio"
            placeholder="Digita il messaggio dell'email"
            required
          />
          <FormText color="muted text-left">
            *La fattura verrà allegata in automatico in formato PDF
          </FormText>
        </FormGroup>
      </Form>
    ),

    preConfirm: async () => {
      let mittente = document.getElementById("mittente");
      let destinatario = document.getElementById("destinatario");
      let oggetto = document.getElementById("oggetto");
      let messaggio = document.getElementById("messaggio");

      if (!mittente.validity.valid) {
        window.alert("Email mittente non valida");
        return false;
      }

      if (!destinatario.validity.valid) {
        window.alert("Email destinatario non valida");
        return false;
      }

      if (!oggetto.validity.valid) {
        window.alert("Si prega di inserire l'oggetto dell'email");
        return false;
      }

      if (!messaggio.validity.valid) {
        window.alert("Si prega di inserire un messaggio");
        return false;
      }

      if (!String(pdf).startsWith("http")) {
        let email = {
          mittente: mittente.value,
          destinatario: destinatario.value,
          oggetto: oggetto.value,
          messaggio: messaggio.value,
          pdf,
        };
        funzioneInviaEmail(email);
      } else {
        let blob = await fetch(pdf).then((r) => r.blob());
        var reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = await function (e) {
          let email = {
            mittente: mittente.value,
            destinatario: destinatario.value,
            oggetto: oggetto.value,
            messaggio: messaggio.value,
            pdf: reader.result,
          };

          funzioneInviaEmail(email);
        }.bind(this);
      }
    },
  });
}

export function showModalInviaNotaDiCredito(pdf, funzioneInviaEmail, mittente) {
  MySwal.fire({
    titleText: "Vuoi inviare la nota di credito al tuo cliente?",
    showCloseButton: true,
    showCancelButton: true,
    cancelButtonColor: "rgb(217, 83, 79)",
    confirmButtonColor: "info",
    focusConfirm: false,
    confirmButtonText: "Invia nota di credito",
    cancelButtonText: "Annulla",
    focusConfirm: false,
    width: 800,
    html: renderToString(
      <Form id="email-form">
        <FormGroup className="mt-4">
          <FormGroup className="mt-4">
            <Label className="float-left">Mittente</Label>
            <Input
              id="mittente"
              type="email"
              name="mittente"
              placeholder="Digita l'indirizzo email da cui vuoi inviare l'email"
              value={mittente}
              disabled
            />
          </FormGroup>
          <Label className="float-left">Destinatario</Label>
          <Input
            id="destinatario"
            type="email"
            name="destinatario"
            placeholder="Digita l'indirizzo email del cliente a cui si vuole inviare la fattura..."
            required
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Label className="float-left">Oggetto</Label>
          <Input
            id="oggetto"
            type="text"
            name="oggetto"
            placeholder="Digita l'oggetto dell'email"
            required
          />
        </FormGroup>
        <FormGroup className="mt-4">
          <Label className="float-left">Messaggio</Label>
          <Input
            id="messaggio"
            type="textarea"
            style={{ minHeight: 200 }}
            name="messaggio"
            placeholder="Digita il messaggio dell'email"
            required
          />
          <FormText color="muted text-left">
            *La nota di credito verrà allegata in automatico in formato PDF
          </FormText>
        </FormGroup>
      </Form>
    ),

    preConfirm: async () => {
      let mittente = document.getElementById("mittente");
      let destinatario = document.getElementById("destinatario");
      let oggetto = document.getElementById("oggetto");
      let messaggio = document.getElementById("messaggio");

      if (!mittente.validity.valid) {
        window.alert("Email mittente non valida");
        return false;
      }

      if (!destinatario.validity.valid) {
        window.alert("Email destinatario non valida");
        return false;
      }

      if (!oggetto.validity.valid) {
        window.alert("Si prega di inserire l'oggetto dell'email");
        return false;
      }

      if (!messaggio.validity.valid) {
        window.alert("Si prega di inserire un messaggio");
        return false;
      }

      if (!String(pdf).startsWith("http")) {
        let email = {
          mittente: mittente.value,
          destinatario: destinatario.value,
          oggetto: oggetto.value,
          messaggio: messaggio.value,
          pdf,
        };
        funzioneInviaEmail(email);
      } else {
        let blob = await fetch(pdf).then((r) => r.blob());
        var reader = new FileReader();
        reader.readAsDataURL(blob);

        reader.onloadend = await function (e) {
          let email = {
            mittente: mittente.value,
            destinatario: destinatario.value,
            oggetto: oggetto.value,
            messaggio: messaggio.value,
            pdf: reader.result,
          };

          funzioneInviaEmail(email);
        }.bind(this);
      }
    },
  });
}
