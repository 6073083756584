import React, { Component } from "react";

import Header from "../../Layout/Header";

import PrimaSezione from "./PrimaSezione.js";

import image from "../../../assets/servizi/header.jpg";

class Servizi extends Component {
    componentDidMount() {
        document.title = "SEVAT | Servizi";
    }

    render() {
        return (
            <React.Fragment>
                <Header
                    image={image}
                    header="La nostra professionalità al tuo servizio"
                />
                <PrimaSezione />
            </React.Fragment>
        );
    }
}
export default Servizi;
