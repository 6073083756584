import React from "react";
import {
  CardTitle,
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
} from "reactstrap";
import { connect } from "react-redux";
import { inserisci_spese } from "../../../actions/spese";
import SelezionaCategoria from "./SelezionaCategoria";
import MaterialDatePicker from "../../components/Modal/MaterialDatePicker";

const lista_descrizioni = [
  ["Spese di vitto e alloggio", "Spese di rappresentanza", "Omaggi", "Altro"],
  [
    "Energia Elettrica",
    "Altre utenze",
    "Spese per pulizie locali",
    "Canoni locazione immobili",
    "Spese condominiali e varie",
    "Altro"
  ],
  [
    "Consulenze da professionisti",
    "Altre prestazioni di lavoro autonomo afferenti all'attività",
    "Altro"
  ],
  [
    "Consulenza e altri sercizi afferenti all'attività (da società)",
    "Pubblicità, inserzioni e affisioni",
    "Altre spese amministrative",
    "Licenze d'uso e assistenza software",
    "Spese generali varie",
    "Spese per dipendenti",
    "Canoni noleggio impianti, macchinari e attrezzature",
    "Energia elettrica per impianti, macchinari e attrezzature",
    "Abbonamenti, libri e pubblicazioni",
    "Trasporti aerei e ferroviari",
    "Acquisti beni materiali per la produzione di beni e serivizi",
    "Acquisti di beni strumentali",
    "Cancelleria",
    "Computer, stampanti, fax",
    "Omaggi",
    "Fiere, mostre e convegni",
    "Altro"
  ],
  ["Spese di rappresentanza", "Omaggi", "Altro"],
  ["Collaboratori", "Altro"],
  [
    "Canoni di abbonamento telefonici",
    "Ricariche telefoniche",
    "Acquisto di telefoni",
    "Altro"
  ],
  [
    "Carburante",
    "Premi di assicurazione",
    "Pedaggi autostradali",
    "Spese di manutenzione",
    "Altro"
  ],
  []
];

class InserisciSpesa extends React.Component {
  state = {
    fattura: {
      importo: "",
      categoria: "Seleziona la categoria...",
      codice_categoria: "",
      descrizione: "",
      descrizione_inserita: "",
      data: ""
    },
    errors: {
      messaggio_nr_fattura: "",
      importo: false,
      messaggio_importo: "",
      descrizione: false,
      messaggio_descrizione: "",
      categoria: false,
      messaggio_categoria: "",
      descrizione_inserita: false,
      messaggio_descrizione_inserita: ""
    },
    mostraSelezione: false
  };
  onInput = e => {
    let fattura = this.state.fattura;
    fattura[e.target.name] = e.target.value;
    this.setState({ fattura });
  };
  getData = data => {
    let fattura = this.state.fattura;
    fattura.data = data;
    this.setState({ fattura });
  };

  onSubmit = e => {
    e.preventDefault();
    let errorFound = false;
    let errors = {
      importo: false,
      messaggio_importo: "",
      descrizione: false,
      messaggio_descrizione: "",
      categoria: false,
      messaggio_categoria: "",
      descrizione_inserita: false,
      messaggio_descrizione_inserita: ""
    };
    const {
      data,
      importo,
      categoria,
      descrizione,
      descrizione_inserita
    } = this.state.fattura;
    if (data === "") {
      errorFound = true;
      errors.emissione = true;
      errors.messaggio_emissione = "Il campo 'Data' non può essere vuoto";
    }

    if (importo === "") {
      errorFound = true;
      errors.importo = true;
      errors.messaggio_importo =
        "Il campo 'Importo totale' non può essere vuoto";
    }

    if (categoria === "Seleziona la categoria...") {
      errorFound = true;
      errors.categoria = true;
      errors.messaggio_categoria = "Il campo 'Categoria' non può essere vuoto";
    }

    if (
      descrizione === "" &&
      categoria !== "Seleziona la categoria..." &&
      categoria !== "Altro"
    ) {
      errorFound = true;
      errors.descrizione = true;
      errors.messaggio_descrizione =
        "Il campo 'Descrizione' deve essere selezionato";
    }

    if (
      (descrizione === "Altro" || descrizione === "") &&
      (categoria !== "Seleziona la categoria..." || categoria === "Altro") &&
      descrizione_inserita === ""
    ) {
      errorFound = true;
      errors.descrizione_inserita = true;
      errors.messaggio_descrizione_inserita =
        "Il campo 'Inserisci descrizione' non può essere vuoto";
    }

    this.setState({ errors: errors });

    if (errorFound) return;

    this.props.inserisci_spese(this.state.fattura);

    this.setState({
      fattura: {
        importo: "",
        categoria: "Seleziona la categoria...",
        codice_categoria: "",
        descrizione: "",
        descrizione_inserita: "",
        data: ""
      }
    });
  };

  selezionaCategoria = (categoria, codice) => {
    var fattura = this.state.fattura;
    fattura.categoria = categoria;
    fattura.codice_categoria = codice;
    this.setState({ ...this.state, mostraSelezione: false, fattura });
  };

  descrizioneChange = e => {
    var fattura = this.state.fattura;
    fattura.descrizione = e.target.value;
    this.setState({ fattura });
  };

  render() {
    const {
      data,
      importo,
      descrizione,
      descrizione_inserita
    } = this.state.fattura;
    const { errors } = this.state;
    return (
      <React.Fragment>
        {this.state.mostraSelezione ? (
          <SelezionaCategoria seleziona={this.selezionaCategoria} />
        ) : (
          ""
        )}
        <CardTitle className="text-center">
          <i
            className="fas fa-receipt text-warning"
            style={{ marginRight: "1em" }}
          />
          Spesa
          <i
            className="fas fa-receipt text-warning"
            style={{ marginLeft: "1em" }}
          />
        </CardTitle>
        <form onSubmit={this.onSubmit}>
          <MaterialDatePicker
            disableFuture={true}
            label="Data"
            placeholder="Seleziona la data..."
            value={data}
            onChange={this.getData}
            invalid={errors.emissione}
            invalidMessage={errors.messaggio_emissione}
          />

          <div className="form-group">
            <label htmlFor="categoria">Categoria</label>
            <div
              className="form-control"
              value={this.state.fattura.categoria}
              invalid={this.state.errors.categoria}
              onClick={() => this.setState({ mostraSelezione: true })}
              onChange={() => {}}
            >
              {this.state.fattura.categoria}
            </div>
            <div
              style={{
                color: "#d9534f",
                fontSize: "0.8571em",
                marginTop: "3px"
              }}
            >
              {errors.messaggio_categoria}
            </div>
          </div>

          {this.state.fattura.categoria !== "Seleziona la categoria..." &&
          this.state.fattura.categoria !== "Altro" ? (
            <div className="form-row mx-0 mb-3">
              <label className="my-1" htmlFor="inputGroupSelect03">
                Descrizione
              </label>
              <Input
                type="select"
                className="custom-select"
                id="inputGroupSelect03"
                onChange={this.descrizioneChange}
                invalid={this.state.errors.descrizione}
              >
                <option defaultValue value="">
                  Scegli la descrizione...
                </option>
                {lista_descrizioni[this.state.fattura.codice_categoria].map(
                  (descrizione, indice) => {
                    return <option key={indice}>{descrizione}</option>;
                  }
                )}
              </Input>
              <FormFeedback>{errors.messaggio_descrizione}</FormFeedback>
            </div>
          ) : (
            ""
          )}

          {descrizione === "Altro" ||
          this.state.fattura.categoria === "Altro" ? (
            <FormGroup>
              <Label for="Descrizione fattura">Inserisci descrizione</Label>
              <Input
                type="text"
                name="descrizione_inserita"
                placeholder="Inserisci una descrizione della spesa..."
                value={descrizione_inserita}
                onChange={this.onInput}
                invalid={errors.descrizione_inserita}
              />
              <FormFeedback>
                {errors.messaggio_descrizione_inserita}
              </FormFeedback>
            </FormGroup>
          ) : (
            ""
          )}

          <div className="form-row">
            <FormGroup className="col-md-12">
              <Label>Importo totale (compreso IVA) (€)</Label>
              <Input
                type="number"
                name="importo"
                placeholder="Inserisci l'importo...."
                value={importo}
                onChange={this.onInput}
                invalid={errors.importo}
              />
              <FormFeedback>{errors.messaggio_importo}</FormFeedback>
            </FormGroup>
          </div>
          <Button
            type="submit"
            color="info"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
              marginTop: "2em"
            }}
          >
            Inserisci spesa
          </Button>
        </form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { inserisci_spese }
)(InserisciSpesa);
