import React, { Component } from "react";

import { Card, CardHeader, CardBody, Collapse } from "reactstrap";

import MaterialTable from "material-table";

import Modal from "../../components/Modal/Modal";

import {
  calcolo_cumulativo,
  calcolo_tasse_prof_1,
  calcolo_tasse_prof_2,
  calcolo_tasse_art,
  calcolo_tasse_comm,
} from "../../calcoloTasse/calcoloTasse";

import "./style.css";

class AccordionItem extends Component {
  state = {
    modalOpen: false,
    modal: "",
  };

  colonne = [
    {
      title: "Descrizione",
      field: "descrizione",
      cellStyle: (data) => {
        if (
          data === "Imposte da versare" ||
          data === "Imposte e Contributi da versare" ||
          data === "Contributi da versare"
        ) {
          return {
            fontSize: 15,
            fontWeight: "bold",
            cursor: "auto",
          };
        }
        return { fontSize: 14 };
      },
    },
    {
      title: "Importo",
      field: "importo",
      type: "numeric",
      cellStyle: { fontSize: 14, cursor: "auto" },
    },
  ];

  options = {
    toolbar: false,
    paging: false,
    sorting: false,
    rowStyle: (data) => {
      if (
        data.descrizione === "Imposte da versare" ||
        data.descrizione === "Imposte e Contributi da versare"
      ) {
        return {
          cursor: "auto",
        };
      }
    },
  };

  modalToggle = () => {
    const modalOpen = !this.state.modalOpen;
    this.setState({ modalOpen });
  };

  render() {
    var data = [];

    const { categoria } = this.props.user;

    if (categoria === "prof 1") {
      data = calcolo_tasse_prof_1(this.props.data, this.props.user);
    } else if (categoria === "prof 2") {
      data = calcolo_tasse_prof_2(this.props.data, this.props.user);
    } else if (categoria === "art") {
      data = calcolo_tasse_art(
        calcolo_cumulativo(this.props.data.mesi),
        this.props.user
      );
    } else if (categoria === "comm") {
      data = calcolo_tasse_comm(
        calcolo_cumulativo(this.props.data.mesi),
        this.props.user
      );
    }

    return (
      <React.Fragment>
        {this.state.modalOpen ? (
          <Modal toggle={this.modalToggle} title={this.state.modal}>
            {this.state.modal === "Dettagli imposta sui ricavi" ||
            this.state.modal === "Dettagli imposta 1" ? (
              <MaterialTable
                columns={this.colonne}
                data={data[0]}
                title="Basic"
                options={this.options}
              />
            ) : (
              <MaterialTable
                columns={this.colonne}
                data={data[1]}
                title="Basic"
                options={this.options}
              />
            )}
          </Modal>
        ) : (
          ""
        )}
        <Card className="card-plain" style={{ fontSize: 30 }}>
          <CardHeader
            role="tab"
            data-parent="#accordion"
            data-toggle="collapse"
            onClick={(e) => {
              e.preventDefault();
              this.props.toggle(this.props.id);
            }}
            style={{ padding: "0px 15px", cursor: "pointer" }}
          >
            <Card
              className="bg-info text-white px-3"
              style={{ userSelect: "none" }}
            >
              <CardBody style={{ fontSize: 16 }}>
                {this.props.data.anno === new Date().getFullYear()
                  ? `Imposte ${this.props.data.anno} da versare nel ${
                      this.props.data.anno + 1
                    }`
                  : `Imposte ${this.props.data.anno} versate nel ${
                      this.props.data.anno + 1
                    }`}
                <i
                  className="nc-icon nc-minimal-down"
                  style={{ float: "right" }}
                />
              </CardBody>
            </Card>
          </CardHeader>
          <Collapse role="tabpanel" isOpen={this.props.opened}>
            <CardBody>
              <div className="container" style={{ maxWidth: 600 }}>
                {categoria === "prof 1" ? (
                  <MaterialTable
                    columns={this.colonne}
                    data={data}
                    title="Basic"
                    options={this.options}
                  />
                ) : categoria === "prof 2" ? (
                  <MaterialTable
                    columns={this.colonne}
                    data={data[2]}
                    title="Basic"
                    options={this.options}
                    onRowClick={(event, rowData) => {
                      if (rowData.tableData.id === 0) {
                        this.setState({
                          modalOpen: true,
                          modal: "Dettagli imposta sui ricavi",
                        });
                      } else if (rowData.tableData.id === 1) {
                        this.setState({
                          modalOpen: true,
                          modal: "Dettagli contributi INPS",
                        });
                      }
                    }}
                  />
                ) : (categoria === "comm" || categoria === "art") &&
                  data.length === 3 ? (
                  <MaterialTable
                    columns={this.colonne}
                    data={data[2]}
                    title="Basic"
                    options={this.options}
                    onRowClick={(event, rowData) => {
                      if (rowData.tableData.id === 0) {
                        this.setState({
                          modalOpen: true,
                          modal: "Dettagli imposta 1",
                        });
                      } else if (rowData.tableData.id === 1) {
                        this.setState({
                          modalOpen: true,
                          modal: "Dettagli imposta 2",
                        });
                      }
                    }}
                  />
                ) : categoria === "comm" || categoria === "art" ? (
                  <MaterialTable
                    columns={this.colonne}
                    data={data}
                    title="Basic"
                    options={this.options}
                  />
                ) : (
                  " "
                )}
              </div>
            </CardBody>
          </Collapse>
        </Card>
      </React.Fragment>
    );
  }
}
export default AccordionItem;
