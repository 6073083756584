import axios from "axios";

// const URL = "http://192.168.10.51";
const URL = "https://sevatsrl.it/";

const axiosInstance = axios.create({
  baseURL: URL,
});

export { URL, axiosInstance };
