import React, { Component } from "react";

import { connect } from "react-redux";

import { inserisci_news } from "../../../actions/news";

class InserisciNews extends Component {
  state = {
    titolo: "",
    testo: ""
  };
  onChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };

  onSubmit = e => {
    e.preventDefault();
    this.props.inserisci_news(this.state.titolo, this.state.testo);
    this.setState({
      titolo: "",
      testo: ""
    });
  };

  render() {
    const { titolo, testo } = this.state;
    return (
      <div
        className="news-form"
        style={{
          backgroundColor: "rgba(62, 69, 81, 0.1)",
          width: "80%"
        }}
      >
        <div className="ui header medium centered">Inserisci</div>
        <form className="ui form" onSubmit={this.onSubmit}>
          <div className={"field"}>
            <label>Titolo</label>
            <input
              type="text"
              name="titolo"
              value={titolo}
              placeholder="Inserisci titolo"
              onChange={this.onChange}
            />
          </div>
          <div className={"field"}>
            <label>Testo</label>
            <textarea
              rows="20"
              name="testo"
              value={testo}
              placeholder="Inserisci qui il testo..."
              onChange={this.onChange}
            />
          </div>

          <button
            className="ui button"
            type="submit"
            style={{
              margin: "auto",
              display: "block",
              backgroundColor: "#0E6EB8",
              color: "white"
            }}
          >
            Inserisci
          </button>
        </form>
      </div>
    );
  }
}
export default connect(
  null,
  { inserisci_news }
)(InserisciNews);
