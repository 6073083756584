import React from "react";
import Fade from "../../Layout/Fade";

export default function Sezione(props) {
    return (
        <Fade>
            <div className="ui container">
                <div
                    className="card"
                    style={{ padding: 25, marginTop: 25, marginBottom: 25 }}
                >
                    <div className="header h2 text-center">{props.titolo}</div>
                    <div className="content" style={{ whiteSpace: "pre-line" }}>
                        {props.messaggio}
                    </div>

                    <div style={{ marginTop: "1rem", textAlign: "right" }}>
                        {props.data}
                    </div>
                </div>
            </div>
        </Fade>
    );
}
