import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { Link } from "react-router-dom";
import { logout, updateAnnoSelezionato } from "../../../actions/auth";
import { connect } from "react-redux";
import dashboardRoutes from "../../routes/dashboard.jsx";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      color: "transparent",
      annoOpen: false
    };
    this.toggle = this.toggle.bind(this);
    this.dropdownToggle = this.dropdownToggle.bind(this);
  }
  toggle() {
    if (this.state.isOpen) {
      this.setState({
        color: "transparent"
      });
    } else {
      this.setState({
        color: "dark"
      });
    }
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  dropdownToggle(e) {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }
  getBrand() {
    var name;
    dashboardRoutes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      } else {
        if (prop.redirect) {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        } else {
          if (prop.path === this.props.location.pathname) {
            name = prop.name;
          }
        }
      }
      return null;
    });
    return name;
  }
  openSidebar() {
    document.documentElement.classList.toggle("nav-open");
    this.refs.sidebarToggle.classList.toggle("toggled");
  }
  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  updateColor() {
    if (window.innerWidth < 993 && this.state.isOpen) {
      this.setState({
        color: "dark"
      });
    } else {
      this.setState({
        color: "transparent"
      });
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateColor.bind(this));
  }
  componentDidUpdate(e) {
    if (
      window.innerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      this.refs.sidebarToggle.classList.toggle("toggled");
    }
  }
  render() {
    return (
      // add or remove classes depending if we are on full-screen-maps page or not
      <Navbar
        color={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "dark"
            : this.state.color
        }
        expand="lg"
        className={
          this.props.location.pathname.indexOf("full-screen-maps") !== -1
            ? "navbar-absolute fixed-top"
            : "navbar-absolute fixed-top " +
              (this.state.color === "transparent" ? "navbar-transparent " : "")
        }
      >
        <Container fluid>
          <div className="navbar-wrapper">
            <div className="navbar-toggle">
              <button
                type="button"
                ref="sidebarToggle"
                className="navbar-toggler"
                onClick={() => this.openSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <NavbarBrand style={{ userSelect: "none" }}>
              {this.getBrand()}
            </NavbarBrand>
          </div>
          <NavbarToggler onClick={this.toggle}>
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
            <span className="navbar-toggler-bar navbar-kebab" />
          </NavbarToggler>
          <Collapse
            isOpen={this.state.isOpen}
            navbar
            className="justify-content-end"
          >
            <Nav navbar>
              <NavItem>
                <Dropdown
                  isOpen={this.state.annoOpen}
                  toggle={() => {
                    this.setState({
                      annoOpen: !this.state.annoOpen
                    });
                  }}
                >
                  <DropdownToggle caret>
                    {this.props.annoSelezionato}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() =>
                        this.props.updateAnnoSelezionato(
                          new Date().getFullYear()
                        )
                      }
                    >
                      {new Date().getFullYear()}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.updateAnnoSelezionato(
                          new Date().getFullYear() - 1
                        )
                      }
                    >
                      {new Date().getFullYear() - 1}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.updateAnnoSelezionato(
                          new Date().getFullYear() - 2
                        )
                      }
                    >
                      {new Date().getFullYear() - 2}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.updateAnnoSelezionato(
                          new Date().getFullYear() - 3
                        )
                      }
                    >
                      {new Date().getFullYear() - 3}
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        this.props.updateAnnoSelezionato(
                          new Date().getFullYear() - 4
                        )
                      }
                    >
                      {new Date().getFullYear() - 4}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </Nav>
            <div
              style={{
                width: 30
              }}
            />
            <Nav navbar>
              <Link to="/dashboard/anagrafica">
                <NavItem>
                  <button className="btn btn-primary">
                    <span className="text-white">Anagrafica</span>
                  </button>
                </NavItem>
              </Link>
            </Nav>
            <div
              style={{
                width: 30
              }}
            />
            <Nav navbar>
              <NavItem>
                <button
                  className="btn btn-outline-danger"
                  onClick={this.props.logout}
                >
                  Logout
                </button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  annoSelezionato: state.auth.annoSelezionato
});

export default connect(mapStateToProps, { logout, updateAnnoSelezionato })(
  Header
);
