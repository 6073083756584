import { DISABLE_REMINDER_FATTURE } from "../actions/types";

const initialState = true;

export default function(state = initialState, action) {
  switch (action.type) {
    case DISABLE_REMINDER_FATTURE:
      return false;
    default:
      return state;
  }
}
