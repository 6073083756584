import { GET_NEWS, ELIMINA_NEWS } from "../actions/types";

const initialState = {
  news: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_NEWS:
      return {
        ...state,
        news: action.payload
      };

    case ELIMINA_NEWS:
      return {
        ...state,
        news: state.news.filter(news => {
          return news.id.toString() !== action.payload;
        })
      };

    default:
      return state;
  }
}
