import React, { Component } from "react";

import moment from "moment";

import { converti } from "../../calcoloTasse/funzioni";

import { connect } from "react-redux";

import {
  invia_contributi,
  get_contributi_anno,
} from "../../../actions/contributi";

class InserisciContributi extends Component {
  state = {
    mesi: ["", "", "", "", "", "", "", "", "", "", "", ""],
  };

  onInput = (e) => {
    var mesi = this.state.mesi;
    mesi[e.target.name] = e.target.value;
    this.setState({
      mesi,
    });
  };

  onSumbit = (e) => {
    e.preventDefault();
    var vuoto = true;
    this.state.mesi.forEach((mese) => {
      if (mese !== "") vuoto = false;
    });
    if (vuoto) {
    } else {
      this.state.mesi.forEach((mese, index) => {
        if (mese !== "") {
          const contributi = {
            importo: parseFloat(mese),
            data: moment(`${this.props.annoSelezionato}-${index + 1}-1`)._d,
          };
          this.props.invia_contributi(contributi);
        }
      });
      setTimeout(() => {
        this.props.get_contributi_anno(this.props.annoSelezionato);
      }, 1000);
      this.setState({
        mesi: ["", "", "", "", "", "", "", "", "", "", "", ""],
      });
    }
  };

  render() {
    var vettore = [
      {
        mese: "Gennaio",
        importo: 0,
      },
      {
        mese: "Febbraio",
        importo: 0,
      },
      {
        mese: "Marzo",
        importo: 0,
      },
      {
        mese: "Aprile",
        importo: 0,
      },
      {
        mese: "Maggio",
        importo: 0,
      },
      {
        mese: "Giugno",
        importo: 0,
      },
      {
        mese: "Luglio",
        importo: 0,
      },
      {
        mese: "Agosto",
        importo: 0,
      },
      {
        mese: "Settembre",
        importo: 0,
      },
      {
        mese: "Ottobre",
        importo: 0,
      },
      {
        mese: "Novembre",
        importo: 0,
      },
      {
        mese: "Dicembre",
        importo: 0,
      },
    ];

    this.props.contributi.forEach((contributi) => {
      const mese = new Date(contributi.data).getMonth();
      vettore[mese].importo += contributi.importo;
    });

    return (
      <form onSubmit={this.onSumbit}>
        <table style={{ width: "100%", border: "1px solid lightgray" }}>
          <tbody>
            <tr>
              <th className="__th">Mese</th>
              <th className="__th">Contributi pagati (€)</th>
              <th className="__th">Inserisci contributi (€)</th>
            </tr>
            {vettore.map((mese, index) => {
              return (
                <tr key={index}>
                  <td className="__td">{mese.mese}</td>
                  <td
                    className="__td"
                    style={mese.importo === 0 ? { color: "#FF8800" } : {}}
                  >
                    {mese.importo === 0
                      ? "DA INSERIRE"
                      : converti(mese.importo)}
                  </td>
                  <td className="__td">
                    <input
                      type="number"
                      name={index}
                      value={this.state.mesi[index]}
                      onChange={this.onInput}
                      className="form-control"
                      disabled={
                        index > new Date().getMonth() &&
                        this.props.annoSelezionato === new Date().getFullYear()
                          ? true
                          : false
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-center mt-5">
          <button className="btn btn-info">Inserisci</button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => ({
  annoSelezionato: state.auth.annoSelezionato,
});

export default connect(mapStateToProps, {
  invia_contributi,
  get_contributi_anno,
})(InserisciContributi);
