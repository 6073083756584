import React, { Component } from "react";

import Header from "../../Layout/Header";
import PrimaSezione from "./PrimaSezione";
import SecondaSezione from "./SecondaSezione";
import TerzaSezione from "./TerzaSezione";
import QuartaSezione from "./QuartaSezione";

import image from "../../../assets/homepage/header.jpg";

class Homepage extends Component {
  componentDidMount() {
    document.title = "SEVAT | Pagina Principale";
  }

  render() {
    return (
      <React.Fragment>
        <Header
          image={image}
          header="Servizi contabili e consulenza fiscale "
          sub="dal 2001"
        />
        <PrimaSezione />
        <SecondaSezione />
        <TerzaSezione />
        <QuartaSezione />
      </React.Fragment>
    );
  }
}

export default Homepage;
