import React, { Component } from "react";
import ReactDOM from "react-dom";

import { Spring } from "react-spring/renderprops";

class ModalDelete extends Component {
  modalRoot = document.getElementById("modal");
  delete = () => {
    this.props.unmount(true);
  };
  close = () => {
    this.props.unmount(false);
  };

  element = document.createElement("div");
  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.modalRoot.appendChild(this.element);
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  render() {
    return ReactDOM.createPortal(
      <div
        onClick={this.close}
        className="ui dimmer modals visible active"
        style={{ position: "fixed", zIndex: 1000000 }}
      >
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ friction: 50 }}
        >
          {(props) => (
            <div
              onClick={(e) => e.stopPropagation()}
              className="ui modal visible active"
              style={{ ...props, maxWidth: "400px" }}
            >
              <div className="header">{this.props.header}</div>
              <div className="actions">
                <button onClick={this.delete} className="ui button red">
                  Si
                </button>
                <button onClick={this.close} className="ui button">
                  No
                </button>
              </div>
            </div>
          )}
        </Spring>
      </div>,
      this.element
    );
  }
}
export default ModalDelete;
