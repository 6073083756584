import React, { Component } from "react";

import AOS from "aos";

export default class Fade extends Component {
    constructor(props, context) {
        super(props, context);
        AOS.init();
    }

    render() {
        return <div data-aos="fade-up">{this.props.children}</div>;
    }
}
