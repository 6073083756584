import React from "react";

export default function CookiePolicy() {
  return (
    <div className="__policies_wrapper">
      <div className="__policies_header" />
      <div className="ui text container text __policies_content">
        <div className="text-center h5">
          <strong>INFORMATIVA GENERALE SUI COOKIE</strong>
        </div>

        <p>
          Utilizziamo i cookie per rendere il nostro sito più facile ed
          intuitivo. I dati raccolti grazie ai cookie servono per rendere
          l'esperienza di navigazione più piacevole e più efficiente in futuro.
        </p>

        <p>
          <strong>Che cosa sono i Cookie?</strong>
          <br />I cookie sono piccoli file di testo inviati dal sito al
          terminale dell’interessato (solitamente al browser), dove vengono
          memorizzati per essere poi ritrasmessi al sito alla successiva visita
          del medesimo utente. Un cookie non può richiamare nessun altro dato
          dal disco fisso dell’utente né trasmettere virus informatici o
          acquisire indirizzi email. Ogni cookie è unico per il web browser
          dell’utente. Alcune delle funzioni dei cookie possono essere demandate
          ad altre tecnologie. Nel presente documento con il termine ‘cookie’ si
          vuol far riferimento sia ai cookie, propriamente detti, sia a tutte le
          tecnologie similari.
        </p>

        <p>
          <strong>Tipologie di Cookie</strong>
          <br />
          Relativamente alla natura dei cookie, ne esistono di diversi tipi:
          <ol className="d mt-3">
            <li className="mb-3">
              <strong>Cookie tecnici</strong>
              <br /> I cookie tecnici sono quelli utilizzati al solo fine di
              "effettuare la trasmissione di una comunicazione su una rete di
              comunicazione elettronica, o nella misura strettamente necessaria
              al fornitore di un servizio della società dell'informazione
              esplicitamente richiesto dall'abbonato o dall'utente a erogare
              tale servizio" (cfr. art. 122, comma 1, del Codice). <br /> Essi
              non vengono utilizzati per scopi ulteriori e sono normalmente
              installati direttamente dal titolare o gestore del sito web.
              Possono essere suddivisi in:
              <ul className="my-3">
                <li>
                  cookie di navigazione o di sessione, che garantiscono la
                  normale navigazione e fruizione del sito web (permettendo, ad
                  esempio, di realizzare un acquisto o autenticarsi per accedere
                  ad aree riservate); essi sono di fatto necessari per il
                  corretto funzionamento del sito;
                </li>
                <li>
                  cookie analytics, assimilati ai cookie tecnici laddove
                  utilizzati direttamente dal gestore del sito per raccogliere
                  informazioni, in forma aggregata, sul numero degli utenti e su
                  come questi visitano il sito stesso, al fine di migliorare le
                  performance del sito;
                </li>
                <li>
                  cookie di funzionalità, che permettono all'utente la
                  navigazione in funzione di una serie di criteri selezionati
                  (ad esempio, la lingua, i prodotti selezionati per l'acquisto)
                  al fine di migliorare il servizio reso allo stesso. Per
                  l'installazione di tali cookie non è richiesto il preventivo
                  consenso degli utenti (più informazioni nel paragrafo Gestione
                  dei cookie in basso).
                </li>
              </ul>
            </li>
            <li>
              <strong>Cookie di profilazione</strong> <br /> I cookie di
              profilazione sono volti a creare profili relativi all'utente e
              vengono utilizzati al fine di inviare messaggi pubblicitari in
              linea con le preferenze manifestate dallo stesso nell'ambito della
              navigazione in rete. Queste tecnologie non collezionano nome degli
              utenti, indirizzi mail, numeri di telefono, indirizzi fisici.{" "}
              <br />
              Per l'utilizzo dei cookie di profilazione è richiesto il consenso
              dell'interessato. Secondo il provvedimento (più informazioni nel
              paragrafo Gestione dei cookie in basso) l’utente può autorizzare o
              negare il consenso all'installazione dei cookie attraverso le
              opzioni fornite nella sezione "Gestione dei cookie". <br /> In
              caso di cookie di terze parti, il sito non ha un controllo diretto
              dei singoli cookie e non può controllarli (non può né installarli
              direttamente né cancellarli). Puoi comunque gestire questi cookie
              attraverso le impostazioni del browser (segui le istruzioni
              riportate più avanti), o i siti indicati nella sezione "Gestione
              dei cookie".
            </li>
          </ol>
        </p>

        <p>
          <strong>Elenco dei cookie tecnici presenti sul sito SEVAT</strong>
        </p>
        <p className="ml-5">
          <strong>Cookie di sistema</strong>
          <br />
          Il sito SEVAT utilizza cookie per garantire all'utente una migliore
          esperienza di navigazione; tali cookie sono indispensabili per la
          fruizione corretta del sito. Puoi disabilitare questi cookie dal
          browser seguendo le indicazioni nel paragrafo dedicato, ma
          comprometterai la tua esperienza sul sito e non potremo rispondere dei
          malfunzionamenti.
        </p>

        <p>
          <strong>Configurazioni per la disabilitazione dei cookies</strong>{" "}
          <br />
          L'utente può scegliere di non abilitare l'esecuzione dei cookies e,
          dunque, annullare l'attività di profilazione a scopo promozionale
          agendo sulle impostazioni del proprio browser.{" "}
        </p>

        <p>
          Istruzioni per la disabilitazione dei cookies relative ai browser più
          utilizzati:
        </p>

        <ul>
          <li className="ml-5">
            <strong>
              <a
                href="https://support.google.com/chrome/answer/95647?hl=it"
                target="_blank"
                rel="noopener noreferrer"
              >
                Chrome
              </a>
            </strong>
          </li>

          <li className="ml-5">
            <strong>
              <a
                href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie"
                target="_blank"
                rel="noopener noreferrer"
              >
                Firefox
              </a>
            </strong>
          </li>

          <li className="ml-5">
            <strong>
              <a
                href="https://support.microsoft.com/it-it/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Internet Explorer
              </a>
            </strong>
          </li>

          <li className="ml-5">
            <strong>
              <a
                href="https://support.apple.com/it-it/guide/safari/sfri11471/mac"
                target="_blank"
                rel="noopener noreferrer"
              >
                Safari
              </a>
            </strong>
          </li>
        </ul>

        <p>
          L'assenza di disabilitazione dei cookies per questo sito è da
          intendersi come assenso all'utilizzo dei cookies stessi, e dei dati da
          questi raccolti, come specificato nel paragrafo relativo ai cookies
          tecnici e di profilazione erogati da parti terze.
        </p>

        <p>
          <strong>Ulteriori informazioni</strong> <br />
          Per ulteriori informazioni riguardanti i cookie si prega di inviare
          un'email a{" "}
          <a href="mailto:segreteria@studiogardino.info">
            segreteria@studiogardino.info
          </a>
        </p>

        <p>
          <i>Data ultimo aggiornamento: 15 Aprile 2019</i>
        </p>
      </div>
    </div>
  );
}
