import React from "react";

const style = {
  left: 0,
  right: 0,
  top: 0,
  zIndex: 100,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
  minHeight: "100vh",
  userSelect: "none",
  width: "100%",
  overflow: "hidden",
};

function Header(props) {
  return (
    <div
      className="ui segment myHeader"
      style={{ ...style, backgroundImage: `url(${props.image})` }}
    >
      <h1
        className="ui header inverted center aligned newRoman slideInFromLeft"
        style={{
          marginTop: "33vh",
          fontSize: 40,
          marginBottom: 0,
        }}
      >
        <strong>{props.header}</strong>
      </h1>
      <h3
        className="ui header inverted center aligned slideInFromBottom"
        style={{ marginTop: 0 }}
      >
        <i>{props.sub}</i>
      </h3>
    </div>
  );
}
export default Header;
