import React, { Component } from "react";

import Header from "../../Layout/Header";
import PrimaSezione from "./PrimaSezione";
import SecondaSezione from "./SecondaSezione";
import TerzaSezione from "./TerzaSezione";

import image from "../../../assets/studio/header.jpg";

export default class Studio extends Component {
  componentDidMount() {
    document.title = "SEVAT | Team";
  }
  render() {
    return (
      <React.Fragment>
        <Header
          image={image}
          header="L'esperienza insieme alla nuova generazione"
        />
        <PrimaSezione />
        <SecondaSezione />
        <div className="ui divider container" />
        <TerzaSezione />
      </React.Fragment>
    );
  }
}
