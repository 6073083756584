import React from "react";
import { Link } from "react-router-dom";

const linkStyle = {
  color: "white",
};

export default function Footer() {
  return (
    <div
      className="ui inverted vertical footer segment"
      style={{ backgroundColor: "#393939" }}
    >
      <div className="ui center aligned container">
        <div className="ui stackable inverted divided grid">
          <div className="row">
            <div className="three wide column ">
              <div style={{ padding: "20px 0" }}>
                <i className="location arrow icon center large" />
              </div>
              <div>
                Via Bossolasco 11 <br />
                10141 Torino, Italia
              </div>
            </div>
            <div className="three wide column ">
              <div style={{ padding: "20px 0" }}>
                <i className="phone icon center large" />
              </div>
              <div>011 30 53 492</div>
            </div>
            <div className="five wide column ">
              <div style={{ padding: "20px 0" }}>
                <i className="envelope icon center large" />
              </div>
              <div>
                <a
                  href="mailto:segreteria@studiogardino.info"
                  style={linkStyle}
                >
                  segreteria@studiogardino.info
                </a>
                <br />
                <a href="mailto:sevatsrls@pec.it" style={linkStyle}>
                  sevatsrls@pec.it
                </a>
              </div>
            </div>
            <div className="five wide column">
              <h4 className="ui inverted header" style={{ padding: "20px 0" }}>
                Copyright © 2019 <br />
                SEVAT S.r.l.
              </h4>
              <p>
                P.IVA 11515720016 <br />
                All Rights Reserved
              </p>
              <p />
            </div>
          </div>
        </div>
        <div
          className="ui inverted section divider"
          style={{ marginBottom: 12, marginTop: 0 }}
        />

        <div
          className="ui horizontal inverted small link list"
          style={{ marginTop: 0 }}
        >
          <Link to="/sedi" className="item">
            Sedi
          </Link>
          <Link className="item" to="privacy-policy">
            Privacy Policy
          </Link>
          <Link className="item" to="cookie-policy">
            Cookie Policy
          </Link>
        </div>
      </div>
    </div>
  );
}
