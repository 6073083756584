import React, { Component } from "react";

import Modal from "../../Modal/Modal";

import Fade from "../../Layout/Fade";

import "./style.css";

const style = {
  marginTop: 50,
  marginBottom: 15,
  userSelect: "none"
};

const servizi = [
  {
    titolo: "Contabilità, Amministrazione e Bilancio",
    lista: [
      "Tenuta della contabilità : ordinaria, semplificata, Liberi Professionisti",
      "Assistenza per la tenuta dei libri sociali e per la redazione dei verbali assembleari",
      "Assistenza per la redazione del Bilancio d'esercizio, abbreviato, esteso e Micro, con deposito in formato Xbrl",
      "Pratiche di iscrizione, variazione e cancellazione in Camera di Commercio ed adempimenti presso il Suap"
    ]
  },
  {
    titolo: "Consulenza fiscale",
    lista: [
      "Dichiarazioni fiscali: modello Redditi Persone fisiche, Società di persone, Società di capitali e Enti del Terzo Settore",
      "Gestione imposte comunali e dichiarazioni immobili (IMU e TASI)",
      "Elaborazione modelli Esterometro e Liquidazioni periodiche IVA",
      "Modello 730, singolo e congiunto"
    ]
  },
  {
    titolo: "Consulenza societaria",
    lista: [
      "Costituzione di società ed assistenza in sede notarile",
      "Assistenza per redazione contratti, di natura privata, commerciale e societaria",
      "Assistenza per operazioni straordinarie aziendali (Fusione, Scissione, Trasformazione, Conferimento d'azienda, Cessione d'azienda, Liquidazione)",
      "Cessioni quote S.r.l.",
      "Analisi di Bilancio, controllo di gestione e redazione Business Plan",
      "Assistenza per adeguamento alla normativa Privacy G.D.P.R.  UE n.679/2016 e D.Lgs n.101/2018",
      "Contenzioso Tributario"
    ]
  },
  {
    titolo: "Successioni",
    lista: [
      "Compilazione e presentazione telematica della dichiarazione di successione e volture catastali",
      "Successioni legittime e testamentarie",
      "Successioni integrative, modificative, sostitutive",
      "Calcolo delle imposte di successione e compilazione del prospetto di autoliquidazione",
      "Richiesta delle visure catastali e ipotecarie in Conservatoria"
    ]
  },
  {
    titolo: "4.0",
    lista: [
      "Assistenza redazione relazione tecnica per: Formazione 4.0, Ricerca e Sviluppo",
      "Assistenza per analisi di fattibilità per la cd. Nuova Sabatini",
      "Consulenza amministrativo-contabile per il nuovo Super-Ammortamento ed Iper-Ammortamento"
    ]
  },
  {
    titolo: "Consulenza Giuridica e Finanza Agevolata",
    lista: [
      "Analisi finanziaria-patrimoniale",
      "Assistenza preventiva e consuntiva per la valutazione dell'indipendenza finanziaria, a seguito dei nuovi finanziamenti, in conformità con gli indici del Codice della Crisi (elaborati dal CNDCEC)"
    ]
  }
];

class PrimaSezione extends Component {
  state = {
    modalOpen: false,
    servizio: null
  };

  openModal = servizio => e => {
    this.setState({ modalOpen: true, servizio: servizi[servizio] });
  };

  handleModalClose = () => {
    this.setState({ modalOpen: false, servizio: null });
  };

  render() {
    const { servizio } = this.state;

    return (
      <Fade>
        {this.state.modalOpen ? (
          <Modal unmount={this.handleModalClose} servizio={servizio} />
        ) : null}
        <div className="ui section huge header centered" style={style}>
          I nostri servizi
        </div>
        <div className="ui container grey header section medium centered">
          La SEVAT assiste ed affianca tutte le Imprese, dalla ditta individuale
          alla Società di Capitali. I clienti possono contare su professionisti
          competenti che li affiancano lungo tutto il percorso contabile sino
          alla redazione del Bilancio e controllo di gestione, fornendo loro
          utili indicazioni sull’andamento dell'operatività, sui fattori critici
          da correggere, sulle agevolazioni fiscali e soprattutto consigliando
          le scelte migliori per una pianificazione fiscale ottimale.
        </div>
        <section className="__serivzi-grid-container">
          <div className="__servizi-grid">
            <div className="__servizi-grid-item" onClick={this.openModal(0)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              Contabilità, Amministrazione e Bilancio
            </div>
            <div className="__servizi-grid-item" onClick={this.openModal(1)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              Consulenza fiscale
            </div>
            <div className="__servizi-grid-item" onClick={this.openModal(2)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              Consulenza Societaria
            </div>
            <div className="__servizi-grid-item" onClick={this.openModal(3)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              Successioni
            </div>
            <div className="__servizi-grid-item" onClick={this.openModal(4)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              4.0
            </div>
            <div className="__servizi-grid-item" onClick={this.openModal(5)}>
              <div className="__servizi-grid-item-overlay">Dettagli</div>
              Consulenza Giuridica e Finanza Agevolata
            </div>
          </div>
        </section>
      </Fade>
    );
  }
}

export default PrimaSezione;
