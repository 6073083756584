import React, { Component } from "react";

import MaterialTable from "material-table";

import {
  art_com_reduce,
  calcolo_tasse_prof_1,
  calcolo_tasse_prof_2,
  calcolo_tasse_art,
  calcolo_cumulativo,
  calcolo_tasse_comm,
} from "../../calcoloTasse/calcoloTasse";

import moment from "moment";
import { converti } from "../../calcoloTasse/funzioni";
import { locale } from "../../calcoloTasse/impostazioni";

const anno_attuale = new Date().getFullYear();

class Tabella extends Component {
  render() {
    const { fatture, contributi, noteDiCredito, user } = this.props;

    var data = [];

    if (fatture.length === 0) {
      for (let i = 0; i < 12; i++) {
        data.push({
          mese: moment(`${anno_attuale}-${i + 1}-1`).format("MMMM"),
          lordo: 0,
          tasse: 0,
          spese: 0,
          netto: 0,
        });
      }
    } else {
      const lordo = art_com_reduce(fatture, [], noteDiCredito)[0].mesi.map(
        (elem) => {
          return elem.imponibile + elem.rivalsa;
        }
      );

      var tasse_mensili = [];
      if (user.categoria === "prof 1" || user.categoria === "prof 2") {
        tasse_mensili = art_com_reduce(
          fatture,
          contributi,
          noteDiCredito
        )[0].mesi.map((mese, index) => {
          if (user.categoria === "prof 1") {
            const tasse = calcolo_tasse_prof_1(mese, user, true);
            return tasse;
          } else if (user.categoria === "prof 2") {
            const tasse = calcolo_tasse_prof_2(mese, user, true);
            return tasse;
          } else {
            return {};
          }
        });
      } else if (user.categoria === "art") {
        tasse_mensili = [];
        const cumulativo = calcolo_cumulativo(
          art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
        );
        const tasse = calcolo_tasse_art(cumulativo, user, true);
        tasse_mensili = tasse.map((mese, index) => {
          var mese_prec = 0;
          if (index !== 0) {
            mese_prec =
              tasse[index - 1].importo_tassa_1 +
              tasse[index - 1].importo_tassa_2;
          }
          const mese_corr = mese.importo_tassa_1 + mese.importo_tassa_2;
          return mese_corr - mese_prec;
        });
      } else if (user.categoria === "comm") {
        tasse_mensili = [];
        const cumulativo = calcolo_cumulativo(
          art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
        );
        const tasse = calcolo_tasse_comm(cumulativo, user, true);
        tasse_mensili = tasse.map((mese, index) => {
          var mese_prec = 0;
          if (index !== 0) {
            mese_prec =
              tasse[index - 1].importo_tassa_1 +
              tasse[index - 1].importo_tassa_2;
          }
          const mese_corr = mese.importo_tassa_1 + mese.importo_tassa_2;
          return mese_corr - mese_prec;
        });
      }

      var spese = new Array(12).fill(0);

      this.props.spese.forEach((element) => {
        const mese = new Date(element.data).getMonth();
        spese[mese] += element.importo;
      });

      this.props.contributi.forEach((contributo) => {
        const mese = new Date(contributo.data).getMonth();
        if (user.categoria === "prof 1") {
          spese[mese] += contributo.importo;
        } else if (
          (user.categoria === "comm" || user.categoria === "art") &&
          mese + 1 !== 6 &&
          mese + 1 !== 7
        ) {
          spese[mese] += contributo.importo;
        }
      });

      var netto = [];
      for (let i = 0; i < 12; i++) {
        netto[i] = lordo[i] - tasse_mensili[i] - spese[i];
      }

      for (let i = 0; i < 12; i++) {
        data.push({
          mese: moment(`${anno_attuale}-${i + 1}-1`).format("MMMM"),
          lordo: converti(lordo[i]),
          spese: converti(spese[i]),
          tasse: converti(tasse_mensili[i]),
          netto: converti(netto[i]),
        });
      }
    }

    var stringa_spese;

    if (user.categoria === "prof 2") {
      stringa_spese = "Spese (€)";
    } else {
      stringa_spese = "Spese e Contributi (€)";
    }

    return (
      <div style={{ maxWidth: "100%" }}>
        <MaterialTable
          columns={[
            {
              title: "Mese",
              field: "mese",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Lordo (€)",
              field: "lordo",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Imposte (€)",
              field: "tasse",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
            {
              title: stringa_spese,
              field: "spese",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Netto (€)",
              field: "netto",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
          ]}
          data={data}
          title={`Andamento mensile ${new Date().getFullYear()}`}
          localization={locale}
          options={{
            paging: false,
            search: false,
            sorting: false,
            headerStyle: {
              fontSize: 14,
            },
          }}
        />
      </div>
    );
  }
}
export default Tabella;
