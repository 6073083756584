import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const AdminRoute = ({ component: Component, auth, ...rest }) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (auth.isLoading) {
                    return (
                        <div className="animated fadeIn pt-1 text-center">
                            Loading...
                        </div>
                    );
                } else if (!auth.isAuthenticated || !auth.user.is_staff) {
                    return <Redirect to="/login" />;
                } else return <Component {...props} />;
            }}
        />
    );
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps)(AdminRoute);
