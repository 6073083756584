import React from "react";

import "./style.css";

class SelezionaCategoria extends React.Component {
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  selezionaCategoria = (categoria, codice) => event => {
    this.props.seleziona(categoria, codice);
  };

  render() {
    return (
      <div className="__categorie-wrapper">
        <div className="__box-categorie">
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Pranzi/Hotel", 0)}
          >
            <i className="fas fa-utensils fa-2x" />
            <p style={{ userSelect: "none" }}>Pranzi/Hotel</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Casa/Ufficio", 1)}
          >
            <i className="fas fa-home fa-2x" />
            <p style={{ userSelect: "none" }}>Casa/Ufficio</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Professionisti", 2)}
          >
            <i className="fas fa-user-tie fa-2x" />
            <p style={{ userSelect: "none" }}>Professionisti</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Attività", 3)}
          >
            <i className="fas fa-tv fa-2x" />
            <p style={{ userSelect: "none" }}>Attività</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Rappresentanza", 4)}
          >
            <i className="fas fa-gift fa-2x" />
            <p style={{ userSelect: "none" }}>Rappresentanza</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Collaboratori", 5)}
          >
            <i className="fas fa-user-friends fa-2x" />
            <p style={{ userSelect: "none" }}>Collaboratori</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Telefono", 6)}
          >
            <i className="fas fa-phone fa-2x" />
            <p style={{ userSelect: "none" }}>Telefono</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Auto", 7)}
          >
            <i className="fas fa-car fa-2x" />
            <p style={{ userSelect: "none" }}>Auto</p>
          </div>
          <div
            className="__box-categorie-item"
            onClick={this.selezionaCategoria("Altro", 8)}
          >
            <i className="fas fa-euro-sign fa-2x" />
            <p style={{ userSelect: "none" }}>Altro</p>
          </div>
        </div>
      </div>
    );
  }
}

export default SelezionaCategoria;
