import {
  GET_FATTURE,
  ELIMINA_FATTURA,
  INVIA_FATTURA,
  INVIA_NOTA_DI_CREDITO,
  MODIFICA_FATTURA,
  GET_NOTE_DI_CREDITO,
  ELIMINA_NOTA_DI_CREDITO,
} from "../actions/types";

const initialState = {
  fatture: [],
  noteDiCredito: [],
  start: 0,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FATTURE:
      return {
        ...state,
        fatture: action.payload,
      };

    case GET_NOTE_DI_CREDITO:
      return {
        ...state,
        noteDiCredito: action.payload,
      };

    case INVIA_FATTURA:
      return {
        ...state,
        fatture: [action.payload, ...state.fatture],
      };

    case INVIA_NOTA_DI_CREDITO:
      return {
        ...state,
        noteDiCredito: [action.payload, ...state.noteDiCredito],
      };

    case MODIFICA_FATTURA:
      return {
        ...state,
        fatture: state.fatture.map((fattura) => {
          if (fattura.id === action.payload.id) {
            return action.payload;
          } else {
            return fattura;
          }
        }),
      };

    case ELIMINA_FATTURA:
      return {
        ...state,
        fatture: state.fatture.filter((fattura) => {
          return fattura.id.toString() !== action.payload;
        }),
      };

    case ELIMINA_NOTA_DI_CREDITO:
      return {
        ...state,
        noteDiCredito: state.noteDiCredito.filter((nota) => {
          return nota.id.toString() !== action.payload;
        }),
      };

    default:
      return state;
  }
}
