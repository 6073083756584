import React from "react";
import { Link } from "react-router-dom";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50
};

export default function TerzaSezione() {
  return (
    <Fade>
      <div style={style}>
        <div className="ui section huge header centered">
          Il nostro obiettivo
        </div>
        <div className="ui header medium grey container centered">
          Accompagnare il cliente nella crescita dell'attività, perchè il vostro
          business è il nostro traguardo. È nostra tendenza far buon uso delle
          opportunità che l'evoluzione tecnologica e l'intelligenza artificiale
          ci ha messo a disposizione; per rispondere alle vere esigenze
          dell'imprenditore abbiamo creato dei prodotti su misura. Scopri le
          nostre <Link to="/perche">piattaforme</Link> per avere la tua azienda
          sempre a portata di click
        </div>
      </div>
    </Fade>
  );
}
