import React, { Component } from "react";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import itLocale from "date-fns/locale/it";

import { FormGroup, Input, Label, FormFeedback } from "reactstrap";

export default class MaterialDatePicker extends Component {
  state = {
    open: false
  };

  openModal = () => {
    this.setState({ open: true });
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <FormGroup>
          <Label>{this.props.label}</Label>
          <Input
            type="text"
            name="nrFattura"
            value={
              this.props.value ? this.props.value.toLocaleDateString("it") : ""
            }
            placeholder={this.props.placeholder}
            onClick={this.openModal}
            invalid={this.props.invalid}
          />
          <FormFeedback>{this.props.invalidMessage}</FormFeedback>
        </FormGroup>
        <DatePicker
          cancelLabel="Chiudi"
          value={new Date()}
          onChange={this.props.onChange ? this.props.onChange : () => {}}
          format="d MMM yyyy"
          disableFuture={
            this.props.disableFuture ? this.props.disableFuture : false
          }
          onClose={() => this.setState({ open: false })}
          open={this.state.open}
          TextFieldComponent={() => <></>}
          ToolbarComponent={() => (
            <div
              style={{
                textAlign: "center",
                padding: 20,
                fontSize: 20,
                color: "white",
                backgroundColor: "#3f51b5"
              }}
            >
              Seleziona la {this.props.label.toLowerCase()}
            </div>
          )}
        />
      </MuiPickersUtilsProvider>
    );
  }
}
