import React from "react";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50,
  userSelect: "none",
};

export default function PrimaSezione() {
  return (
    <Fade>
      <div className="ui grid container centered" style={style}>
        <div className="ui section huge header centered mb-5">
          Professionisti
        </div>
        <div className="row">
          <div className="col-lg-5 __shadow text-center mb-5">
            <div className="ui section huge header centered mb-0">
              Pierfederico Gardino{" "}
            </div>
            <p className="ui header">
              <a
                href="https://www.linkedin.com/in/pierfederico-gardino-85394215b/"
                target="_blank"
              >
                Linkedin
              </a>
            </p>
            <p className="ui header medium grey container centered">
              esercita la Professione di Dottore Commercialista e Revisore
              Legale dei conti, iscritto all'Ordine di Torino; è Amministratore
              Unico della SEVAT s.r.l. ed Amministratore Unico della G&F
              Consulting Stp s.r.l. Laureato in Economia e Commercio (Torino),
              Economics (Torino), Filosofia (Siena).
            </p>
          </div>
          {/* <div className="col-lg-5 __shadow text-center ml-auto mb-5">
            <div className="ui section huge header centered">
              Pier Francesco Gardino{" "}
            </div>

            <p className="ui header medium grey container centered">
              esercita la Professione di Tributarista dal 2001, Qualificato ai
              sensi della Legge n.4/2013. Laureato in Sociologia, presso la York
              International University (Milano).
            </p>
          </div> */}
        </div>
        <div className="row">
          <div className="col-lg-5 __shadow text-center mb-5">
            <div className="ui section huge header centered mb-0">
              Davide Pelullo
            </div>
            <p className="ui header">
              <a href="mailto:davide.pelullo@studiogardino.info">
                davide.pelullo@studiogardino.info
              </a>
              <br />
              <a
                href="https://www.linkedin.com/in/davide-pelullo-6b9827a8/"
                target="_blank"
              >
                Linkedin
              </a>
            </p>
            <p className="ui header medium grey container centered">
              esercita la Professione di Dottore Commercialista, iscritto
              all'Ordine di Torino dal 2014. Laureato in Economia Aziendale
              (Torino) e Professioni Contabili (Torino). Collabora con la Sevat
              s.r.l. dal 2021.
            </p>
          </div>
        </div>

        <div className="ui section huge header centered mb-5">Dipendenti</div>
        <div className="row">
          <div className="col-lg-5 __shadow mb-5">
            <div className="ui section huge header centered mb-0">
              Elena Donatella Mazarese
            </div>
            <p className="ui header">
              <a href="mailto:elena@studiogardino.info">
                elena@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              diplomata in Ragioneria (Torino), collabora con la SEVAT s.r.l.
              dal 2019.
            </p>
          </div>
          <div className="col-lg-5 __shadow text-center ml-auto mb-5">
            <div className="ui section huge header centered mb-0">
              Antonella Beatrice
            </div>

            <p className="ui header">
              <a href="mailto:antonella@studiogardino.info">
                antonella@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              diplomata in Ragioneria (Torino), collabora con la SEVAT s.r.l.
              dal 2019.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 __shadow mb-5">
            <div className="ui section huge header centered mb-0">
              Cinzia Marino
            </div>

            <p className="ui header">
              <a href="mailto:cinzia@studiogardino.info">
                cinzia@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              ragioniera Commercialista (Torino), collabora con la SEVAT s.r.l.
              dal 2019.
            </p>
          </div>
          <div className="col-lg-5 __shadow text-center ml-auto mb-5">
            <div className="ui section huge header centered mb-0">
              Gianpiero Massarengo
            </div>
            <p className="ui header">
              <a href="mailto:gianpiero@studiogardino.info">
                gianpiero@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              collaboratore della Sevat s.r.l. dal 2020
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 __shadow mb-5">
            <div className="ui section huge header centered mb-0">
              Marco Lorenzo Migliaccio
            </div>
            <p className="ui header">
              <a href="mailto:lorenzo@studiogardino.info">
                lorenzo@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              collaboratore della Sevat s.r.l. dal 2020
            </p>
          </div>
        </div>
        <div className="ui section huge header centered mb-5">Of Counsel</div>
        <div className="row">
          <div className="col-lg-5 __shadow mb-5">
            <div className="ui section huge header centered mb-0">
              Dott. Miceli Giovanni
            </div>
            <p className="ui header">
              <a href="mailto:giovanni@studiogardino.info">
                giovanni@studiogardino.info
              </a>
            </p>
            <p className="ui header medium grey container centered">
              laureato Magistrale in Studi Giuridici Europei (Torino). Collabora
              con la Sevat s.r.l. dal 2020.
            </p>
          </div>
        </div>
      </div>
    </Fade>
  );
}
