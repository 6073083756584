import React, { Component } from "react";

const style = {
  padding: "0px 0px 70px 0px",
  maxWidth: "100vw",
  margin: 0
};

export default class SecondaSezione extends Component {
  render() {
    return (
      <div className="ui grid center aligned  middle aligned" style={style}>
        <div className="ui header medium grey container centered">
          La SEVAT è una società di elaborazione dati contabili e consulenza
          fiscale che nasce nel 2001 dall'esperienza pluridecennale dei suoi
          soci. Ad oggi la Società è composta da 2 professionisti e da
          collaboratori, con un portafoglio clienti in diversi settori
          produttivi e commerciali che nel Nord Ovest hanno deciso di affidarsi
          ai nostri servizi contabili, fiscali ed amministrativi.
        </div>
      </div>
    );
  }
}
