import {
  USER_LOADED,
  USER_LOADING,
  GET_ERRORS,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  AUTH_ERROR,
  UPDATE_ANNO_SELEZIONATO,
} from "./types";
import {
  showModalError,
  showModalContributi,
  showModalSuccess,
} from "../components/Modal/Modals";
import { axiosInstance } from "../axiosInstance";

export const loadUser = () => (dispatch, getState) => {
  dispatch({
    type: USER_LOADING,
  });

  axiosInstance
    .get("/api/auth/user", tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: AUTH_ERROR,
      });
    });
};

export const updateUser = (user) => (dispatch, getState) => {
  axiosInstance
    .post("/api/auth/user", user, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: USER_LOADED,
        payload: res.data,
      });
      showModalSuccess("Profilo aggiornato");
    });
};

export const login = (username, password) => (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({
    username,
    password,
  });

  axiosInstance
    .post("/api/auth/login", body, config)
    .then((res) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res.data,
      });
      showModalContributi();
    })
    .catch((err) => {
      showModalError("Credenziali non valide... Riprovare");
      dispatch({
        type: LOGIN_FAIL,
      });
    });
};

export const logout = () => (dispatch, getState) => {
  axiosInstance
    .post("/api/auth/logout", null, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: {
          msg: err.response.data,
          status: err.response.status,
        },
      });
    });
};

export const updateAnnoSelezionato = (anno) => (dispatch) => {
  dispatch({
    type: UPDATE_ANNO_SELEZIONATO,
    payload: anno,
  });
};

export const tokenConfig = (getState) => {
  const token = getState().auth.token;

  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (token) {
    config.headers["Authorization"] = `Token ${token}`;
  }

  return config;
};
