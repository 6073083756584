import React from "react";

import "./style.css";

class CircularProgressBar extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }
  state = { animate: false };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    const offsetPage = window.pageYOffset + window.innerHeight;

    if (
      offsetPage >
      this.ref.current.offsetParent.offsetParent.offsetTop + 100
    ) {
      this.setState({ animate: true });
    }
  };

  render() {
    return (
      <div ref={this.ref} className="CircularProgressbar-container">
        <svg className="CircularProgressbar " viewBox="0 0 100 100">
          <path
            className="CircularProgressbar-trail"
            d="
    M 50,50
    m 0,-46
    a 46,46 0 1 1 0,92
    a 46,46 0 1 1 0,-92
  "
            strokeWidth="8"
            fillOpacity="0"
          />
          <path
            className={
              this.state.animate
                ? "CircularProgressbar-path transition"
                : "CircularProgressbar-path"
            }
            d="
    M 50,50
    m 0,-46
    a 46,46 0 1 1 0,92
    a 46,46 0 1 1 0,-92
  "
            strokeWidth="8"
            fillOpacity="0"
          />
          <text className="CircularProgressbar-text" x="50" y="50">
            {this.props.number}
          </text>
        </svg>
        <div className="CircularProgressbar-description">
          <span>{this.props.description}</span>
          <br />
          <span
            style={{
              fontSize: 15
            }}
          >
            {this.props.sub}
          </span>
        </div>
      </div>
    );
  }
}

export default CircularProgressBar;
