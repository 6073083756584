import React from "react";
import {
  CardTitle,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Form,
  Button,
  CardSubtitle,
} from "reactstrap";

import { updateUser } from "../../../actions/auth";

import { connect } from "react-redux";
import { URL } from "../../../axiosInstance";

require("react-images-upload");

class Anagrafica extends React.Component {
  state = {
    user: {},
    logoSrc:
      "http://www.resetyourbody.com/wp-content/uploads/COMPANY_LOGO/logo-default.png",
  };

  componentDidMount() {
    let user = this.props.user;
    if (user.logo === null) {
      user.logo = "";
    }
    if (user.logo !== "") {
      this.setState({
        logoSrc: URL + user.logo,
      });
    }
    this.setState({
      user,
    });
  }

  onInput = (e) => {
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user });
  };

  onLogoChange = (e) => {
    let user = this.state.user;

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onloadend = function (e) {
      user.logo = reader.result;
      this.setState({
        logoSrc: reader.result,
      });
    }.bind(this);

    this.setState({
      user,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.props.updateUser(this.state.user);
  };

  render() {
    const { user } = this.state;

    return (
      <React.Fragment>
        <div className="content">
          <div className="__tab-wrapper">
            <CardTitle className="text-center">
              <i
                className="fas fa-receipt text-info"
                style={{ marginRight: "1em" }}
              />
              Anagrafica
              <i
                className="fas fa-receipt text-info"
                style={{ marginLeft: "1em" }}
              />
            </CardTitle>

            <Form onSubmit={this.onSubmit}>
              <label
                className="fileContainer my-4"
                style={{
                  boxShadow: "none",
                }}
              >
                <img
                  style={{
                    width: 128,
                    height: "auto",
                  }}
                  src={this.state.logoSrc}
                  alt=""
                />
                <span className="mt-3">Seleziona il tuo logo</span>
                <input
                  type="file"
                  accept="image/*"
                  onChange={this.onLogoChange}
                ></input>
              </label>

              <Row>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">Denominazione</Label>
                    <Input
                      type="text"
                      name="denominazione"
                      value={user.denominazione}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">Luogo d'esercizio</Label>
                    <Input
                      type="text"
                      name="luogo_esercizio"
                      value={user.luogo_esercizio}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">Codice fiscale</Label>
                    <Input
                      type="text"
                      name="codice_fiscale"
                      value={user.codice_fiscale}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">Partita IVA</Label>
                    <Input
                      type="text"
                      name="partita_iva"
                      value={user.partita_iva}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <CardSubtitle className="text-center mt-4 mb-2">
                Dati relativi ai bonifici (opzionale)
              </CardSubtitle>
              <Row>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">IBAN</Label>
                    <Input
                      type="text"
                      name="iban"
                      value={user.iban}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Label for="exampleEmail">Intestato a</Label>
                    <Input
                      type="text"
                      name="intestato"
                      value={user.intestato}
                      onChange={this.onInput}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Button
                className="mt-5"
                type="submit"
                color="info"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  marginTop: "2em",
                }}
              >
                Salva
              </Button>
            </Form>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { updateUser })(Anagrafica);
