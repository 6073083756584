import React from "react";

import { connect } from "react-redux";
import {
  get_clienti,
  invia_cliente,
  elimina_cliente
} from "../../../actions/clienti";

import ModalEdit from "../../components/Modal/ModalEdit";
import TabellaClienti from "./TabellaClienti";

class Clienti extends React.Component {
  state = {
    modalOpen: false,
    modal: "",
    id: "",
    cliente: {}
  };

  componentDidMount() {
    this.props.get_clienti();
  }

  openModal = (modal, id) => {
    this.setState({ modalOpen: true, id: id, modal: modal });
  };

  handleModalCloseDelete = action => {
    if (action === true && this.state.modal === "delete fattura") {
      this.props.elimina_fattura(this.state.id);
    }
    this.setState({ modalOpen: false });
  };
  handleModalCloseEdit = action => {
    if (action === true) {
      this.props.elimina_fattura(this.state.id);
    }
    this.setState({ modalOpen: false });
  };

  aggiungiCliente = cliente => {
    this.setState({
      cliente
    });
  };

  render() {
    return (
      <div className="content">
        {this.state.modalOpen ? (
          <ModalEdit
            unmount={this.handleModalCloseDelete}
            fattura={this.props.fatture.fatture.filter(fattura => {
              return fattura.id === this.state.id;
            })}
          />
        ) : (
          ""
        )}

        <TabellaClienti
          data={this.props.clienti}
          elimina_cliente={this.props.elimina_cliente}
          invia_cliente={this.props.invia_cliente}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clienti: state.clienti
});

export default connect(
  mapStateToProps,
  { get_clienti, invia_cliente, elimina_cliente }
)(Clienti);
