import React, { Component } from "react";

import Sidebar from "./Sidebar";
import InserisciNews from "./InserisciNews";
import ListaNews from "./ListaNews";
import Content from "./Content";

import AdminRoute from "../../../common/AdminRoute";

class Manage extends Component {
    render() {
        return (
            <React.Fragment>
                <Sidebar />
                <Content>
                    <AdminRoute
                        exact
                        path="/gestione-news/inserisci"
                        component={InserisciNews}
                    />
                    <AdminRoute
                        exact
                        path="/gestione-news/lista"
                        component={ListaNews}
                    />
                </Content>
            </React.Fragment>
        );
    }
}
export default Manage;
