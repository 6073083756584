import React, { Component } from "react";

import MaterialTable from "material-table";

import { locale } from "../../calcoloTasse/impostazioni";
import { converti } from "../../calcoloTasse/funzioni";
import {
  showModalAggiungiFatture,
  showModalConferma,
  showModalInviaFattura,
} from "../../../components/Modal/Modals";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { inviaEmailFattura } from "../../../actions/sendMail";

import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import itLocale from "date-fns/locale/it";

import Switch from "react-switch";
import { modifica_fattura_generata } from "../../../actions/fatture";
import { URL } from "../../../axiosInstance";

class TabellaFatture extends Component {
  render() {
    const fatture = this.props.data.fatture.map((fattura) => {
      return {
        id: fattura.id,
        data: new Date(fattura.data_emissione),
        data_incasso: new Date(fattura.data_incasso),
        nr_fattura: fattura.nr_fattura,
        cliente: fattura.cliente,
        incassata: fattura.incassata,
        generata: fattura.generata,
        pdf: URL + fattura.pdf,
        totale:
          this.props.user.categoria === "prof 1" ||
          this.props.user.categoria === "prof 2"
            ? fattura.imponibile + fattura.rivalsa + fattura.marca_da_bollo
            : fattura.imponibile + fattura.marca_da_bollo,
      };
    });

    return (
      <MaterialTable
        columns={[
          { title: "ID", field: "id", hidden: true },
          {
            title: "Incassata",
            field: "incassata",
            render: (rowData) => {
              if (rowData.generata)
                return (
                  <MyDatePicker
                    incassata={rowData.incassata}
                    modifica_fattura={this.props.modifica_fattura_generata}
                    id={rowData.id}
                  />
                );
              else {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Switch
                      checked={true}
                      onChange={() => {}}
                      onColor="#9dd49d"
                      offHandleColor="#d9534f"
                      onHandleColor="#5cb85c"
                      handleDiameter={23}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={18}
                      width={38}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                );
              }
            },
            cellStyle: { fontSize: 14 },
          },
          { title: "generata", field: "generata", hidden: true },
          {
            title: "Data emissione",
            field: "data",
            type: "datetime",
            defaultSort: "desc",
            render: (rowData) => {
              return rowData.data.toLocaleDateString();
            },
            cellStyle: { fontSize: 14 },
          },
          {
            title: "Data incasso",
            field: "data_incasso",
            type: "datetime",
            render: (rowData) => {
              if (rowData.incassata === true) {
                return rowData.data_incasso.toLocaleDateString();
              } else {
                return "";
              }
            },
            cellStyle: { fontSize: 14 },
          },
          {
            title: "Numero fattura",
            field: "nr_fattura",
            type: "numeric",
            cellStyle: { fontSize: 14 },
          },
          {
            title: "Cliente",
            field: "cliente",
            cellStyle: { fontSize: 14 },
          },
          {
            title: "Totale (€)",
            field: "totale",
            type: "numeric",
            cellStyle: { fontSize: 14 },
            render: (rowData) => {
              return converti(rowData.totale);
            },
          },
        ]}
        data={fatture}
        actions={[
          (rowData) => ({
            icon: "",
            tooltip: "Modifica fattura",
            hidden: rowData.generata === true,
            disabled: true,
            onClick: (event, rowData) => {},
            iconProps: {
              style: {
                fontSize: 30,
                color: "white",
              },
            },
          }),
          (rowData) => ({
            icon: "edit",
            tooltip: "Modifica fattura",
            hidden: rowData.generata === true,
            onClick: (event, rowData) => {
              this.props.open("edit", rowData.id);
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "orange",
              },
            },
          }),
          (rowData) => ({
            icon: "delete_forever",
            tooltip: "Cancella fattura",
            hidden: rowData.generata === true,
            onClick: (event, rowData) => {
              showModalConferma(
                "Sei sicuro di voler eliminare la fattura?",
                this.props.delete,
                rowData.id
              );
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "red",
              },
            },
          }),
          (rowData) => ({
            icon: "save_alt",
            tooltip: "Scarica fattura",
            hidden: rowData.generata === false,
            onClick: (event, rowData) => {
              fetch(rowData.pdf)
                .then((resp) => resp.blob())
                .then((blob) => {
                  const url = window.URL.createObjectURL(blob);
                  const a = document.createElement("a");
                  a.style.display = "none";
                  a.href = url;
                  // the filename you want
                  a.download =
                    "fattura-" + rowData.data.toLocaleDateString("ie") + ".pdf";
                  document.body.appendChild(a);
                  a.click();
                  window.URL.revokeObjectURL(url);
                })
                .catch((err) => {
                  alert("Si è verificato un errore");
                });
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "blue",
              },
            },
          }),
          (rowData) => ({
            icon: "share",
            tooltip: "Invia fattura",
            hidden: rowData.generata === false,
            onClick: (event, rowData) => {
              showModalInviaFattura(
                rowData.pdf,
                this.props.inviaEmailFattura,
                this.props.user.email
              );
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "green",
              },
            },
          }),
          (rowData) => ({
            icon: "delete_forever",
            tooltip: "Cancella fattura",
            hidden: rowData.generata === false,
            onClick: (event, rowData) => {
              showModalConferma(
                "Sei sicuro di voler eliminare la fattura?",
                this.props.delete_g,
                rowData.id
              );
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "red",
              },
            },
          }),
          {
            icon: IconaAggiungiFattura,
            tooltip: "Aggiungi Fattura",
            iconProps: {
              fontSize: "large",
              color: "primary",
            },
            isFreeAction: true,
            onClick: () => showModalAggiungiFatture(this.props.history),
          },
        ]}
        title=""
        options={{
          actionsColumnIndex: -1,
          search: false,
          headerStyle: {
            fontSize: 14,
          },
          pageSize: 10,
          pageSizeOptions: [10],
        }}
        localization={locale}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  annoSelezionato: state.auth.annoSelezionato,
});

export default withRouter(
  connect(mapStateToProps, { inviaEmailFattura, modifica_fattura_generata })(
    TabellaFatture
  )
);

function IconaAggiungiFattura() {
  return (
    <div>
      <span className="MuiIconButton-label">
        <span
          className="material-icons MuiIcon-root MuiIcon-colorPrimary MuiIcon-fontSizeLarge"
          aria-hidden="true"
        >
          add
        </span>
        <span className="MuiIcon-colorPrimary">Aggiungi nuova fattura</span>
      </span>
      <span className="MuiTouchRipple-root" />
    </div>
  );
}

class MyDatePicker extends Component {
  state = {
    open: false,
  };

  onChange = (v) => {
    this.props.modifica_fattura(this.props.id, v, true);
  };

  render() {
    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={itLocale}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Switch
            checked={this.props.incassata}
            onChange={() => {
              if (this.props.incassata === false) {
                this.setState({
                  open: !this.state.open,
                });
              } else {
                this.props.modifica_fattura(this.props.id, null, false);
              }
            }}
            onColor="#9dd49d"
            offHandleColor="#d9534f"
            onHandleColor="#5cb85c"
            handleDiameter={23}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={18}
            width={38}
            className="react-switch"
            id="material-switch"
          />
        </div>
        <DatePicker
          cancelLabel="Chiudi"
          value={new Date()}
          onChange={this.onChange}
          format="d MMM yyyy"
          disableFuture={true}
          onClose={() => this.setState({ open: false })}
          open={this.state.open}
          TextFieldComponent={() => <></>}
          ToolbarComponent={() => (
            <div
              style={{
                textAlign: "center",
                padding: 20,
                fontSize: 20,
                color: "white",
                backgroundColor: "#3f51b5",
              }}
            >
              Seleziona la data di incasso
            </div>
          )}
        />
      </MuiPickersUtilsProvider>
    );
  }
}
