import MaterialTable from "material-table";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  elimina_nota_di_credito,
  get_note_di_credito,
} from "../../../actions/fatture";
import { locale } from "../../calcoloTasse/impostazioni";
import { converti } from "../../calcoloTasse/funzioni";
import {
  showModalConferma,
  showModalInviaNotaDiCredito,
} from "../../../components/Modal/Modals";
import { inviaEmailNotaDiCredito } from "../../../actions/sendMail";
import { URL } from "../../../axiosInstance";

function IconaAggiungiNotaDiCredito() {
  return (
    <div>
      <span className="MuiIconButton-label">
        <span
          className="material-icons MuiIcon-root MuiIcon-colorPrimary MuiIcon-fontSizeLarge"
          aria-hidden="true"
        >
          add
        </span>
        <span className="MuiIcon-colorPrimary">Aggiungi nota di credito</span>
      </span>
      <span className="MuiTouchRipple-root" />
    </div>
  );
}

class NoteDiCredito extends Component {
  componentDidMount() {
    this.props.get_note_di_credito();
  }

  calcolaTotale = (nota_di_credito) => {
    return this.props.user.categoria === "prof 1" ||
      this.props.user.categoria === "prof 2"
      ? nota_di_credito.imponibile +
          nota_di_credito.rivalsa +
          nota_di_credito.marca_da_bollo
      : nota_di_credito.imponibile + nota_di_credito.marca_da_bollo;
  };

  render() {
    const NoteDiCredito = this.props.fatture.noteDiCredito.map(
      (nota_di_credito) => {
        return {
          id: nota_di_credito.id,
          data: new Date(nota_di_credito.data),
          numero: nota_di_credito.numero,
          id_fattura: nota_di_credito.id_fattura,
          nr_fattura: nota_di_credito.nr_fattura,
          totale: this.calcolaTotale(nota_di_credito),
          cliente: nota_di_credito.cliente,
          pdf: URL + nota_di_credito.pdf,
        };
      }
    );

    return (
      <div className="content">
        <MaterialTable
          columns={[
            { title: "ID", field: "id", hidden: true },
            {
              title: "Data",
              field: "data",
              type: "datetime",
              defaultSort: "desc",
              render: (rowData) => {
                return rowData.data.toLocaleDateString();
              },
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Numero",
              field: "numero",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Numero fattura relativa",
              field: "nr_fattura",
              type: "numeric",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Cliente",
              field: "cliente",
              cellStyle: { fontSize: 14 },
            },
            {
              title: "Totale (€)",
              field: "totale",
              type: "numeric",
              cellStyle: { fontSize: 14 },
              render: (rowData) => {
                return converti(rowData.totale);
              },
            },
          ]}
          data={NoteDiCredito}
          title=""
          options={{
            actionsColumnIndex: -1,
            search: false,
            headerStyle: {
              fontSize: 14,
            },
            pageSize: 10,
            pageSizeOptions: [10],
          }}
          localization={locale}
          actions={[
            (rowData) => ({
              icon: "save_alt",
              tooltip: "Scarica nota di credito",
              onClick: (event, rowData) => {
                fetch(rowData.pdf)
                  .then((resp) => resp.blob())
                  .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    // the filename you want
                    a.download =
                      "nota_di_credito-" +
                      rowData.data.toLocaleDateString("ie") +
                      ".pdf";
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                  })
                  .catch((err) => {
                    alert("Si è verificato un errore");
                  });
              },
              iconProps: {
                style: {
                  fontSize: 30,
                  color: "blue",
                },
              },
            }),
            (rowData) => ({
              icon: "share",
              tooltip: "Invia nota di credito",
              onClick: (event, rowData) => {
                showModalInviaNotaDiCredito(
                  rowData.pdf,
                  this.props.inviaEmailNotaDiCredito,
                  this.props.user.email
                );
              },
              iconProps: {
                style: {
                  fontSize: 30,
                  color: "green",
                },
              },
            }),

            (rowData) => ({
              icon: "delete_forever",
              tooltip: "Cancella fattura",
              hidden: rowData.generata === false,
              onClick: (event, rowData) => {
                showModalConferma(
                  "Sei sicuro di voler eliminare la nota di credito?",
                  this.props.elimina_nota_di_credito,
                  rowData.id
                );
              },
              iconProps: {
                style: {
                  fontSize: 30,
                  color: "red",
                },
              },
            }),

            {
              icon: IconaAggiungiNotaDiCredito,
              tooltip: "Aggiungi nota di credito",
              iconProps: {
                fontSize: "large",
                color: "primary",
              },
              isFreeAction: true,
              onClick: () =>
                this.props.history.push("/dashboard/note_di_credito/genera"),
            },
          ]}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  user: state.auth.user,
  fatture: state.fatture,
});

export default connect(mapStateToProps, {
  get_note_di_credito,
  elimina_nota_di_credito,
  inviaEmailNotaDiCredito,
})(NoteDiCredito);
