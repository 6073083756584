import React, { Component } from "react";

import { Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import { localeConvert } from "../../fucntions/functions";
import { URL } from "../../../axiosInstance";

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    width: "10%",
    height: "auto",
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 10,
    marginTop: 4,
    textAlign: "left",
    fontWeight: 10,
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

function getTabella(articoli) {
  return articoli.map((articolo) => {
    return (
      <View key={articolo.nome}>
        <View
          style={{
            marginTop: 5,
            marginBottom: 5,
            width: "100%",
            flexDirection: "row",
          }}
        >
          <View style={{ width: "25%" }}>
            <Text
              style={{
                fontSize: 12,
                padding: 2,
              }}
            >
              {articolo.nome}
            </Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text
              style={{
                fontSize: 12,
                padding: 2,
                alignSelf: "flex-end",
              }}
            >
              {localeConvert(articolo.prezzo)}
            </Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text
              style={{
                fontSize: 12,
                padding: 2,
                alignSelf: "flex-end",
              }}
            >
              {articolo.quantita} {articolo.unita_misura}
            </Text>
          </View>
          <View style={{ width: "25%" }}>
            <Text
              style={{
                fontSize: 12,
                padding: 2,
                alignSelf: "flex-end",
              }}
            >
              {localeConvert(articolo.totale)}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: "100%",
            height: 2,
            backgroundColor: "#448aff",
          }}
        ></View>
      </View>
    );
  });
}

export default class PdfFattura extends Component {
  state = {
    logo: "",
  };

  async componentDidMount() {
    this.setState({
      logo: `${URL}${this.props.user.logo}`,
    });
  }

  render() {
    return (
      <Document>
        <Page style={styles.body}>
          <View style={{ flexDirection: "row" }}>
            <View style={{ width: "50%" }}>
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#448aff",
                  }}
                  fixed
                >
                  {this.props.user.denominazione}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Partita IVA: {this.props.user.partita_iva}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Codice fiscale: {this.props.user.codice_fiscale}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.user.luogo_esercizio}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: "50%",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Text
                style={{
                  fontSize: 16,
                  color: "#448aff",
                }}
              >
                Fattura
              </Text>
              <Text style={styles.header} fixed>
                nr. {this.props.fattura.nrFattura} del{" "}
                {this.props.fattura.dataEmissione !== ""
                  ? this.props.fattura.dataEmissione.toLocaleDateString()
                  : ""}
              </Text>
            </View>
          </View>
          <View
            style={{
              width: "100%",
              height: 2,
              marginTop: 5,
              marginBottom: 5,
              backgroundColor: "#448aff",
            }}
          />
          {this.props.cliente ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: 16,
                    color: "#448aff",
                  }}
                  fixed
                >
                  Spettabile
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.cliente.denominazione}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Partita IVA: {this.props.cliente.partita_iva}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  Codice fiscale: {this.props.cliente.codice_fiscale}
                </Text>
              </View>
              <View>
                <Text style={styles.header} fixed>
                  {this.props.cliente.luogo_esercizio}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}

          <View
            style={{
              marginTop: 50,
              backgroundColor: "#448aff",
              width: "100%",
              flexDirection: "row",
            }}
          >
            <View style={{ width: "25%" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  padding: 2,
                }}
              >
                Descrizione
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  padding: 2,
                  alignSelf: "flex-end",
                }}
              >
                Prezzo unitario (€)
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  padding: 2,
                  alignSelf: "flex-end",
                }}
              >
                Quantità
              </Text>
            </View>
            <View style={{ width: "25%" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: 12,
                  padding: 2,
                  alignSelf: "flex-end",
                }}
              >
                Importo totale (€)
              </Text>
            </View>
          </View>
          {getTabella(this.props.fattura.articoli)}
          <View
            style={{
              flexDirection: "row",
              marginTop: 20,
            }}
          >
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  padding: 2,
                }}
              >
                Totale onorario:
              </Text>
            </View>
            <View
              style={{
                width: "15%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 12,
                  padding: 2,
                }}
              >
                € {localeConvert(this.props.fattura.imponibile)}
              </Text>
            </View>
          </View>
          {this.props.fattura.rivalsa !== 0 ? (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  {this.props.user.categoria === "prof 2"
                    ? "Rivalsa INPS:"
                    : "Rivalsa professionale " +
                      this.props.fattura.percentualeRivalsa * 100 +
                      "%:"}
                </Text>
              </View>
              <View
                style={{
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  € {localeConvert(this.props.fattura.rivalsa)}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}
          {this.props.fattura.marcaDaBollo === false &&
          this.props.fattura.marca_da_bollo !== 0 ? (
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: "85%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  Marca da bollo:
                </Text>
              </View>
              <View
                style={{
                  width: "15%",
                }}
              >
                <Text
                  style={{
                    alignSelf: "flex-end",
                    fontSize: 12,
                    padding: 2,
                  }}
                >
                  € {localeConvert(this.props.fattura.marca_da_bollo)}
                </Text>
              </View>
            </View>
          ) : (
            <View />
          )}

          <View
            style={{
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <View
              style={{
                width: "85%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 16,
                  padding: 2,
                  color: "#448aff",
                }}
              >
                Totale fattura:
              </Text>
            </View>
            <View
              style={{
                width: "15%",
              }}
            >
              <Text
                style={{
                  alignSelf: "flex-end",
                  fontSize: 16,
                  padding: 2,
                  color: "#448aff",
                }}
              >
                €{" "}
                {localeConvert(
                  this.props.fattura.imponibile +
                    this.props.fattura.rivalsa +
                    this.props.fattura.marca_da_bollo
                )}
              </Text>
            </View>
          </View>

          {this.props.fattura.terminiPagamento !== "Non specificato" ? (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 15,
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "heavy",
                    }}
                  >
                    Termini pagamento
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "flex-end",
                      fontSize: 16,
                      color: "#448aff",
                    }}
                  >
                    {this.props.fattura.terminiPagamento}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  height: 2,
                  marginTop: 5,
                  marginBottom: 5,
                  backgroundColor: "#448aff",
                }}
              />
            </View>
          ) : (
            <View />
          )}

          {this.props.fattura.metodoPagamento !== "Non specificato" ? (
            <View>
              <View
                style={{
                  flexDirection: "row",
                  marginTop: 15,
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "heavy",
                    }}
                  >
                    Modalità di pagamento
                  </Text>
                </View>
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "flex-end",
                      fontSize: 16,
                      color: "#448aff",
                    }}
                  >
                    {this.props.fattura.metodoPagamento}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  height: 2,
                  marginTop: 5,
                  marginBottom: 10,
                  backgroundColor: "#448aff",
                }}
              />
              {this.props.fattura.metodoPagamento === "Bonifico" &&
              this.props.fattura.iban !== "" &&
              this.props.fattura.intestato !== "" ? (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <Text
                    style={{
                      fontSize: 10,
                    }}
                  >
                    IBAN: {this.props.fattura.iban.toUpperCase()}
                  </Text>
                  <Text
                    style={{
                      fontSize: 10,
                    }}
                  >
                    Intestato a {this.props.fattura.intestato}
                  </Text>
                </View>
              ) : (
                <View />
              )}
            </View>
          ) : (
            <View />
          )}

          {this.props.fattura.note !== "" ? (
            <View>
              <View
                style={{
                  marginTop: 15,
                }}
              >
                <View
                  style={{
                    width: "50%",
                  }}
                >
                  <Text
                    style={{
                      alignSelf: "flex-end",
                      fontSize: 16,
                      color: "#448aff",
                    }}
                  >
                    Note
                  </Text>
                </View>
                <View
                  style={{
                    width: "100%",
                    marginTop: 3,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      fontWeight: "heavy",
                    }}
                  >
                    {this.props.fattura.note}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  width: "100%",
                  height: 2,
                  marginTop: 5,
                  marginBottom: 5,
                  backgroundColor: "#448aff",
                }}
              />
            </View>
          ) : (
            <View />
          )}

          <View>
            <Text style={{ fontSize: 10, marginTop: 50, fontStyle: "italic" }}>
              Operazione esente da Iva in quanto effettuata ai sensi dell'art.1,
              commi da 54 a 89 della Legge n. 190/2014 Regime forfetario. Il
              compenso non è soggetto a ritenute d'acconto ai sensi della legge
              190 del 23 Dicembre 2014 art. 1 comma 67. Ed ai sensi della Legge
              n.145/2018
            </Text>
          </View>
          {this.props.fattura.codiceMarcaDaBollo !== "" ? (
            <View>
              <Text
                style={{ fontSize: 10, marginTop: 20, fontStyle: "italic" }}
              >
                Imposta di bollo assolta sull'originale. ID{" "}
                {this.props.fattura.codiceMarcaDaBollo}
              </Text>
            </View>
          ) : (
            <View />
          )}
        </Page>
      </Document>
    );
  }
}
