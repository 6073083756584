import { converti } from "./funzioni";

export function prof_1_2_reduce(
  fatture = [],
  contributi = [],
  noteDiCredito = []
) {
  var vettore_fatture = fatture
    .reduce((vettore, elem) => {
      const anno = new Date(elem.data_incasso).getFullYear();
      if (
        vettore.filter((a) => {
          if (a.anno === anno) return true;
          else return false;
        }).length > 0
      ) {
        vettore.forEach((element) => {
          if (element.anno === anno) {
            element.imponibile += elem.imponibile;
            element.rivalsa += elem.rivalsa;
          }
        });
      } else {
        const obj = {
          anno: anno,
          imponibile: elem.imponibile,
          rivalsa: elem.rivalsa,
          contributi: 0,
        };
        vettore.push(obj);
      }
      return vettore;
    }, [])
    .sort((x1, x2) => {
      return x2.anno - x1.anno;
    });

  contributi.forEach((elem) => {
    const anno = new Date(elem.data).getFullYear();
    for (let i = 0; i < vettore_fatture.length; i++) {
      if (vettore_fatture[i].anno === anno) {
        vettore_fatture[i].contributi += elem.importo;
      }
    }
  });

  noteDiCredito.forEach((elem) => {
    const anno = new Date(elem.data_fattura).getFullYear();
    for (let i = 0; i < vettore_fatture.length; i++) {
      if (vettore_fatture[i].anno === anno) {
        vettore_fatture[i].imponibile -= elem.imponibile;
        vettore_fatture[i].rivalsa -= elem.rivalsa;
      }
    }
  });
  return vettore_fatture;
}

export function art_com_reduce(
  fatture = [],
  contributi = [],
  noteDiCredito = []
) {
  var vettore_fatture = fatture
    .reduce((vettore, elem) => {
      const anno = new Date(elem.data_incasso).getFullYear();
      const mese = new Date(elem.data_incasso).getMonth();
      if (
        vettore.filter((a) => {
          if (a.anno === anno) return true;
          else return false;
        }).length > 0
      ) {
        vettore.forEach((element) => {
          if (element.anno === anno) {
            element.mesi[mese].imponibile += elem.imponibile;
            element.mesi[mese].rivalsa += elem.rivalsa;
          }
        });
      } else {
        var mesi = [
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
          { imponibile: 0, rivalsa: 0, contributi: 0 },
        ];

        const mese = new Date(elem.data_incasso).getMonth();

        mesi[mese].imponibile += elem.imponibile;
        mesi[mese].rivalsa += elem.rivalsa;

        const obj = {
          anno: anno,
          mesi: mesi,
        };
        vettore.push(obj);
      }
      return vettore;
    }, [])
    .sort((x1, x2) => {
      return x2.anno - x1.anno;
    });

  contributi.forEach((elem) => {
    const anno = new Date(elem.data).getFullYear();
    const mese = new Date(elem.data).getMonth();
    for (let i = 0; i < vettore_fatture.length; i++) {
      if (vettore_fatture[i].anno === anno) {
        vettore_fatture[i].mesi[mese].contributi += elem.importo;
      }
    }
  });

  noteDiCredito.forEach((elem) => {
    const anno = new Date(elem.data_fattura).getFullYear();
    const mese = new Date(elem.data_fattura).getMonth();
    for (let i = 0; i < vettore_fatture.length; i++) {
      if (vettore_fatture[i].anno === anno) {
        vettore_fatture[i].mesi[mese].imponibile -= elem.imponibile;
        vettore_fatture[i].mesi[mese].rivalsa -= elem.rivalsa;
      }
    }
  });
  return vettore_fatture;
}

export function calcolo_cumulativo(mesi) {
  return mesi.map((elem, index) => {
    var imponibile = 0;
    var contributi = 0;
    for (let i = 0; i <= index; i++) {
      imponibile += mesi[i].imponibile;
      contributi += mesi[i].contributi;
    }

    return {
      imponibile: imponibile,
      contributi: contributi,
    };
  });
}

export function calcolo_tasse_prof_1(anno, user, flag = false) {
  const imponibile = anno.imponibile;
  const aliquota1 = user.coefficiente_di_redditivita;
  const base = (imponibile * aliquota1) / 100;

  var aliquota2 = 0;

  if (user.attivita_precedenti) {
    aliquota2 = 15;
  } else if (new Date().getFullYear() - user.anno_inizio >= 5) {
    aliquota2 = 15;
  } else {
    aliquota2 = 5;
  }

  const base2 = base - anno.contributi;

  const tasse_da_versare = (base2 * aliquota2) / 100;

  if (flag) {
    return tasse_da_versare;
  }
  return [
    {
      descrizione: "Imponibile",
      importo: converti(imponibile) + " €",
    },
    {
      descrizione: "Coefficiente di redditività",
      importo: converti(aliquota1) + " %",
    },
    {
      descrizione: "Base 1",
      importo: converti(base) + " €",
    },
    {
      descrizione: "Contributi versati",
      importo: converti(anno.contributi),
    },
    {
      descrizione: "Base 2",
      importo: converti(base2) + " €",
    },
    {
      descrizione: "Aliquota",
      importo: converti(aliquota2) + " %",
    },
    {
      descrizione: "Imposte da versare",
      importo: converti(tasse_da_versare) + " €",
    },
  ];
}

export function calcolo_tasse_prof_2(anno, user, flag = false) {
  const { imponibile, rivalsa, contributi } = anno;

  const totale_imponibile = imponibile + rivalsa;
  const coefficiente_di_redditivita = user.coefficiente_di_redditivita;

  const base = (totale_imponibile * coefficiente_di_redditivita) / 100;
  const base2 = base - contributi;
  var aliquota_tassa_1 = 0;

  if (user.attivita_precedenti) {
    aliquota_tassa_1 = 15;
  } else if (new Date().getFullYear() - user.anno_inizio >= 5) {
    aliquota_tassa_1 = 15;
  } else {
    aliquota_tassa_1 = 5;
  }
  const tassa_1 = (base2 * aliquota_tassa_1) / 100;

  const tabella_1 = [
    {
      descrizione: "Imponibile",
      importo: converti(totale_imponibile) + " €",
    },
    {
      descrizione: "Coefficiente di redditività",
      importo: coefficiente_di_redditivita + " %",
    },
    {
      descrizione: "Base 1",
      importo: converti(base) + " €",
    },
    {
      descrizione: "Contributi versati",
      importo: converti(contributi),
    },
    {
      descrizione: "Base 2",
      importo: converti(base2) + " €",
    },
    {
      descrizione: "Aliquota",
      importo: converti(aliquota_tassa_1) + " %",
    },
    {
      descrizione: "Imposte da versare",
      importo: converti(tassa_1) + " €",
    },
  ];

  var aliquota_inps = 25.72;
  if (user.username === "guglielmino.maria.domenica") {
    aliquota_inps = 0;
  }
  const ante_tassa_inps =
    (totale_imponibile * coefficiente_di_redditivita) / 100;
  const tassa_inps = (base * aliquota_inps) / 100;

  if (flag) {
    return tassa_1 + tassa_inps;
  }

  var tabella_2 = [
    {
      descrizione: "Imponibile",
      importo: converti(totale_imponibile) + " €",
    },
    {
      descrizione: "Coefficiente di redditività",
      importo: coefficiente_di_redditivita + " %",
    },
    {
      descrizione: "Ante contributi",
      importo: converti(ante_tassa_inps) + " €",
    },
    {
      descrizione: "Aliquota INPS",
      importo: converti(aliquota_inps) + " %",
    },
    {
      descrizione: "Contributi da versare",
      importo: converti(tassa_inps) + " €",
    },
  ];

  var tabella_3 = [
    {
      descrizione: "Imposta sui ricavi",
      importo: converti(tassa_1) + " €",
    },
    {
      descrizione: "Contributi INPS",
      importo: converti(tassa_inps) + " €",
    },
    {
      descrizione: "Imposte e Contributi da versare",
      importo: converti(tassa_1 + tassa_inps) + " €",
    },
  ];

  if (user.username === "guglielmino.maria.domenica") {
    tabella_3 = [
      {
        descrizione: "Imposta sui ricavi",
        importo: converti(tassa_1) + " €",
      },
      {
        descrizione: "Imposte e Contributi da versare",
        importo: converti(tassa_1 + tassa_inps) + " €",
      },
    ];
  }

  return [tabella_1, tabella_2, tabella_3];
}

export function calcolo_tasse_art(dati_mensili_cumulativi, user, flag = false) {
  const {
    coefficiente_di_redditivita,
    attivita_precedenti,
    anno_inizio,
  } = user;
  const dati = dati_mensili_cumulativi.map((mese) => {
    const base = (mese.imponibile * coefficiente_di_redditivita) / 100;
    var tassa_1 = 0;
    var importo_tassa_1 = 0;

    if (base >= 15548 && base < 46123) {
      tassa_1 = 23.55;
      importo_tassa_1 += ((base - 15548) * tassa_1) / 100;
    } else if (base >= 46123 && base < 65000) {
      tassa_1 = 24.55;
      importo_tassa_1 += ((46123 - 15548) * 23.55) / 100;
      importo_tassa_1 += ((base - 46123) * tassa_1) / 100;
    }

    var tassa_2 = 0;
    if (attivita_precedenti) {
      tassa_2 = 15;
    } else if (new Date().getFullYear() - anno_inizio >= 5) {
      tassa_2 = 15;
    } else {
      tassa_2 = 5;
    }
    const importo_tassa_2 = ((base - mese.contributi) * tassa_2) / 100;

    return {
      imponibile: mese.imponibile,
      base,
      tassa_1,
      importo_tassa_1,
      tassa_2,
      importo_tassa_2,
      contributi: mese.contributi,
    };
  });
  if (flag) {
    return dati;
  }

  if (dati[11].tassa_1 === 0) {
    const anno = dati[11];

    return [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: converti(coefficiente_di_redditivita) + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },

      {
        descrizione: "Contributi",
        importo: converti(anno.contributi) + " €",
      },
      {
        descrizione: "Base 2",
        importo: converti(anno.base - anno.contributi) + " €",
      },
      {
        descrizione: "Aliquota imposta",
        importo: converti(anno.tassa_2) + " %",
      },
      {
        descrizione: "Imposte da versare",
        importo: converti(anno.importo_tassa_2) + " €",
      },
    ];
  } else {
    const anno = dati[11];

    const tabella_1 = [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: converti(coefficiente_di_redditivita) + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },
    ];

    if (anno.base >= 15548 && anno.base < 46123) {
      tabella_1.push(
        {
          descrizione: "Aliquota imposta con base superiore a 15.548 €",
          importo: converti(23.55) + " %",
        },
        {
          descrizione: "Importo imposta 1",
          importo: converti(anno.importo_tassa_1) + " €",
        }
      );
    } else if (anno.base >= 46123) {
      tabella_1.push(
        {
          descrizione:
            "Aliquota imposta con base superiore a 15.548 € e inferiore a 46.123 €",
          importo: converti(23.55) + " %",
        },
        {
          descrizione: "Aliquota imposta con base superiore a 46.123 €",
          importo: converti(24.55) + " %",
        },
        {
          descrizione: "Importo imposta 1",
          importo: converti(anno.importo_tassa_1) + " €",
        }
      );
    }

    const tabella_2 = [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: converti(coefficiente_di_redditivita) + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },
      {
        descrizione: "Contributi",
        importo: converti(anno.contributi) + " €",
      },
      {
        descrizione: "Base 2",
        importo: converti(anno.base - anno.contributi) + " €",
      },
      {
        descrizione: "Aliquota imposta 2",
        importo: converti(anno.tassa_2) + " %",
      },

      {
        descrizione: "Importo imposta 2",
        importo: converti(anno.importo_tassa_2) + " €",
      },
    ];

    const tabella_3 = [
      {
        descrizione: "Imposta 1",
        importo: converti(anno.importo_tassa_1) + " €",
      },
      {
        descrizione: "Imposta 2",
        importo: converti(anno.importo_tassa_2) + " €",
      },
      {
        descrizione: "Imposte da versare",
        importo: converti(anno.importo_tassa_1 + anno.importo_tassa_2) + " €",
      },
    ];
    return [tabella_1, tabella_2, tabella_3];
  }
}

export function calcolo_tasse_comm(
  dati_mensili_cumulativi,
  user,
  flag = false
) {
  const {
    coefficiente_di_redditivita,
    attivita_precedenti,
    anno_inizio,
  } = user;
  const dati = dati_mensili_cumulativi.map((mese) => {
    const base = (mese.imponibile * coefficiente_di_redditivita) / 100;
    var tassa_1 = 0;
    var importo_tassa_1 = 0;

    if (base >= 15548 && base < 46123) {
      tassa_1 = 23.64;
      importo_tassa_1 += ((base - 15548) * tassa_1) / 100;
    } else if (base >= 46123 && base < 65000) {
      tassa_1 = 24.64;
      importo_tassa_1 += ((46123 - 15548) * 23.64) / 100;
      importo_tassa_1 += ((base - 46123) * tassa_1) / 100;
    }

    var tassa_2 = 0;
    if (attivita_precedenti) {
      tassa_2 = 15;
    } else if (new Date().getFullYear() - anno_inizio >= 5) {
      tassa_2 = 15;
    } else {
      tassa_2 = 5;
    }
    const importo_tassa_2 = ((base - mese.contributi) * tassa_2) / 100;

    return {
      imponibile: mese.imponibile,
      base,
      tassa_1,
      importo_tassa_1,
      tassa_2,
      importo_tassa_2,
      contributi: mese.contributi,
    };
  });

  if (flag) {
    return dati;
  }

  if (dati[11].tassa_1 === 0) {
    const anno = dati[11];

    return [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: converti(coefficiente_di_redditivita) + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },

      {
        descrizione: "Contributi",
        importo: converti(anno.contributi) + " €",
      },
      {
        descrizione: "Base 2",
        importo: converti(anno.base - anno.contributi) + " €",
      },
      {
        descrizione: "Aliquota imposta",
        importo: converti(anno.tassa_2) + " %",
      },
      {
        descrizione: "Imposte da versare",
        importo: converti(anno.importo_tassa_2) + " €",
      },
    ];
  } else {
    const anno = dati[11];

    var tabella_1 = [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: coefficiente_di_redditivita + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },
    ];

    if (anno.base >= 15548 && anno.base < 46123) {
      tabella_1.push(
        {
          descrizione: "Aliquota imposta con base superiore a 15.548 €",
          importo: converti(23.64) + " %",
        },
        {
          descrizione: "Importo imposta 1",
          importo: converti(anno.importo_tassa_1) + " €",
        }
      );
    } else if (anno.base >= 46123) {
      tabella_1.push(
        {
          descrizione:
            "Aliquota imposta con base superiore a 15.548 € e inferiore a 46.123 €",
          importo: converti(23.64) + " %",
        },
        {
          descrizione: "Aliquota imposta con base superiore a 46.123 €",
          importo: converti(24.64) + " %",
        },
        {
          descrizione: "Importo imposta 1",
          importo: converti(anno.importo_tassa_1) + " €",
        }
      );
    }

    const tabella_2 = [
      {
        descrizione: "Imponibile",
        importo: converti(anno.imponibile) + " €",
      },
      {
        descrizione: "Coefficiente di redditività",
        importo: coefficiente_di_redditivita + " %",
      },
      {
        descrizione: "Base",
        importo: converti(anno.base) + " €",
      },
      {
        descrizione: "Contributi",
        importo: converti(anno.contributi) + " €",
      },
      {
        descrizione: "Base 2",
        importo: converti(anno.base - anno.contributi) + " €",
      },
      {
        descrizione: "Aliquota imposta 2",
        importo: converti(anno.tassa_2) + " %",
      },

      {
        descrizione: "Importo imposta 2",
        importo: converti(anno.importo_tassa_2) + " €",
      },
    ];

    const tabella_3 = [
      {
        descrizione: "Imposta 1",
        importo: converti(anno.importo_tassa_1) + " €",
      },
      {
        descrizione: "Imposta 2",
        importo: converti(anno.importo_tassa_2) + " €",
      },
      {
        descrizione: "Imposte da versare",
        importo: converti(anno.importo_tassa_1 + anno.importo_tassa_2) + " €",
      },
    ];
    return [tabella_1, tabella_2, tabella_3];
  }
}
