import React from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav } from "reactstrap";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.activeRoute.bind(this);
    }
    // verifies if routeName is the one active (in browser input)
    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1
            ? "active"
            : "";
    }
    componentDidMount() {}
    componentWillUnmount() {}
    render() {
        return (
            <div
                className="dashboardsidebar"
                data-color={this.props.bgColor}
                data-active-color={this.props.activeColor}
            >
                <div className="logo" style={{ textAlign: "center" }}>
                    <Link to="/dashboard" className="simple-text logo-normal">
                        Sevat
                    </Link>
                </div>
                <div
                    className="dashboardsidebar-wrapper"
                    ref="dashboardsidebar"
                >
                    <Nav>
                        {this.props.routes.map((prop, key) => {
                            if (prop.redirect) return null;
                            return (
                                <li
                                    className={
                                        this.activeRoute(prop.path) +
                                        (prop.pro ? " active-pro" : "")
                                    }
                                    key={key}
                                >
                                    <NavLink
                                        to={prop.path}
                                        className="nav-link"
                                        activeClassName="active"
                                    >
                                        <i className={prop.icon} />
                                        <p>{prop.name}</p>
                                    </NavLink>
                                </li>
                            );
                        })}
                    </Nav>
                </div>
            </div>
        );
    }
}

export default Sidebar;
