import { GET_CLIENTI, INVIA_CLIENTE, DELETE_CLIENTE } from "../actions/types";

const initialState = {
  clienti: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_CLIENTI:
      return {
        ...state,
        clienti: action.payload
      };

    case INVIA_CLIENTE:
      return {
        ...state,
        clienti: [action.payload, ...state.clienti]
      };

    case DELETE_CLIENTE:
      return {
        ...state,
        clienti: state.clienti.filter(cliente => {
          return cliente.id.toString() !== action.payload;
        })
      };

    default:
      return state;
  }
}
