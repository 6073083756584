import React, { Component } from "react";

import { connect } from "react-redux";

import { get_spese_anno } from "../../../actions/spese";

import { converti } from "../../calcoloTasse/funzioni";
import { locale } from "../../calcoloTasse/impostazioni";

import MaterialTable from "material-table";
import { showModalConferma } from "../../../components/Modal/Modals";

const columns = [
  { title: "ID", field: "id", hidden: true },
  {
    title: "Data",
    field: "data",
    type: "datetime",
    defaultSort: "desc",
    render: rowData => {
      return rowData.data.toLocaleDateString();
    },
    cellStyle: { fontSize: 14, padding: "4px 12px" }
  },
  {
    title: "Categoria",
    field: "categoria",
    cellStyle: { fontSize: 14, padding: "4px 12px" }
  },
  {
    title: "Descrizione",
    field: "descrizione",
    cellStyle: { fontSize: 14, padding: "4px 12px" }
  },
  {
    title: "Totale (€)",
    field: "importo",
    type: "numeric",
    cellStyle: { fontSize: 14, padding: "4px 12px" },
    render: rowData => {
      return converti(rowData.importo);
    }
  }
];

const options = {
  actionsColumnIndex: -1,
  headerStyle: {
    fontSize: 14,
    padding: "4px 12px"
  },
  pageSize: 10,
  pageSizeOptions: [10]
};

class TabellaSpese extends Component {
  componentDidMount() {
    this.props.get_spese_anno(this.props.annoSelezionato);
  }
  componentWillReceiveProps(props) {
    if (props.annoSelezionato !== this.props.annoSelezionato) {
      props.get_spese_anno(props.annoSelezionato);
    }
  }

  render() {
    const spese = this.props.spese.map(spesa => {
      return {
        id: spesa.id,
        data: new Date(spesa.data),
        categoria: spesa.categoria,
        descrizione: spesa.descrizione,
        importo: spesa.importo
      };
    });
    return (
      <MaterialTable
        columns={columns}
        options={options}
        actions={[
          {
            icon: "delete_forever",
            tooltip: "Cancella Spesa",
            onClick: (event, rowData) => {
              showModalConferma(
                "Sei sicuro di voler eliminare la spesa?",
                this.props.delete,
                rowData.id
              );
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "red"
              }
            }
          }
        ]}
        data={spese}
        localization={locale}
        title=""
      />
    );
  }
}

const mapStateToProps = state => ({
  spese: state.spese.spese,
  annoSelezionato: state.auth.annoSelezionato
});

export default connect(mapStateToProps, { get_spese_anno })(TabellaSpese);
