import React, { Component } from "react";

import {
  Row,
  Col,
  Card,
  CardTitle,
  CardHeader,
  CardFooter,
  CardBody,
} from "reactstrap";

import { Line } from "react-chartjs-2";

import Stats from "../../components/Stats/Stats.jsx";

import {
  art_com_reduce,
  calcolo_tasse_prof_1,
  calcolo_tasse_prof_2,
  calcolo_tasse_art,
  calcolo_cumulativo,
  calcolo_tasse_comm,
} from "../../calcoloTasse/calcoloTasse";

import moment from "moment";

const options = {
  maintainAspectRatio: false,
  legend: {
    display: true,
    onClick: null,
    labels: {
      boxWidth: 12,
      fontSize: 14,
    },
  },
  tooltips: {
    mode: "index",
    intersect: false,
    enabled: true,
    titleFontSize: 17,
    backgroundColor: "#2c2c2c",
    titleMarginBottom: 8,
    displayColors: false,
    bodyFontSize: 15,
    callbacks: {
      title: function (tooltipItem, data) {
        return moment(`${anno_attuale}-${tooltipItem[0].index + 1}`).format(
          "MMMM YYYY"
        );
      },
      label: function (tooltipItem, data) {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ": " +
          (
            Math.round(
              data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
                .y * 100
            ) / 100
          ).toLocaleString("de") +
          " €"
        );
      },
    },
  },
  scales: {
    yAxes: [
      {
        ticks: {
          fontColor: "#9f9f9f",
          beginAtZero: true,
        },
        gridLines: {
          drawBorder: false,
          zeroLineColor: "#ccc",
          color: "rgba(255,255,255,0.05)",
        },
      },
    ],
    xAxes: [
      {
        type: "time",
        time: {
          unit: "month",
        },
        barPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(255,255,255,0.1)",
          zeroLineColor: "transparent",
          display: false,
        },
        ticks: {
          padding: 20,
          fontColor: "#9f9f9f",
        },
      },
    ],
  },
};

const anno_attuale = new Date().getFullYear();

class Chart extends Component {
  render() {
    const { user, fatture, contributi, noteDiCredito } = this.props;

    var text_last_update = "";
    var data = {
      datasets: [
        {
          label: "Imposte",
          borderColor: "rgba(255, 68, 68, 1)",
          backgroundColor: "rgba(255, 68, 68, .4)",
          pointRadius: 3,
          pointHoverRadius: 6,
          borderWidth: 3,
          data: [],
        },
        {
          label: "Reddito netto",
          borderColor: "rgba(0, 200, 81, 1)",
          backgroundColor: "rgba(0, 200, 81, .4)",
          pointRadius: 3,
          pointHoverRadius: 6,
          borderWidth: 3,
          data: [],
        },
      ],
    };
    if (fatture.length !== 0) {
      var tasse_mensili = [];
      if (user.categoria === "prof 1" || user.categoria === "prof 2") {
        tasse_mensili = art_com_reduce(
          fatture,
          contributi,
          noteDiCredito
        )[0].mesi.map((mese, index) => {
          if (user.categoria === "prof 1") {
            const tasse = calcolo_tasse_prof_1(mese, user, true);
            return {
              x: new Date(`${anno_attuale}/${index + 1}/01`),
              y: tasse,
            };
          } else if (user.categoria === "prof 2") {
            const tasse = calcolo_tasse_prof_2(mese, user, true);
            return {
              x: new Date(`${anno_attuale}/${index + 1}/01`),
              y: tasse,
            };
          } else {
            return {};
          }
        });
      } else if (user.categoria === "art") {
        tasse_mensili = [];
        const cumulativo = calcolo_cumulativo(
          art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
        );
        const tasse = calcolo_tasse_art(cumulativo, user, true);
        tasse_mensili = tasse.map((mese, index) => {
          var mese_prec = 0;
          if (index !== 0) {
            mese_prec =
              tasse[index - 1].importo_tassa_1 +
              tasse[index - 1].importo_tassa_2;
          }
          const mese_corr = mese.importo_tassa_1 + mese.importo_tassa_2;
          return {
            x: new Date(`${anno_attuale}/${index + 1}/01`),
            y: mese_corr - mese_prec,
          };
        });
      } else if (user.categoria === "comm") {
        tasse_mensili = [];
        const cumulativo = calcolo_cumulativo(
          art_com_reduce(fatture, contributi, noteDiCredito)[0].mesi
        );
        const tasse = calcolo_tasse_comm(cumulativo, user, true);
        tasse_mensili = tasse.map((mese, index) => {
          var mese_prec = 0;
          if (index !== 0) {
            mese_prec =
              tasse[index - 1].importo_tassa_1 +
              tasse[index - 1].importo_tassa_2;
          }
          const mese_corr = mese.importo_tassa_1 + mese.importo_tassa_2;
          return {
            x: new Date(`${anno_attuale}/${index + 1}/01`),
            y: mese_corr - mese_prec,
          };
        });
      }
      var spese = new Array(12).fill(0);

      this.props.spese.forEach((element) => {
        const mese = new Date(element.data).getMonth();
        spese[mese] += element.importo;
      });

      this.props.contributi.forEach((contributo) => {
        const mese = new Date(contributo.data).getMonth();
        if (user.categoria === "prof 1") {
          spese[mese] += contributo.importo;
        } else if (
          (user.categoria === "comm" || user.categoria === "art") &&
          mese + 1 !== 6 &&
          mese + 1 !== 7
        ) {
          spese[mese] += contributo.importo;
        }
      });

      const incassato_mensile = art_com_reduce(
        fatture,
        [],
        noteDiCredito
      )[0].mesi.map((mese, index) => {
        return {
          x: new Date(`${anno_attuale}/${index + 1}/01`),
          y:
            mese.imponibile +
            mese.rivalsa -
            tasse_mensili[index].y -
            spese[index],
        };
      });

      data = {
        datasets: [
          {
            label: "Imposte",
            borderColor: "rgba(255, 68, 68, 1)",
            backgroundColor: "rgba(255, 68, 68, .4)",
            pointRadius: 3,
            pointHoverRadius: 6,
            borderWidth: 3,
            lineTension: 0,
            data: tasse_mensili,
          },
          {
            label: "Reddito netto",
            borderColor: "rgba(0, 200, 81, 1)",
            backgroundColor: "rgba(0, 200, 81, .4)",
            pointRadius: 3,
            pointHoverRadius: 6,
            borderWidth: 3,
            lineTension: 0,
            data: incassato_mensile,
          },
        ],
      };

      const last_update = fatture.reduce((max, fattura) => {
        if (moment(fattura.data_incasso).isAfter(max)) {
          max = moment(fattura.data_incasso);
        }
        return max;
      }, moment("1980"));

      if (moment().diff(last_update, "days") === 0) {
        text_last_update = "oggi";
      } else {
        text_last_update = last_update.fromNow();
      }
    }

    return (
      <Row>
        <Col xs={12}>
          <Card>
            <CardHeader>
              <CardTitle />
            </CardHeader>
            <CardBody>
              <div style={{ minHeight: 200, height: "45vh" }}>
                <Line data={data} options={options} />
              </div>
            </CardBody>
            <CardFooter>
              <hr />
              <Stats>
                {[
                  {
                    i: "fas fa-history",
                    t: `Ultima fattura incassata: ${text_last_update}`,
                  },
                ]}
              </Stats>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    );
  }
}
export default Chart;
