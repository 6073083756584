import React from "react";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50,
  userSelect: "none"
};

export default function SecondaSezione() {
  return (
    <Fade>
      <div style={style}>
        <div className="ui section huge header centered">
          A chi ci rivolgiamo
        </div>
        <div className="ui header medium grey container centered">
          In questa ottica la Società offre tutti i servizi di contabilità e di
          consulenza in materia societaria e tributaria sia ai privati sia alle
          aziende (dalle ditte individuali alle società di capitali incluse le
          Fondazioni e gli Enti del Terzo Settore), compresa la costituzione,
          controllo di gestione, operazioni straordinarie e tutti gli
          adempimenti fiscali previsti dalla legge
        </div>
      </div>
    </Fade>
  );
}
