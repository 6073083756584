import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import Menu from "./Menu";
import Footer from "./Footer";

class ScrollToTop extends Component {
  componentDidUpdate() {
    window.scrollTo(0, 0);
  }

  render() {
    return this.props.children;
  }

  componentDidMount() {
    window.cookieconsent.initialise({
      palette: {
        popup: {
          background: "#252e39",
        },
        button: {
          background: "#14a7d0",
        },
      },
      position: "bottom-right",
      content: {
        message:
          "Questo sito web utilizza i cookie per assicurarti di ottenere la migliore esperienza sul nostro sito web",
        dismiss: "Ok!",
        link: "Ulteriori informazioni",
        href: "http://sevatsrl.it/cookie-policy",
      },
    });
  }
}

export default function DefaultLayout(props) {
  const { children } = props;

  if (
    props.location.pathname !== "/" &&
    props.location.pathname !== "/team" &&
    props.location.pathname !== "/servizi" &&
    props.location.pathname !== "/sedi" &&
    props.location.pathname !== "/news" &&
    props.location.pathname !== "/perche" &&
    props.location.pathname !== "/login" &&
    props.location.pathname !== "/privacy-policy" &&
    props.location.pathname !== "/cookie-policy"
  ) {
    return <Redirect to="/" />;
  } else
    return (
      <ScrollToTop>
        <Menu />
        <div className="pusher">{children}</div>
        <Footer />
      </ScrollToTop>
    );
}
