import React from "react";
import { Link } from "react-router-dom";
import Fade from "../../Layout/Fade";
const style = {
  padding: "50px 0",
};

export default function QuartaSezione() {
  return (
    <Fade>
      <div
        className="ui grid middle aligned center aligned container stackable"
        style={style}
      >
        <div
          className="ui section huge header centered"
          style={{ marginBottom: 50 }}
        >
          La crescita continua del cliente è il nostro obiettivo
        </div>

        <div className="row">
          <div className="four wide column ">
            <Link to="/team">
              <i className="circular inverted blue users huge icon" />
            </Link>
            <br />
            <div className="ui header medium">
              <strong>La Società</strong>
            </div>
            <div
              className="ui sub"
              style={{ textAlign: "justify", color: "grey" }}
            >
              La Società fornisce assistenza contabile e consulenza fiscale a
              360 gradi, perseguendo quale obiettivo il supporto alla crescita
              dell'attività del cliente
            </div>
          </div>
          <div className="four wide column">
            <Link to="/servizi">
              <i className="circular inverted blue balance scale huge icon" />
            </Link>
            <br />
            <div className="ui header medium">
              <strong>Servizi</strong>
            </div>
            <div
              className="ui sub"
              style={{ textAlign: "justify", color: "grey" }}
            >
              Assistiamo tutti i clienti, partendo dai privati fino ad arrivare
              alle società di capitali, passando per gli Enti del Terzo Settore,
              offrendo una consulenza mirata per ogni tipo di esigenza
            </div>
          </div>
          <div className="four wide column">
            <Link to="/perche">
              <i className="circular inverted blue question huge icon" />
            </Link>
            <br />
            <div className="ui header medium">
              <strong>Perchè noi</strong>
            </div>
            <div
              className="ui sub"
              style={{ textAlign: "justify", color: "grey" }}
            >
              Non solo consulenza. Abbiamo creato diverse piattaforme per
              permettere al cliente di avere i dati della propria impresa sempre
              a portata di mano
            </div>
          </div>
          <div className="four wide column">
            <Link to="/sedi">
              <i className="circular inverted blue phone huge icon" />
            </Link>
            <br />
            <div className="ui header medium">
              <strong>Sedi</strong>
            </div>
            <div
              className="ui sub"
              style={{ textAlign: "justify", color: "grey" }}
            >
              Il nostro punto di forza? Il dialogo. Se hai un problema, prenota
              un appuntamento presso il nostro studio. Parlare per noi è sempre
              gratuito!
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
