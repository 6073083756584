import React, { Component } from "react";
import ReactDOM from "react-dom";

import {
  FormGroup,
  Label,
  Input,
  Button,
  FormFeedback,
  FormText,
} from "reactstrap";

import Datetime from "react-datetime";

import { Spring } from "react-spring/renderprops";

import moment from "moment";

import { modifica_fattura, get_fatture } from "../../../actions/fatture";

import { connect } from "react-redux";

class ModalEdit extends Component {
  modalRoot = document.getElementById("modal");
  state = {
    fattura: {
      nr_fattura: "",
      imponibile: "",
      rivalsa: "",
      marca_da_bollo: "",
      cliente: "",
      descrizione: "",
      data_incasso: "",
      data_emissione: "",
    },
    errors: {
      nr_fattura: false,
      messaggio_nr_fattura: "",
      imponibile: false,
      messaggio_imponibile: "",
      rivalsa: false,
      messaggio_rivalsa: "",
      marca_da_bollo: false,
      messaggiomarca_da_bollo: "",
      cliente: false,
      messaggio_cliente: "",
      descrizione: false,
      messaggio_descrizione: "",
      incasso: false,
      messaggio_incasso: "",
      emissione: false,
      messaggio_emissione: "",
    },
  };

  onInput = (e) => {
    let fattura = this.state.fattura;
    fattura[e.target.name] = e.target.value;
    this.setState({ fattura });
  };

  getDataIncasso = (data) => {
    let fattura = this.state.fattura;
    fattura.data_incasso = data._d;
    this.setState({ fattura });
  };

  getDataEmissione = (data) => {
    let fattura = this.state.fattura;
    fattura.data_emissione = data._d;
    this.setState({ fattura });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let errorFound = false;

    let errors = {
      nr_fattura: false,
      messaggio_nr_fattura: "",
      imponibile: false,
      messaggio_imponibile: "",
      rivalsa: false,
      messaggio_rivalsa: "",
      marca_da_bollo: false,
      messaggiomarca_da_bollo: "",
      cliente: false,
      messaggio_cliente: "",
      descrizione: false,
      messaggio_descrizione: "",
      incasso: false,
      messaggio_incasso: "",
      emissione: false,
      messaggio_emissione: "",
    };

    const {
      data_emissione,
      data_incasso,
      nr_fattura,
      cliente,
      imponibile,
      rivalsa,
      marca_da_bollo,
    } = this.state.fattura;

    if (data_emissione === "") {
      errorFound = true;
      errors.emissione = true;
      errors.messaggio_emissione =
        "Il campo 'Data emissione fattura' non può essere vuoto";
    }
    if (data_incasso === "") {
      errorFound = true;
      errors.incasso = true;
      errors.messaggio_incasso =
        "Il campo 'Data incasso fattura' non può essere vuoto";
    }

    if (
      data_incasso !== "" &&
      data_emissione !== "" &&
      data_incasso.getTime() < data_emissione.getTime()
    ) {
      errorFound = true;
      errors.incasso = true;
      errors.messaggio_incasso =
        "La data di emissione deve essere precedente alla data di incasso";
    }

    if (nr_fattura === "") {
      errorFound = true;
      errors.nr_fattura = true;
      errors.messaggio_nr_fattura =
        "Il campo 'Nr. fattura' non può essere vuoto";
    }
    if (cliente === "") {
      errorFound = true;
      errors.cliente = true;
      errors.messaggio_cliente = "Il campo 'Nome cliente' non può essere vuoto";
    }
    if (imponibile === "") {
      errorFound = true;
      errors.imponibile = true;
      errors.messaggio_imponibile =
        "Il campo 'Importo imponibile' non può essere vuoto";
    }
    if (rivalsa === "") {
      errorFound = true;
      errors.rivalsa = true;
      errors.messaggio_rivalsa =
        "Il campo 'Importo rivalsa' non può essere vuoto";
    }
    if (marca_da_bollo === "") {
      errorFound = true;
      errors.marca_da_bollo = true;
      errors.messaggiomarca_da_bollo =
        "Il campo 'Importo marca da bollo' non può essere vuoto";
    }

    this.setState({ errors: errors });

    if (errorFound) return;

    this.close();

    this.props.modifica_fattura(this.state.fattura, this.props.fattura[0].id);

    setTimeout(() => {
      this.props.get_fatture();
    }, 1000);

    this.setState({
      fattura: {
        nr_fattura: "",
        imponibile: "",
        rivalsa: "",
        marca_da_bollo: "",
        cliente: "",
        descrizione: "",
        data_incasso: "",
        data_emissione: "",
      },
    });
  };

  delete = () => {
    this.props.unmount(true);
  };
  close = () => {
    this.props.unmount(false);
  };

  element = document.createElement("div");
  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.modalRoot.appendChild(this.element);
    if (this.props.fattura.lenght !== 0) {
      const fattura = this.props.fattura[0];

      this.setState({
        fattura: {
          nr_fattura: fattura.nr_fattura,
          imponibile: fattura.imponibile,
          rivalsa: fattura.rivalsa,
          marca_da_bollo: fattura.marca_da_bollo,
          cliente: fattura.cliente,
          descrizione: fattura.descrizione,
          data_incasso: moment(fattura.data_incasso)._d,
          data_emissione: moment(fattura.data_emissione)._d,
        },
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }

  render() {
    const {
      data_emissione,
      data_incasso,
      nr_fattura,
      cliente,
      imponibile,
      rivalsa,
      marca_da_bollo,
      descrizione,
    } = this.state.fattura;

    const { errors } = this.state;
    return ReactDOM.createPortal(
      <div
        onClick={this.close}
        className="ui dimmer modals visible active"
        style={{
          position: "fixed",
          zIndex: 1000000,
          overflow: "auto",
        }}
      >
        <Spring
          from={{ opacity: 0 }}
          to={{ opacity: 1 }}
          config={{ friction: 50 }}
        >
          {(props) => (
            <div
              onClick={(e) => e.stopPropagation()}
              className="ui modal visible active modal-height"
              style={props}
            >
              <div className="header">
                <form onSubmit={this.onSubmit}>
                  <div className="form-row">
                    <FormGroup className="col-md-6">
                      <Label>Data emissione fattura</Label>
                      <Datetime
                        timeFormat={false}
                        viewMode="months"
                        inputProps={{
                          placeholder: "Seleziona la data",
                        }}
                        value={data_emissione}
                        onChange={this.getDataEmissione}
                      />
                      {errors.emissione ? (
                        <FormText className="text-danger">
                          {errors.messaggio_emissione}
                        </FormText>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                    <FormGroup className="col-md-6">
                      <Label>Data incasso fattura</Label>
                      <Datetime
                        timeFormat={false}
                        viewMode="months"
                        inputProps={{
                          placeholder: "Seleziona la data",
                        }}
                        value={data_incasso}
                        onChange={this.getDataIncasso}
                      />
                      {errors.incasso ? (
                        <FormText className="text-danger">
                          {errors.messaggio_incasso}
                        </FormText>
                      ) : (
                        ""
                      )}
                    </FormGroup>
                  </div>
                  <FormGroup>
                    <Label>Nr. Fattura</Label>
                    <Input
                      type="number"
                      name="nr_fattura"
                      placeholder="Inserire il numero della fattura..."
                      value={nr_fattura}
                      onChange={this.onInput}
                      invalid={errors.nr_fattura}
                    />
                    <FormFeedback>{errors.messaggio_nr_fattura}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label>Nome cliente</Label>
                    <Input
                      type="text"
                      name="cliente"
                      placeholder="Inserire il nome del cliente..."
                      value={cliente}
                      onChange={this.onInput}
                      invalid={errors.cliente}
                    />
                    <FormFeedback>{errors.messaggio_cliente}</FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="Descrizione fattura">Descrizione fattura</Label>
                    <Input
                      type="text"
                      name="descrizione"
                      placeholder="Inserisci una descrizione della fattura... (opzionale)"
                      value={descrizione}
                      onChange={this.onInput}
                      invalid={errors.descrizione}
                    />
                    <FormFeedback>{errors.messaggio_descrizione}</FormFeedback>
                  </FormGroup>
                  <div className="form-row">
                    <FormGroup className="col-md-4">
                      <Label>Importo imponibile (€)</Label>
                      <Input
                        type="number"
                        name="imponibile"
                        placeholder="Inserisci l'imponibile...."
                        value={imponibile}
                        onChange={this.onInput}
                        invalid={errors.imponibile}
                      />
                      <FormFeedback>{errors.messaggio_imponibile}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label>Importo rivalsa (€)</Label>
                      <Input
                        type="number"
                        name="rivalsa"
                        placeholder="Inserisci l'importo della rivalsa...."
                        value={rivalsa}
                        onChange={this.onInput}
                        invalid={errors.rivalsa}
                      />
                      <FormFeedback>{errors.messaggio_rivalsa}</FormFeedback>
                    </FormGroup>
                    <FormGroup className="col-md-4">
                      <Label>Importo marca da bollo (€)</Label>
                      <Input
                        type="number"
                        name="marca_da_bollo"
                        placeholder="Inserisci l'importo della marca da bollo...."
                        value={marca_da_bollo}
                        onChange={this.onInput}
                        invalid={errors.marca_da_bollo}
                      />
                      <FormFeedback>
                        {errors.messaggiomarca_da_bollo}
                      </FormFeedback>
                    </FormGroup>
                  </div>
                  <div className="row">
                    <Button
                      type="submit"
                      color="info"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                        marginTop: "2em",
                        width: "auto",
                      }}
                    >
                      Modifica fattura
                    </Button>
                    <Button
                      color="danger"
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "block",
                        marginTop: "2em",
                        width: "auto",
                      }}
                      onClick={this.close}
                    >
                      Chiudi
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Spring>
      </div>,
      this.element
    );
  }
}

export default connect(null, { modifica_fattura, get_fatture })(ModalEdit);
