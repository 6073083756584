import React, { Component } from "react";

import image from "../../../assets/perche/header.jpg";

import Header from "../../Layout/Header";
import PrimaSezione from "./PrimaSezione";
import SecondaSezione from "./SecondaSezione";

class Perche extends Component {
    componentDidMount() {
        document.title = "SEVAT | Perchè noi";
    }

    render() {
        return (
            <React.Fragment>
                <Header image={image} header="Tutti i dati sempre con te" />
                <PrimaSezione />

                <SecondaSezione />
            </React.Fragment>
        );
    }
}
export default Perche;
