import { combineReducers } from "redux";
import auth from "./auth";
import errors from "./errors";
import fatture from "./fatture";
import news from "./news";
import contributi from "./contributi";
import loading from "./loading";
import spese from "./spese";
import clienti from "./clienti";
import reminderFatture from "./reminderFatture";

export default combineReducers({
  auth,
  errors,
  fatture,
  news,
  contributi,
  loading,
  spese,
  clienti,
  reminderFatture
});
