import {
  GET_CLIENTI,
  INVIA_CLIENTE,
  DELETE_CLIENTE,
  LOADING_SHOW,
  LOADING_HIDE,
} from "./types";

import { tokenConfig } from "./auth";
import { showModalSuccess, showModalError } from "../components/Modal/Modals";
import { axiosInstance } from "../axiosInstance";

export const get_clienti = () => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    "/api/clienti/",
    tokenConfig(getState)
  );

  dispatch({
    type: GET_CLIENTI,
    payload: response.data,
  });
};

export const invia_cliente = (cliente) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_SHOW,
    });
    const response = await axiosInstance.post(
      "/api/clienti/",
      cliente,
      tokenConfig(getState)
    );
    dispatch({
      type: INVIA_CLIENTE,
      payload: response.data,
    });
    dispatch({
      type: LOADING_HIDE,
    });

    showModalSuccess("Il cliente è stato inserito correttamente");
  } catch (err) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(err.response.data);
  }
};
export const elimina_cliente = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/clienti/${id}`,
      tokenConfig(getState)
    );

    dispatch({
      type: DELETE_CLIENTE,
      payload: response.data,
    });
    showModalSuccess("Il cliente è stato eliminato correttamente!");
  } catch (err) {
    showModalError("Si è verificato un errore... Riprovare");
  }
};
