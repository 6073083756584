import React, { Component } from "react";

import MaterialTable from "material-table";

import { showModalConferma } from "../../../components/Modal/Modals";

import { Label, Row, Col, Input, FormGroup } from "reactstrap";

import { renderToString } from "react-dom/server";

import { withRouter } from "react-router-dom";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

class TabellaClienti extends Component {
  state = {};
  render() {
    const clienti = this.props.data.clienti.map(cliente => {
      return {
        id: cliente.id,
        denominazione: cliente.denominazione,
        luogo_esercizio: cliente.luogo_esercizio,
        partita_iva: cliente.partita_iva,
        codice_fiscale: cliente.codice_fiscale
      };
    });

    return (
      <MaterialTable
        columns={[
          { title: "ID", field: "id", hidden: true },
          {
            title: "Denominazione",
            field: "denominazione",
            defaultSort: "asc",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Sede legale",
            field: "luogo_esercizio",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Partita IVA",
            field: "partita_iva",
            cellStyle: { fontSize: 14 }
          },
          {
            title: "Codice Fiscale",
            field: "codice_fiscale",
            cellStyle: { fontSize: 14 }
          }
        ]}
        data={clienti}
        actions={[
          {
            icon: "delete_forever",
            tooltip: "Cancella cliente",
            onClick: (event, rowData) => {
              showModalConferma(
                "Sei sicuro di voler eliminare il cliente?",
                this.props.elimina_cliente,
                rowData.id
              );
            },
            iconProps: {
              style: {
                fontSize: 30,
                color: "red"
              }
            }
          },
          {
            icon: IconaAggiungi,
            tooltip: "Aggiungi Cliente",
            iconProps: {
              fontSize: "large",
              color: "primary"
            },
            isFreeAction: true,
            onClick: () => {
              MySwal.fire({
                title: "Aggiungi cliente",
                width: 1000,
                showCloseButton: true,
                confirmButtonText: "Aggiungi cliente",
                html: renderToString(
                  <div>
                    <Row className="mt-4">
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="float-left">Denominazione</Label>
                          <Input
                            id="denominazione"
                            type="text"
                            name="denominazione"
                            placeholder="Digita la denominazione del cliente"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="float-left">Sede legale</Label>
                          <Input
                            id="luogo_esercizio"
                            type="text"
                            name="luogo_esercizio"
                            placeholder="Digita la sede legale del cliente"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="float-left">Codice Fiscale</Label>
                          <Input
                            id="codice_fiscale"
                            type="text"
                            name="codice_fiscale"
                            placeholder="Digita il codice fiscale del cliente"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="12" sm="6">
                        <FormGroup>
                          <Label className="float-left">Partita IVA</Label>
                          <Input
                            id="partita_iva"
                            type="text"
                            name="partita_iva"
                            placeholder="Digita la partita IVA del cliente"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                ),
                focusConfirm: false,
                preConfirm: () => {
                  let cliente = {
                    denominazione: document.getElementById("denominazione")
                      .value,
                    luogo_esercizio: document.getElementById("luogo_esercizio")
                      .value,
                    partita_iva: document.getElementById("partita_iva").value,
                    codice_fiscale: document.getElementById("codice_fiscale")
                      .value
                  };

                  if (
                    cliente.denominazione !== "" &&
                    cliente.luogo_esercizio !== "" &&
                    cliente.partita_iva !== "" &&
                    cliente.codice_fiscale !== ""
                  ) {
                    this.props.invia_cliente(cliente);
                    return true;
                  } else {
                    return false;
                  }
                }
              });
            }
          }
        ]}
        title=""
        options={{
          actionsColumnIndex: -1,
          search: false,
          headerStyle: {
            fontSize: 14
          },
          pageSize: 15,
          pageSizeOptions: [15]
        }}
        localization={{
          pagination: {
            labelDisplayedRows: "{from}-{to} di {count}", // {from}-{to} of {count}
            labelRowsPerPage: "Righe per pagina:", // Rows per page:,
            labelRowsSelect: "righe",
            firstAriaLabel: "Prima pagina", // First Page
            firstTooltip: "Prima pagina", // First Page
            previousAriaLabel: "Pagina precedente", // Previous Page
            previousTooltip: "Pagina precedente", // Previous Page
            nextAriaLabel: "Pagina successiva", // Next Page
            nextTooltip: "Pagina successiva", // Next Page
            lastAriaLabel: "Ultima pagina", // Last Page
            lastTooltip: "Ultima pagina" // Last Page
          },
          toolbar: {
            nRowsSelected: "{0} righe selezionate", // {0} row(s) selected
            showColumnsTitle: "Mostra colonne", // Show Columns
            showColumnsAriaLabel: "Mostra colonne", // Show Columns
            exportTitle: "Esporta", // Export
            exportAriaLabel: "Esporta", // Export
            exportName: "Esporta CSV", // Export as CSV
            searchTooltip: "Cerca" // Search
          },
          header: {
            actions: "" // Actions
          },
          body: {
            emptyDataSourceMessage: "Nessuna cliente inserito", // No records to display
            filterRow: {
              filterTooltip: "Filtra" // Filter
            }
          }
        }}
      />
    );
  }
}
export default withRouter(TabellaClienti);

function IconaAggiungi() {
  return (
    <div>
      <span class="MuiIconButton-label">
        <span
          class="material-icons MuiIcon-root MuiIcon-colorPrimary MuiIcon-fontSizeLarge"
          aria-hidden="true"
        >
          add
        </span>
        <span className="MuiIcon-colorPrimary">Aggiungi nuovo cliente</span>
      </span>
      <span class="MuiTouchRipple-root" />
    </div>
  );
}
