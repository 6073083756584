import {
  GET_FATTURE,
  ELIMINA_FATTURA,
  LOADING_SHOW,
  LOADING_HIDE,
  INVIA_FATTURA,
  MODIFICA_FATTURA,
  GET_NOTE_DI_CREDITO,
  ELIMINA_NOTA_DI_CREDITO,
  INVIA_NOTA_DI_CREDITO,
  GET_CONTRIBUTI,
} from "./types";

import { tokenConfig } from "./auth";
import { showModalSuccess, showModalError } from "../components/Modal/Modals";
import { format } from "date-fns";
import { axiosInstance } from "../axiosInstance";

export const invia_fattura = (fattura) => async (dispatch, getState) => {
  const body = JSON.stringify({
    ...fattura,
    data_emissione: format(fattura.data_emissione, "yyyy-MM-dd"),
    data_incasso: format(fattura.data_incasso, "yyyy-MM-dd"),
  });

  try {
    dispatch({
      type: LOADING_SHOW,
    });
    const response = await axiosInstance.post(
      "/api/fatture/",
      body,
      tokenConfig(getState)
    );
    dispatch({
      type: INVIA_FATTURA,
      payload: response.data,
    });
    dispatch({
      type: LOADING_HIDE,
    });
    showModalSuccess("La fattura è stata inserita correttamente!");
  } catch (err) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(err.response.data);
  }
};

export const genera_fattura = (fattura) => async (dispatch, getState) => {
  const body = JSON.stringify({
    ...fattura,
    data_emissione: format(fattura.data_emissione, "yyyy-MM-dd"),
  });

  try {
    dispatch({
      type: LOADING_SHOW,
    });
    const response = await axiosInstance.post(
      "/api/fattureG/",
      body,
      tokenConfig(getState)
    );
    dispatch({
      type: INVIA_FATTURA,
      payload: response.data,
    });
    dispatch({
      type: LOADING_HIDE,
    });
    // showModalSuccess("La fattura è stata generata correttamente!");
  } catch (err) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(err.response.data);
  }
};

export const genera_nota_di_credito = (nota) => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOADING_HIDE,
    });
    const response = await axiosInstance.post(
      "/api/note_di_credito/",
      nota,
      tokenConfig(getState)
    );
    dispatch({
      type: INVIA_NOTA_DI_CREDITO,
      payload: response.data,
    });
    dispatch({
      type: LOADING_HIDE,
    });
  } catch (error) {
    dispatch({
      type: LOADING_HIDE,
    });
    showModalError(error.response.data);
  }
};

export const modifica_fattura = (fattura, id) => async (dispatch, getState) => {
  const body = JSON.stringify({
    ...fattura,
    data_emissione: format(fattura.data_emissione, "yyyy-MM-dd"),
    data_incasso: format(fattura.data_incasso, "yyyy-MM-dd"),
  });

  try {
    await axiosInstance.put(`/api/fatture/${id}/`, body, tokenConfig(getState));
    showModalSuccess("La fattura è stata modificata correttamente!");
  } catch (err) {
    showModalError(err.response.data);
    return;
  }
};

export const modifica_fattura_generata = (id, data, incassata) => async (
  dispatch,
  getState
) => {
  let body;
  if (incassata && data != null) {
    body = JSON.stringify({
      incassata,
      data_incasso: format(data, "yyyy-MM-dd"),
    });
  } else {
    body = JSON.stringify({
      incassata,
      data: "",
    });
  }

  try {
    const response = await axiosInstance.put(
      `/api/fattureG/${id}/`,
      body,
      tokenConfig(getState)
    );
    dispatch({
      type: MODIFICA_FATTURA,
      payload: response.data,
    });
    showModalSuccess("La fattura è stata modificata correttamente!");
  } catch (err) {
    showModalError(err.response.data);
    return;
  }
};

export const get_fatture = () => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    "/api/fatture/",
    tokenConfig(getState)
  );

  dispatch({
    type: GET_FATTURE,
    payload: response.data,
  });
};

export const get_note_di_credito = () => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    "/api/note_di_credito/",
    tokenConfig(getState)
  );

  dispatch({
    type: GET_NOTE_DI_CREDITO,
    payload: response.data,
  });
};

export const get_note_di_credito_incassate_anno = (anno) => async (
  dispatch,
  getState
) => {
  const response = await axiosInstance.get(
    `/api/note_di_credito?anno=${anno}`,
    tokenConfig(getState)
  );

  dispatch({
    type: GET_NOTE_DI_CREDITO,
    payload: response.data,
  });
};

export const get_note_di_credito_incassate = () => async (
  dispatch,
  getState
) => {
  const response = await axiosInstance.get(
    `/api/note_di_credito?anno=tutti`,
    tokenConfig(getState)
  );

  dispatch({
    type: GET_NOTE_DI_CREDITO,
    payload: response.data,
  });
};

export const get_fatture_incassate = () => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    `/api/fatture?incassate=true`,
    tokenConfig(getState)
  );

  dispatch({
    type: GET_FATTURE,
    payload: response.data,
  });
};

export const get_fatture_incassate_anno = (anno) => async (
  dispatch,
  getState
) => {
  const response = await axiosInstance.get(
    `/api/fatture?anno=${anno}&incassate=true`,
    tokenConfig(getState)
  );

  dispatch({
    type: GET_FATTURE,
    payload: response.data,
  });
};

export const elimina_fattura = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/fatture/${id}/`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_FATTURA,
      payload: response.data,
    });
    showModalSuccess("La fattura è stata eliminata correttamente!");
  } catch (err) {
    console.log(err);
    showModalError("Si è verificato un errore... Riprovare");
  }
};

export const elimina_fattura_g = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/fattureG/${id}/`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_FATTURA,
      payload: response.data,
    });
    showModalSuccess("La fattura è stata eliminata correttamente!");
  } catch (err) {
    console.log(err);
    showModalError("Si è verificato un errore... Riprovare");
  }
};

export const elimina_nota_di_credito = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/note_di_credito/${id}/`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_NOTA_DI_CREDITO,
      payload: response.data,
    });
    showModalSuccess("La nota di credito è stata eliminata correttamente!");
  } catch (err) {
    console.log(err);
    showModalError("Si è verificato un errore... Riprovare");
  }
};
