import { tokenConfig } from "./auth";
import { GET_NEWS, ELIMINA_NEWS } from "./types";
import { showModalError, showModalSuccess } from "../components/Modal/Modals";
import { axiosInstance } from "../axiosInstance";

export const inserisci_news = (titolo, testo) => async (dispatch, getState) => {
  const body = JSON.stringify({
    titolo,
    testo,
  });

  try {
    await axiosInstance.post("/api/news/", body, tokenConfig(getState));
    showModalSuccess("News inserita correttamente!");
  } catch (e) {
    showModalError("Si è verificato un errore... Riprovare");
  }
};
export const get_news = () => async (dispatch) => {
  try {
    const response = await axiosInstance.get("/api/news/");
    dispatch({
      type: GET_NEWS,
      payload: response.data,
    });
  } catch (e) {}
};

export const elimina_news = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/news/${id}`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_NEWS,
      payload: response.data,
    });
    showModalSuccess("La news è stata eliminata correttamente!");
  } catch (err) {
    showModalError("Si è verificato un errore... Riprovare");
  }
};
