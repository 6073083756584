import { GET_CONTRIBUTI, ELIMINA_CONTRIBUTI } from "./types";

import { tokenConfig } from "./auth";
import { showModalSuccess, showModalError } from "../components/Modal/Modals";
import { axiosInstance } from "../axiosInstance";

export const invia_contributi = (contributi) => async (dispatch, getState) => {
  const body = JSON.stringify({
    ...contributi,
    data:
      contributi.data.toLocaleDateString().split("/")[2] +
      "-" +
      contributi.data.toLocaleDateString().split("/")[1] +
      "-" +
      contributi.data.toLocaleDateString().split("/")[0],
  });

  try {
    await axiosInstance.post("/api/contributi/", body, tokenConfig(getState));
    showModalSuccess("I contributi sono stati inviati correttamente!");
  } catch (err) {
    showModalError(err.response.data);
    return;
  }
};

export const get_contributi = () => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    "/api/contributi/",
    tokenConfig(getState)
  );

  dispatch({
    type: GET_CONTRIBUTI,
    payload: response.data,
  });
};

export const get_contributi_anno = (anno) => async (dispatch, getState) => {
  const response = await axiosInstance.get(
    `/api/contributi?anno=${anno}`,
    tokenConfig(getState)
  );

  dispatch({
    type: GET_CONTRIBUTI,
    payload: response.data,
  });
};

export const elimina_contributi = (id) => async (dispatch, getState) => {
  try {
    const response = await axiosInstance.delete(
      `/api/contributi/${id}`,
      tokenConfig(getState)
    );

    dispatch({
      type: ELIMINA_CONTRIBUTI,
      payload: response.data,
    });
    showModalSuccess("I contributi sono stati eliminati correttamente!");
  } catch (err) {
    showModalError("Si è verificato un errore... Riprovare");
  }
};
