export const locale = {
    pagination: {
        labelDisplayedRows: "{from}-{to} di {count}", // {from}-{to} of {count}
        labelRowsPerPage: "Righe per pagina:", // Rows per page:,
        labelRowsSelect: "righe",
        firstAriaLabel: "Prima pagina", // First Page
        firstTooltip: "Prima pagina", // First Page
        previousAriaLabel: "Pagina precedente", // Previous Page
        previousTooltip: "Pagina precedente", // Previous Page
        nextAriaLabel: "Pagina successiva", // Next Page
        nextTooltip: "Pagina successiva", // Next Page
        lastAriaLabel: "Ultima pagina", // Last Page
        lastTooltip: "Ultima pagina" // Last Page
    },
    toolbar: {
        nRowsSelected: "{0} righe selezionate", // {0} row(s) selected
        showColumnsTitle: "Mostra colonne", // Show Columns
        showColumnsAriaLabel: "Mostra colonne", // Show Columns
        exportTitle: "Esporta", // Export
        exportAriaLabel: "Esporta", // Export
        exportName: "Esporta CSV", // Export as CSV
        searchTooltip: "Cerca" // Search
    },
    header: {
        actions: "" // Actions
    },
    body: {
        emptyDataSourceMessage: "Nessuna dato da mostrare", // No records to display
        filterRow: {
            filterTooltip: "Filtra" // Filter
        }
    }
};
