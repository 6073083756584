import React, { Component } from "react";
import Header from "../../Layout/Header";

import image from "../../../assets/news/header.jpg";
import Sezione from "./Sezione";

import { connect } from "react-redux";

import { get_news } from "../../../actions/news";
import moment from "moment";

class News extends Component {
  state = {
    cerca: "",
    filtro: ""
  };

  componentDidMount() {
    this.props.get_news();
    document.title = "SEVAT | News";
  }

  getAllNews = () => {
    if (this.state.filtro === "") {
      const vettore = this.props.news
        .map((news, index) => {
          return (
            <Sezione
              key={index}
              titolo={news.titolo}
              messaggio={news.testo}
              data={moment(news.data).format("DD MMMM YYYY")}
            />
          );
        });
      if (vettore.length === 0) {
        return <div className="h2 text-center">Nessuna news trovata</div>;
      } else {
        return vettore;
      }
    } else {
      const vettore = this.props.news
        .sort((x1, x2) => {
          return x2.id - x1.id;
        })
        .filter(news => {
          if (
            news.titolo.toLowerCase().includes(this.state.filtro) ||
            news.testo.toLowerCase().includes(this.state.filtro)
          )
            return true;
          else return false;
        })
        .map((news, index) => {
          return (
            <Sezione
              titolo={news.titolo}
              messaggio={news.testo}
              data={moment(news.data).format("DD MMMM YYYY")}
            />
          );
        });

      if (vettore.length === 0) {
        return <div className="h2 text-center" style={{
          marginTop: 200,
          marginBottom: 200
        }}>Nessuna news trovata</div>;
      } else {
        return vettore;
      }
    }
  };

  onInput = e => {
    this.setState({ cerca: e.target.value.toLowerCase() });
    setTimeout(()=>{
      this.setState({ filtro: this.state.cerca });
    }, 1000)
  };

  render() {
    return (
      <React.Fragment>
        <Header image={image} header="News" />
        <div className="ui search text-center">
          <h2 style={{
              marginTop: 25,
              marginBottom: 10
            }} >Cerca news</h2>  
          <div
            className="ui icon input"
            style={{
              width: "50%",
              maxWidth: 500,
              marginBottom: 25
            }}
          >
           
            <input
              className="prompt"
              type="text"
              placeholder="Cerca..."
              name="cerca"
              value={this.state.cerca}
              onChange={this.onInput}
            />
            <i className="search icon" />
          </div>
          <div className="results" />
        </div>
        {this.getAllNews()}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  news: state.news.news
});

export default connect(
  mapStateToProps,
  { get_news }
)(News);
