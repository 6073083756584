import React from "react";

import "./style.css";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { logout } from "../../../actions/auth";
class Sidebar extends React.Component {
  render() {
    return (
      <div
        className="my-sidebar"
        style={{ backgroundColor: "rgba(62, 69, 81, 0.1)" }}
      >
        <Link to="/gestione-news/inserisci">
          <div className="my-sidebar-items">Inserisci news</div>
        </Link>

        <Link to="/gestione-news/lista">
          <div className="my-sidebar-items">Lista news</div>
        </Link>
        <div>
          <div className="my-sidebar-items" onClick={this.props.logout}>
            Logout
          </div>
        </div>
      </div>
    );
  }
}
export default connect(
  null,
  { logout }
)(Sidebar);
