export function converti(number) {
  return (Math.round(number * 100) / 100).toLocaleString("de");
}

export function parseFromLocale(number) {
  return parseFloat(
    number
      .toString()
      .replace(".", "")
      .replace(",", ".")
  );
}
