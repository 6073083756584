import React from "react";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50,
  userSelect: "none"
};

export default function PrimaSezione() {
  return (
    <Fade>
      <div style={style}>
        <div className="ui section huge header centered">
          Risolvere una vera esigenza
        </div>
        <div className="ui header medium grey container centered">
          Quello che ci contraddistingue è il dialogo. Oltre alla consulenza, ci
          impegniamo ogni giorno per ascoltare le vere esigenze dei nostri
          clienti e trovare una risposta efficace ai problemi. Attraverso il
          dialogo con i piccoli e medi imprenditori, ci siamo accorti di una
          problematica: la mancanza di una piena e corrente consapevolezza dei
          costi e ricavi della propria azienda. Per un'attività in evoluzione
          riteniamo sia fondamentale conoscere in maniera accurata la
          composizione dei costi/ricavi e prevederne l'andamento all'interno
          della gestione operativa. Per questo abbiamo deciso di investire per
          dar vita ad una serie di prodotti che permettano a tutti i nostri
          clienti di poter monitorare in ogni istante della giornata i dati
          della propria impresa. Con un semplice click, da qualunque
          dispositivo.
        </div>
      </div>
      <div className="ui divider container" />
    </Fade>
  );
}
