import React from "react";

import "./style.css";

import TabellaFatture from "./TabellaFatture";

import { connect } from "react-redux";
import {
  get_note_di_credito,
  get_fatture,
  elimina_fattura,
  elimina_fattura_g
} from "../../../actions/fatture";

import ModalEdit from "../../components/Modal/ModalEdit";
import { showModalReminderFatture } from "../../../components/Modal/Modals";
import { disabilita_reminder } from "../../../actions/reminder_fatture";

class Fatture extends React.Component {
  state = {
    modalOpen: false,
    modal: "",
    id: ""
  };

  componentDidMount() {
    this.props.get_fatture();
    this.props.get_note_di_credito();
    if (this.props.reminderFatture) {
      showModalReminderFatture();
      this.props.disabilita_reminder();
    }
  }

  openModal = (modal, id) => {
    this.setState({ modalOpen: true, id: id, modal: modal });
  };

  handleModalCloseDelete = action => {
    if (action === true && this.state.modal === "delete fattura") {
      this.props.elimina_fattura(this.state.id);
    }
    this.setState({ modalOpen: false });
  };
  handleModalCloseEdit = action => {
    if (action === true) {
      this.props.elimina_fattura(this.state.id);
    }
    this.setState({ modalOpen: false });
  };
  render() {
    return (
      <div className="content">
        {this.state.modalOpen ? (
          <ModalEdit
            unmount={this.handleModalCloseDelete}
            fattura={this.props.fatture.fatture.filter(fattura => {
              return fattura.id === this.state.id;
            })}
          />
        ) : (
          ""
        )}

        <TabellaFatture
          data={this.props.fatture}
          user={this.props.user}
          open={this.openModal}
          delete={this.props.elimina_fattura}
          delete_g={this.props.elimina_fattura_g}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  fatture: state.fatture,
  noteDiCredito: state.noteDiCredito,
  user: state.auth.user,
  reminderFatture: state.reminderFatture
});

export default connect(
  mapStateToProps,
  { get_fatture, get_note_di_credito, elimina_fattura, elimina_fattura_g, disabilita_reminder }
)(Fatture);
