import React from "react";

import AccordionItem from "./AccordionItem";

import { connect } from "react-redux";

import {
  get_fatture_incassate,
  get_note_di_credito_incassate,
  get_note_di_credito,
} from "../../../actions/fatture";
import { get_contributi } from "../../../actions/contributi";

import {
  prof_1_2_reduce,
  art_com_reduce,
} from "../../calcoloTasse/calcoloTasse";

class Accordion extends React.Component {
  state = {
    openedCollapse: 0,
  };

  componentDidMount() {
    this.props.get_fatture_incassate();
    this.props.get_contributi();
    this.props.get_note_di_credito();
  }

  collapsesToggle = (collapse) => {
    const openedCollapse = this.state.openedCollapse;
    if (openedCollapse === collapse) {
      this.setState({
        openedCollapse: -1,
      });
    } else {
      this.setState({
        openedCollapse: collapse,
      });
    }
  };

  render() {
    if (
      this.props.fatture === undefined ||
      this.props.contributi === undefined ||
      this.props.noteDiCredito === undefined
    )
      return "Loading";
    var vettore_tasse = [];

    if (
      this.props.user.categoria === "prof 1" ||
      this.props.user.categoria === "prof 2"
    ) {
      vettore_tasse = prof_1_2_reduce(
        this.props.fatture,
        this.props.contributi,
        this.props.noteDiCredito
      );
    } else {
      vettore_tasse = art_com_reduce(
        this.props.fatture,
        this.props.contributi,
        this.props.noteDiCredito
      );
    }

    return (
      <>
        <div
          aria-multiselectable={true}
          className="card-collapse"
          id="accordion"
          role="tablist"
        >
          {vettore_tasse.map((elem, index) => {
            return (
              <AccordionItem
                key={index}
                user={this.props.user}
                data={elem}
                id={index}
                opened={this.state.openedCollapse === index}
                toggle={this.collapsesToggle}
              />
            );
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fatture: state.fatture.fatture,
  contributi: state.contributi.contributi,
  noteDiCredito: state.fatture.noteDiCredito,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  get_fatture_incassate,
  get_contributi,
  get_note_di_credito_incassate,
  get_note_di_credito,
})(Accordion);
