import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import image1 from "../../../assets/perche/Capture1.PNG";
import image2 from "../../../assets/perche/Capture2.PNG";
import image3 from "../../../assets/perche/Capture3.PNG";
import image4 from "../../../assets/perche/Capture4.PNG";
import image5 from "../../../assets/perche/Capture5.PNG";
import image6 from "../../../assets/perche/Capture6.PNG";

import Fade from "../../Layout/Fade";

const style = {
  marginTop: 50,
  marginBottom: 50,
  userSelect: "none"
};

export default function SecondaSezione() {
  return (
    <Fade>
      <div style={style}>
        <div className="ui section huge header centered">
          Piattaforma Copernico
        </div>
        <div className="ui container">
          <Carousel
            autoPlay
            infiniteLoop
            emulateTouch
            showStatus={false}
            dynamicHeight
            transitionTime={700}
            interval={5000}
          >
            <div>
              <img src={image1} alt="1" />
            </div>
            <div>
              <img src={image2} alt="2" />
            </div>
            <div>
              <img src={image3} alt="3" />
            </div>
            <div>
              <img src={image4} alt="4" />
            </div>
            <div>
              <img src={image5} alt="5" />
            </div>
            <div>
              <img src={image6} alt="6" />
            </div>
          </Carousel>
        </div>
      </div>
    </Fade>
  );
}
